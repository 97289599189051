// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import {
  HttpUtilsService,
  QueryParamsModel,
  QueryResultsModel,
} from '../../_base/crud';
// Models and Consts
import { StoreEmployeeModel } from '../_models/store-employee.model';
import { SPECIFICATIONS_DICTIONARY } from '../_consts/specification.dictionary';
import { environment } from '../../../../environments/environment';

const API_STORE_URL = `${environment.api_url}/store`;
// Real REST API
@Injectable()
export class StoreEmployeesService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  // CREATE =>  POST: add a new store employee to the server
  createStoreEmployee(userId, storeId, role): Observable<StoreEmployeeModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<StoreEmployeeModel>(
      `${API_STORE_URL}/employee`,
      { userId, storeId, role },
      { headers: httpHeaders },
    );
  }

  // READ
  // Server should return filtered specs by storeId
  getAllStoreSpecsByStoreId(storeId: number): Observable<StoreEmployeeModel[]> {
    const url = API_STORE_URL + '?storeId=' + storeId;
    return this.http.get<StoreEmployeeModel[]>(url);
  }

  getStoreSpecById(storeSpecId: number): Observable<StoreEmployeeModel> {
    return this.http.get<StoreEmployeeModel>(API_STORE_URL + `/${storeSpecId}`);
  }

  // Server should return sorted/filtered specs and merge with items from state
  findStoreEmployees(
    queryParams: QueryParamsModel,
    storeId: number,
  ): Observable<QueryResultsModel> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

    const url = `${API_STORE_URL}/${storeId}/employees`;
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params: httpParams,
    });
  }

  // UPDATE => PUT: update the store employee on the server
  updateStoreSpec(storeSpec: StoreEmployeeModel): Observable<any> {
    return this.http.put(API_STORE_URL, storeSpec, {
      headers: this.httpUtils.getHTTPHeaders(),
    });
  }

  // DELETE => delete the store employee from the server
  deleteStoreEmployee(storeId: number, employeeId: number): Observable<any> {
    const url = `${API_STORE_URL}/${storeId}/employee/${employeeId}`;
    return this.http.delete<StoreEmployeeModel>(url);
  }

  deleteStoreEmployees(ids: number[] = []): Observable<any> {
    const url = API_STORE_URL + '/deleteStoreEmployees';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { storeEmployeeIdsForDelete: ids };
    return this.http.put<any>(url, body, { headers: httpHeaders });
  }

  getSpecs(): string[] {
    return SPECIFICATIONS_DICTIONARY;
  }
}
