import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthNoticeService, AuthService } from '../../../../core/auth';
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: UntypedFormGroup;
  loading = false;
  errors: any = [];
  private token: string;
  public errorMessage: string = '';
  public showLoginButton: boolean = false;
  public showPasswordForm: boolean = true;
  private unsubscribe: Subject<any>;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authNoticeService: AuthNoticeService,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegistrationForm();
    this.token = this.activatedRoute.snapshot.params.token || null;
    if (this.token === null) {
      this.router.navigateByUrl('auth/login');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next({});
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegistrationForm() {
    this.resetPasswordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(30),
          ]),
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: ConfirmPasswordValidator.MatchPassword,
      },
    );
  }

  submit() {
    this.errorMessage = '';
    const controls = this.resetPasswordForm.controls;
    if (this.resetPasswordForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }

    this.loading = true;

    const password = controls['password'].value;

    this.authService.resetPassword(password, this.token).subscribe(
      (res) => {
        this.authNoticeService.setNotice(
          'Your password was successfully updated',
          'success',
        );
        this.showPasswordForm = false;
        this.showLoginButton = true;
        this.cdr.detectChanges();
      },
      () => {
        this.loading = false;
        this.cdr.detectChanges();
      },
    );
  }

  goToLogin(): void {
    this.router.navigateByUrl('auth/login');
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }
}
