import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../../core/_base/crud';
import {
  HeadquarterStoreModel,
  HeadquarterStoreOnServerCreated,
  HeadquarterStoresDataSource,
  HeadquarterStoresPageRequested,
  HeadquarterStoreUpdated,
  ManyHeadquarterStoresDeleted,
  OneHeadquarterStoreDeleted,
  selectLastCreatedHeadquarterStoreId,
} from '../../../../../../../../core/headquarter';
import { StoreEditDialogComponent } from '../store-edit/store-edit-dialog.component';
import { StoreModel } from '../../../../../../../../core/store';
import { NgxPermissionsService } from 'ngx-permissions';
import {HeadquarterConfiguration} from "../../../../../../../../core/catalog/_services/provider.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'kt-stores-list',
  templateUrl: './stores-list.component.html',
  styleUrls: ['./stores-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoresListComponent implements OnInit, OnDestroy {
  @Input() headquarterId$: Observable<number>;
  headquarterId: number;
  @Input() headquarterConfigurationCode: string;
  dataSource: HeadquarterStoresDataSource;
  displayedColumns = [
    /*'select',*/ 'name',
    'address',
    'phone',
    'email',
    'actions',
  ];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  selection = new SelectionModel<HeadquarterStoreModel>(true, []);
  headquarterStoresResult: HeadquarterStoreModel[] = [];
  private componentSubscriptions: Subscription;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private permissionsService: NgxPermissionsService,
  ) {}

  ngOnInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadStoresList();
        }),
      )
      .subscribe();

    // Filtration, bind to searchInput
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadStoresList();
        }),
      )
      .subscribe();

    // Init DataSource
    this.dataSource = new HeadquarterStoresDataSource(this.store);
    this.dataSource.entitySubject.subscribe(
      (res) => (this.headquarterStoresResult = res),
    );
    this.headquarterId$.subscribe((res) => {
      if (!res) {
        return;
      }

      this.headquarterId = res;
      // of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadStoresList();
      // }); // Remove this line, just loading imitation
    });
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  loadStoresList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );
    // Call request from server
    this.store.dispatch(
      new HeadquarterStoresPageRequested({
        page: queryParams,
        headquarterId: this.headquarterId,
      }),
    );
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    filter._specificationName = searchText;
    filter.value = searchText;
    return filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.headquarterStoresResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.headquarterStoresResult.forEach((row) => this.selection.select(row));
    }
  }

  deleteStore(_item: HeadquarterStoreModel) {
    const _title: string = 'Store Delete';
    const _description: string =
      'Are you sure to remove this store from headquarter?';
    const _waitDesciption: string = 'Store is removing...';
    const _deleteMessage = `Store has been removed`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(
        new OneHeadquarterStoreDeleted({
          storeId: _item.id,
          headquarterId: this.headquarterId,
        }),
      );
      setTimeout(() => {
        this.loadStoresList();
      }, 1000);
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete,
      );
    });
  }

  deleteStores() {
    const _title: string = 'Stores Delete';
    const _description: string =
      'Are you sure to permanently delete selected stores?';
    const _waitDesciption: string = 'Stores are deleting...';
    const _deleteMessage = 'Selected stores have been deleted';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const length = this.selection.selected.length;
      const idsForDeletion: number[] = [];
      for (let i = 0; i < length; i++) {
        idsForDeletion.push(this.selection.selected[i].id);
      }
      this.store.dispatch(
        new ManyHeadquarterStoresDeleted({ ids: idsForDeletion }),
      );
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete,
      );
      this.selection.clear();
    });
  }

  fetchStores() {
    const messages = [];
    this.selection.selected.forEach((elem) => {
      messages.push({
        text: `${elem.email}`,
        id: elem.id,
        // text: `${elem._specificationName}: ${elem.value}`, id: elem.id
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }

  addStore() {
    // tslint:disable-next-line:prefer-const
    let newSpec = new HeadquarterStoreModel();
    newSpec.clear(this.headquarterId);
    const dialogRef = this.dialog.open(StoreEditDialogComponent, {
      data: {
        isNew: true,
        headquarterId: this.headquarterId,
        headquarterConfigurationCode: this.headquarterConfigurationCode,
      },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((newStore) => {
      if (newStore) {
        let store = this.prepareStore(newStore);
        this.store.dispatch(
          new HeadquarterStoreOnServerCreated({ store: store }),
        );
        this.componentSubscriptions = this.store
          .pipe(select(selectLastCreatedHeadquarterStoreId))
          .subscribe((result) => {
            if (!result) {
              return;
            }

            const saveMessage = `Store has been added`;
            this.layoutUtilsService.showActionNotification(
              saveMessage,
              MessageType.Create,
              5000,
              true,
              false,
            );
          });
      }
    });
  }

  prepareStore(store): StoreModel {
    let permissions = this.permissionsService.getPermissions();
    const _store = new StoreModel();
    _store.name = store.name;
    _store.address = store.address;
    _store.phone = store.phone;
    _store.externalReferenceId = store.externalReferenceId;
    if (permissions['ROLE_SUPER_ADMIN']) {
      _store.headquarterId = this.headquarterId;
    }
    if (this.headquarterConfigurationCode === HeadquarterConfiguration.Hungary) {
      _store.operatorStoreId = store.operatorStoreId;
      _store.insuranceStoreId = store.insuranceStoreId;
      _store.ticketingStoreId = store.ticketingStoreId;
    }
    return _store;
  }

  editStore(item: HeadquarterStoreModel) {
    const _item = Object.assign({}, item);
    const dialogRef = this.dialog.open(StoreEditDialogComponent, {
      data: {
        storeId: _item.id,
        role: 'store',
        isNew: false,
      },
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.isUpdated) {
        const updateHeadquarterStore: Update<HeadquarterStoreModel> = {
          id: _item.id,
          changes: _item,
        };
        this.store.dispatch(
          new HeadquarterStoreUpdated({
            partialHeadquarterStore: updateHeadquarterStore,
            headquarterStore: _item,
          }),
        );
        const saveMessage = `Store has been updated`;
        this.layoutUtilsService.showActionNotification(
          saveMessage,
          MessageType.Update,
          10000,
          true,
          true,
        );
      }
    });
  }
}
