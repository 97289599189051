// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { HeadquarterEmployeesState } from '../_reducers/headquarter-employee.reducers';
import { HeadquarterEmployeeModel } from '../_models/headquarter-employee.model';

export const selectHeadquarterEmployeesState =
  createFeatureSelector<HeadquarterEmployeesState>('headquarterEmployees');

export const selectHeadquarterEmployeeById = (headquarterEmployeeId: number) =>
  createSelector(
    selectHeadquarterEmployeesState,
    (headquarterEmployeesState) =>
      headquarterEmployeesState.entities[headquarterEmployeeId],
  );

export const selectHeadquarterEmployeesPageLoading = createSelector(
  selectHeadquarterEmployeesState,
  (headquarterEmployeesState) => headquarterEmployeesState.loading,
);

export const selectCurrentHeadquarterIdInStoreForHeadquarterSpecs =
  createSelector(
    selectHeadquarterEmployeesState,
    (headquarterEmployeesState) => headquarterEmployeesState.headquarterId,
  );

export const selectLastCreatedHeadquarterEmployeeId = createSelector(
  selectHeadquarterEmployeesState,
  (headquarterEmployeesState) =>
    headquarterEmployeesState.lastCreatedHeadquarterEmployeeId,
);

export const selectPSShowInitWaitingMessage = createSelector(
  selectHeadquarterEmployeesState,
  (headquarterEmployeesState) =>
    headquarterEmployeesState.showInitWaitingMessage,
);

export const selectHeadquarterEmployeesInStore = createSelector(
  selectHeadquarterEmployeesState,
  (headquarterEmployeesState) => {
    const items: HeadquarterEmployeeModel[] = [];
    each(headquarterEmployeesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: HeadquarterEmployeeModel[] = httpExtension.sortArray(
      items,
      headquarterEmployeesState.lastQuery.sortField,
      headquarterEmployeesState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(
      result,
      headquarterEmployeesState.totalCount,
      '',
    );
  },
);
