import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(
    array: any[],
    field: string,
    value?: string | string[] | { title: string }[],
  ) {
    if (array !== undefined && value !== undefined) {
      if (typeof value === 'string') {
        const string = value.toLowerCase();

        if (typeof array[0] === 'string') {
          return array.filter(function (el: any) {
            return el.toLowerCase().indexOf(string) > -1;
          });
        }

        return array.filter(function (el: any) {
          return el[field].toLowerCase().indexOf(string) > -1;
        });
      }

      if (Array.isArray(value) && typeof value[0] === 'string') {
        let res = [];

        array.map((el) => {
          if (value.length > 0) {
            // @ts-ignore
            value.map((arrString) => {
              if (
                el[field].toLowerCase().indexOf(arrString.toLowerCase()) > -1
              ) {
                res.push(el);
              }
            });
          } else {
            res.push(el);
          }
        });

        return res;
      }
    }

    // if there is no incoming data. Returns unique values ​​in an array
    if (array !== undefined && value === undefined) {
      let allValue = array.map((elem) => elem[field]);
      return Array.from(new Set(allValue));
    }
  }
}
