import { Component, Inject, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-activity-menu',
  templateUrl: './activity-menu.component.html',
  styleUrls: ['./activity-menu.component.scss'],
})
export class ActivityMenuComponent implements OnInit {
  public displayingTimer = {
    minutes: 2,
    seconds: '00',
  };

  constructor(
    public dialogRef: MatDialogRef<ActivityMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    timer(1000, 1000).subscribe((res) => {
      this.displayingTimer.minutes = parseInt(
        ((120 - res) / 60).toString(),
        10,
      );
      this.displayingTimer.seconds = (
        120 -
        this.displayingTimer.minutes * 60 -
        res
      ).toString();

      if (res === 120) {
        this.autoLogout();
      }
    });
  }

  public confirm() {
    this.data.action = 'confirm';
    this.dialogRef.close(this.data);
  }

  public logout() {
    this.data.action = 'logout';
    this.dialogRef.close(this.data);
  }

  private autoLogout() {
      this.data.action = 'auto-logout';
      this.dialogRef.close(this.data);
  }
}
