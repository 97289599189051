import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../core/reducers';
import { QueryParamsModel } from '../../../../../../../../core/_base/crud';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  EmployeesRequestsPageRequested,
  EmployeesRequestsUpdated,
} from '../../../../../../../../core/headquarter/_actions/employees-requests.action';
import { EmployeesRequestsDataSource } from '../../../../../../../../core/headquarter/_data-sources/employees-requests.datasource';
import { EmployeesRequestsModel } from '../../../../../../../../core/headquarter/_models/employees-requests.model';

@Component({
  selector: 'kt-employees-requests',
  templateUrl: './employees-requests.component.html',
  styleUrls: ['./employees-requests.component.scss'],
})
export class EmployeesRequestsComponent implements OnInit, OnDestroy {
  @Input() headquarterId$: Observable<number>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  private destroyStream$ = new Subject<void>();
  headquarterId: number;
  dataSource: EmployeesRequestsDataSource;
  displayedColumns = ['email', 'accept', 'decline'];
  selection = new SelectionModel<EmployeesRequestsModel>(true, []);
  employeesRequestsResult: EmployeesRequestsModel[] = [];

  constructor(private store: Store<AppState>, public dialog: MatDialog) {}

  ngOnInit() {
    this.sort.sortChange
      .pipe(takeUntil(this.destroyStream$))
      .subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadEmployeesList();
        }),
      )
      .subscribe();

    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.destroyStream$),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadEmployeesList();
        }),
      )
      .subscribe();

    this.dataSource = new EmployeesRequestsDataSource(this.store);
    this.dataSource.entitySubject
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => (this.employeesRequestsResult = res));

    this.headquarterId$
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        this.headquarterId = res;
        this.loadEmployeesList();
      });
  }

  ngOnDestroy() {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }

  loadEmployeesList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );
    this.store.dispatch(
      new EmployeesRequestsPageRequested({
        page: queryParams,
        headquarterId: this.headquarterId,
      }),
    );
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    filter._specificationName = searchText;
    filter.value = searchText;
    return filter;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.employeesRequestsResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.employeesRequestsResult.forEach((row) => this.selection.select(row));
    }
  }

  declineEmployee(employee) {
    this.store.dispatch(
      new EmployeesRequestsUpdated({
        employeeRequests: { id: employee.id, status: 'DECLINED' },
      }),
    );
  }

  acceptEmployee(employee) {
    this.store.dispatch(
      new EmployeesRequestsUpdated({
        employeeRequests: { id: employee.id, status: 'APPROVED' },
      }),
    );
  }
}
