import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { AuthService, FakeLogout, Logout } from '../../../auth';
import { Router } from '@angular/router';
import { LayoutUtilsService, MessageType } from './layout-utils.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStorageService } from '../../../services/local-storage.service';

const helper = new JwtHelperService();

@Injectable()
export class InterceptService implements HttpInterceptor {
    private isRefreshInProgress: boolean = false;

    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null,
    );

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private utilService: LayoutUtilsService,
        private authService: AuthService,
        private permissionService: NgxPermissionsService,
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let authToken = LocalStorageService.get(environment.authTokenKey);
        let refreshToken = LocalStorageService.get('refresh');

        if (this.checkIsToken(authToken) && this.checkIsToken(refreshToken)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${LocalStorageService.get(
                        environment.authTokenKey,
                    )}`,
                },
            });
        }

        if (this.checkIsToken(authToken) && this.checkIsToken(refreshToken)) {
            if (helper.isTokenExpired(authToken)) {
                if (helper.isTokenExpired(refreshToken)) {
                    this.utilService.showActionNotification(
                        'Session expired',
                        MessageType.Update,
                        10000,
                        true,
                    );
                    this.store.dispatch(new Logout());
                    location.reload();
                }
            }
        }

        return next.handle(request).pipe(
            catchError((failedRequest) => {
                if (failedRequest.status === 401) {
                    return this.unauthorisedHandler(next, request);
                } else if (failedRequest.status === 600) {
                    return this.providerSideErrorHandler(failedRequest);
                } else if (failedRequest.status === 422) {
                    return this.unprocessableEntityHandler(failedRequest);
                } else if (failedRequest.status === 500) {
                    return this.internalServerErrorHandler(request, failedRequest);
                } else if (failedRequest.url && failedRequest.url.includes('sim-card/activate')) {
                    return throwError(failedRequest);
                } else if (
                    failedRequest.url &&
                    !failedRequest.url.includes('autologin') &&
                    !failedRequest.url.endsWith('headquarter') &&
                    !failedRequest.url.endsWith('login')
                ) {
                    return this.defaultErrorHandler(failedRequest);
                } else {
                    return throwError(failedRequest);
                }
            }),
        );
    }

    addAuthenticationToken(req) {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${LocalStorageService.get(
                    environment.authTokenKey,
                )}`,
            },
        });
    }

    private refreshToken() {
        this.authService
            .getNewTokens(LocalStorageService.get('refresh'))
            .subscribe((success) => {
                // console.log(success);
                LocalStorageService.save(environment.authTokenKey, success.token);
                LocalStorageService.save('refresh', success.refreshToken);
                this.refreshTokenSubject.next(success);
            });
    }

    checkIsToken(token: string) {
        if (token) {
            try {
                helper.isTokenExpired(token);
            } catch (err) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    private transformHttpErrorToJsonOrString(httpError: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (httpError instanceof ArrayBuffer) {
                resolve(String.fromCharCode.apply(null, new Uint8Array(httpError)));
            } else if (httpError instanceof Blob) {
                const blobReader = new FileReader();

                blobReader.addEventListener('loadend', () => {
                    try {
                        const parsed = JSON.parse(blobReader.result as string);
                        resolve(parsed);
                    } catch {
                        resolve(blobReader.result);
                    }
                });

                blobReader.readAsText(httpError);
            } else {
                resolve(httpError);
            }
        });
    }

    private internalServerErrorHandler(request, failedRequest): Observable<any> {
        if (request.url.includes('token')) {
            this.utilService.showActionNotification(
                'Session expired',
                MessageType.Update,
                10000,
                true,
            );
        } else {
            this.utilService.showActionNotification(
                'Internal server error',
                MessageType.Update,
                10000,
                true,
            );
        }

        return throwError(failedRequest);
    }

    private providerSideErrorHandler(failedRequest): Observable<any> {
        this.utilService.showActionNotification(
            failedRequest.error.error || failedRequest.error.message,
            MessageType.Read,
            10000,
            true,
        );
        return throwError('This is a server error!');
    }

    private defaultErrorHandler(failedRequest): Observable<any> {
        this.utilService.showActionNotification(
            failedRequest.url +
            '\n' +
            failedRequest.statusText +
            `\n` +
            (failedRequest.error.error || failedRequest.error.message),
            MessageType.Update,
            10000,
            true,
        );
        return throwError(failedRequest);
    }

    private unprocessableEntityHandler(failedRequest): Observable<any> {
        return from(
            this.transformHttpErrorToJsonOrString(failedRequest.error),
        ).pipe(
            switchMap((err) => {
                let errMsg = '';

                err.errors.forEach((element) => {
                    errMsg += Array.isArray(element.desc)
                        ? `${element.desc.join('. ')} \n`
                        : `${element.desc}. <br/>`;
                });

                this.utilService.showActionNotification(
                    errMsg,
                    MessageType.Update,
                    10000,
                    true,
                );

                return throwError(err);
            }),
        );
    }

    private unauthorisedHandler(next, request): Observable<any> {
        let authTokenFromStorage = LocalStorageService.get(environment.authTokenKey);
        let refreshTokenFromStorage = LocalStorageService.get('refresh');
        if (
            this.checkIsToken(authTokenFromStorage) &&
            this.checkIsToken(refreshTokenFromStorage)
        ) {
            if (this.isRefreshInProgress) {
                return this.refreshTokenSubject.pipe(
                    filter((result) => result != null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(request))),
                );
            } else {
                this.isRefreshInProgress = true;

                this.refreshTokenSubject.next(null);
                return this.authService.getNewTokens(refreshTokenFromStorage).pipe(
                    switchMap((success: any) => {
                        LocalStorageService.save(environment.authTokenKey, success.token);
                        LocalStorageService.save('refresh', success.refreshToken);
                        this.refreshTokenSubject.next(success);
                        return next.handle(this.addAuthenticationToken(request));
                    }),
                    catchError((err) => {
                        return of(err);
                    }),
                    finalize(() => (this.isRefreshInProgress = false)),
                );
            }
        } else {
            this.store.dispatch(new Logout());
            location.reload();
        }
    }
}
