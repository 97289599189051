import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HeadquarterEmployeeModel } from '../_models/headquarter-employee.model';
import { QueryParamsModel } from '../../_base/crud';
import {
  EmployeesRequestsActions,
  EmployeesRequestsActionTypes,
} from '../_actions/employees-requests.action';
import { EmployeesRequestsModel } from '../_models/employees-requests.model';
import { HeadquarterEmployeeActionTypes } from '../_actions/headquarter-employee.actions';

export interface EmployeesRequestsState
  extends EntityState<EmployeesRequestsModel> {
  headquarterId: number;
  loading: boolean;
  totalCount: number;
  lastCreatedHeadquarterEmployeeId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<EmployeesRequestsModel> =
  createEntityAdapter<EmployeesRequestsModel>();

export const initialEmployeesRequestsState: EmployeesRequestsState =
  adapter.getInitialState({
    loading: false,
    totalCount: 0,
    headquarterId: undefined,
    lastCreatedHeadquarterEmployeeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function employeesRequestsReducer(
  state = initialEmployeesRequestsState,
  action: EmployeesRequestsActions,
): EmployeesRequestsState {
  switch (action.type) {
    case EmployeesRequestsActionTypes.EmployeesRequestsPageToggleLoading:
      return {
        ...state,
        loading: action.payload.isLoading,
        lastCreatedHeadquarterEmployeeId: undefined,
      };
    case EmployeesRequestsActionTypes.EmployeesRequestsPageRequested:
      return {
        ...state,
        totalCount: 0,
        loading: true,
        headquarterId: action.payload.headquarterId,
        lastQuery: action.payload.page,
      };
    case EmployeesRequestsActionTypes.OneEmployeesRequestsDeleted:
      return adapter.removeOne(action.payload, state);
    case EmployeesRequestsActionTypes.EmployeesRequestsPageLoaded:
      return adapter.addMany(action.payload.employeesRequests, {
        ...initialEmployeesRequestsState,
        totalCount: action.payload.totalCount,
        loading: false,
        headquarterId: state.headquarterId,
        lastQuery: state.lastQuery,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}
