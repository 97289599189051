<div mat-dialog-content>
    <mat-list>
        <div mat-subheader>
            {{ 'CATALOG.CHECKOUT.SUCCESS.MESSAGE' | translate }}
        </div>
        <ng-container  *ngFor="let order of data.orders">
            <mat-list-item class='auto-height'>
                <div mat-line>{{ order.orderIds.toString() }}</div>
                <ng-container *ngIf="order.voucher">
                    <a [href]="order.voucher" target="_blank">
                        <mat-icon [matTooltip]="order.voucher | truncate: [10]">
                            link
                        </mat-icon>
                    </a>
                </ng-container>
                <ng-container *ngIf="order.agreement">
                    <qr-code *ngIf="!!order.agreement && order.agreement.qr"
                             [value]="order.agreement.link"
                             [size]="100"></qr-code>
                    <a [href]="order.agreement.link"
                       *ngIf="!!order.agreement && !order.agreement.qr"
                       target="_blank">
                        <mat-icon [matTooltip]="order.agreement.link | truncate: [10]">
                            link
                        </mat-icon>
                    </a>
                    <button *ngIf="!!order.agreement" (click)="showLinkQr(order.agreement)"
                            type="button" mat-icon-button color="primary"
                            [matTooltip]="order.agreement.qr
                                 ? ('CATALOG.CHECKOUT.SUCCESS.ICONS.LINK' | translate)
                                 : 'CATALOG.CHECKOUT.SUCCESS.ICONS.PHOTO_CAMERA' | translate">
                        <ng-container [ngSwitch]="order.agreement.qr">
                            <mat-icon>
                                <ng-container *ngSwitchCase="true">link</ng-container>
                                <ng-container *ngSwitchCase="false">photo_camera</ng-container>
                            </mat-icon>
                        </ng-container>
                    </button>
                    <button *ngIf="!!order.agreement.link"
                            type="button"
                            mat-icon-button
                            color="primary"
                            [matTooltip]="'CATALOG.CHECKOUT.SUCCESS.ICONS.EMAIL' | translate"
                            (click)="sendEmail(order.agreement.link)">
                        <mat-icon>email</mat-icon>
                    </button>
                    <button *ngIf="order.agreement.documentId" disabled class='success' type="button" mat-icon-button>
                        <ng-container [ngSwitch]="order.agreement.status">
                            <mat-icon *ngSwitchCase="'pending'" [matTooltip]="'CATALOG.CHECKOUT.SUCCESS.ICONS.ASSIGNMENT' | translate">
                                assignment
                            </mat-icon>
                            <mat-icon (click)="copyMessage(order.agreement.providerOrderId)" *ngSwitchCase="'closed'" [matTooltip]="order.agreement.providerOrderId
                            ? order.agreement.providerOrderId
                            : order.agreement.status === 'signed' ? 'Signed': 'Closed'">
                                <ng-container *ngSwitchCase="'signed'">done_all</ng-container>
                                <ng-container *ngSwitchCase="'closed'">done_all</ng-container>
                            </mat-icon>
                            <mat-icon class='error' *ngSwitchCase="'error'" [matTooltip]="order.agreement.errorMessage">
                                error
                            </mat-icon>
                        </ng-container>
                    </button>
                </ng-container>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
        <mat-list-item class='auto-height' *ngIf="hasTeliaPayment">
            <div mat-line>Delbetalning</div>
            <ng-container [ngSwitch]="data.purchase.qr">
                <ng-container>
                    <qr-code *ngIf="data.purchase.qr"
                             [value]="purchaseLink" [size]="100">
                    </qr-code>
                    <button *ngIf="!data.purchase.qr" (click)="showTeliaPayment()"
                            type="button" mat-icon-button color="primary" [matTooltip]="'Open Telia payment'">
                        <mat-icon>link</mat-icon>
                    </button>
                </ng-container>
                <button (click)="showLinkQr(data.purchase)"
                        type="button" mat-icon-button color="primary"
                        [matTooltip]="data.purchase.qr
                                 ? ('CATALOG.CHECKOUT.SUCCESS.ICONS.LINK' | translate)
                                 : 'CATALOG.CHECKOUT.SUCCESS.ICONS.PHOTO_CAMERA' | translate">
                    <ng-container [ngSwitch]="data.purchase.qr">
                        <mat-icon>
                            <ng-container *ngSwitchCase="true">link</ng-container>
                            <ng-container *ngSwitchCase="false">photo_camera</ng-container>
                        </mat-icon>
                    </ng-container>
                </button>
                <button type="button" mat-icon-button color="primary" [matTooltip]="'Mail Telia payment'">
                    <mat-icon>email</mat-icon>
                </button>
                <button type="button" mat-icon-button color="primary" class='success' disabled>
                    <ng-container [ngSwitch]="data.purchase.signed">
                        <mat-icon [matTooltip]="'Telia payment'">
                            <ng-container *ngSwitchCase="true">done_all</ng-container>
                            <ng-container *ngSwitchCase="false">assignment</ng-container>
                        </mat-icon>
                    </ng-container>
                </button>
            </ng-container>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item class='auto-height'>
            <ng-container [ngSwitch]="agreementsChecking || paymentChecking">
                <ng-container *ngSwitchCase="true">
                    <div mat-line class='mt-10 row'>
                        <mat-spinner [diameter]="30" class='mrl-10'>
                        </mat-spinner>Väntar på signering</div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <div *ngIf='!ordersError' mat-line class='mt-10 row'>
                        <mat-icon class='mrl-10'>
                            done_all
                        </mat-icon>
                        Order slutförd
                    </div>
                    <div *ngIf='ordersError' mat-line class='mt-10 row'>
                        <mat-icon class='mrl-10 error'>
                            error
                        </mat-icon>
                        Order misslyckades
                    </div>
                </ng-container>
            </ng-container>
        </mat-list-item>
    </mat-list>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-button (click)="onStayClick()">
        {{ 'CATALOG.CHECKOUT.SUCCESS.BACK' | translate }}
    </button>
    <button mat-button (click)="onCatalogClick()" cdkFocusInitial>
        {{ 'CATALOG.CHECKOUT.SUCCESS.NEXT' | translate }}
    </button>
</div>
