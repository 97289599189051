import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutConfigService, MenuAsideService, MenuConfigService } from '../../../../core/_base/layout';
import { LayoutConfig } from '../../../../core/_config/dashboard/layout.config';
import { MenuConfig } from '../../../../core/_config/dashboard/menu.config';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { currentUser, currentUserRoleIds, Permission } from '../../../../core/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
    selector: 'kt-base3',
    templateUrl: './base3.component.html',
    styleUrls: ['./base3.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class Base3Component implements OnInit, OnDestroy {
    isSidenavOpen = false;
    darkMode: boolean = false;

    constructor(private layoutConfigService: LayoutConfigService,
                private menuConfigService: MenuConfigService,
                public menuAsideService: MenuAsideService,
                private permissionsService: NgxPermissionsService,
                private store: Store<AppState>,
                private router: Router,
                private overlay: OverlayContainer) {
    }
    private destroyStream$ = new Subject();

    private CampaignModuleEnabled: boolean;
    private StaffBonusModuleEnabled: boolean;


    loadRolesWithPermissions() {
        this.store
            .pipe(select(currentUser))
            .subscribe((res) => {
                if (res?.headquarter) {
                    this.CampaignModuleEnabled = res.headquarter.campaignModule;
                    this.StaffBonusModuleEnabled = res.headquarter.staffBonusModule;
                }
            });
        this.store.pipe(select(currentUserRoleIds))
            .pipe(takeUntil(this.destroyStream$))
            .subscribe((res) => {
                if (!res || res.length === 0) {
                    return;
                }

                this.permissionsService.flushPermissions();
                this.permissionsService.loadPermissions(res.map((role) => role.name));
            });
    }

    toggleSidenavLock() {
        this.isSidenavOpen = !this.isSidenavOpen;
    }

    ngOnInit(): void {
        this.loadConfigs();
    }

    ngOnDestroy(): void {
        this.destroyStream$.next({});
        this.destroyStream$.complete();
    }

    private loadConfigs(): void {
        this.loadRolesWithPermissions();

        this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
        this.menuConfigService.loadConfigs(new MenuConfig().configs);
    }

    clickItem(item) {
        if (item.submenu) {
            this.isSidenavOpen = true;
            item.opened = !item.opened;
            return
        }
        this.routeTo(item);
    }

    routeTo(item) {
        this.router.navigate([item.page]);
    }

    titleFilter(title: string): boolean {
        if ((title === 'Campaigns' && !this.CampaignModuleEnabled) ||
            (title === 'Staff bonus' && !this.StaffBonusModuleEnabled)) {
            return;
        }

        return true;
    }

    switchTheme() {
        this.darkMode = !this.darkMode;
        if (this.darkMode) {
            this.overlay.getContainerElement().classList.add('darkMode');
        } else {
            this.overlay.getContainerElement().classList.remove('darkMode');
        }
    }
}
