import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {CalculatedModel, CalculationOrder, CalculationRequestModel} from '../_models/calculation.model';
import {ApplicationService} from '../../services/application.service';
import {DevicesReducerState} from '../_reducers/devices.reducers';
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { EditOrderModel, SaveOrderModel } from '../../order';
import { CanselOrderModel } from '../../order/_models/order.model';
import { ITeliaPurchaseResponse } from '../../order/_models/telia-purchase.model';
import { FlowService } from '../../services/flow.service';
import { LocalStorageService } from "../../services/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class OrdersService {
    private readonly baseUrl = `${environment.api_url}/order`;
    private readonly httpOptions = this.httpUtils.getHTTPHeaders();

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private app: ApplicationService, private flow: FlowService) {

    }

    calculateMultiple(body: CalculationRequestModel): Observable<CalculatedModel> {
        return this.http.post<CalculatedModel>(`${this.baseUrl}/calculate/multiple`, body);
    }

    calculate(data: any) {
        return this.http.post(`${this.baseUrl}/calculate`, data);
    }

    prepareMultipleCalculationReqData(): {manualFlow: boolean, orders: Array<CalculationOrder>} {
        const manualFlow = !this.flow.providerConnectedMode;
        const orders = this.app.previousOrders$.value;

        return {manualFlow,orders: orders.map((o: DevicesReducerState) => {
            const campaigns = o.selectedCampaigns.map((c) => ({
                id: c.id,
                discount: c.discount,
                bundlings: c.bundlings,
            }));
            let discountArticles = o.generatedOrder.salesDiscountArticles ? o.generatedOrder.salesDiscountArticles.map(a => ({id: a.id})) : [];
            return {
                id: o.id,
                deviceId: o.selectedDevice.id ? o.selectedDevice.id : undefined,
                subventionId: o.generatedOrder.subvention ? o.generatedOrder.subvention.id : undefined,
                contractId: o.selectedContract.id,
                offerId: o.selectedContract.offerId,
                provider: o.selectedContract.providerContract ? Object.assign({},o.selectedContract.providerContract,o.selectedContract.providerContract.providerRelatedData) : {},
                extra: o.generatedOrder.extras ? o.generatedOrder.extras.map(e => e.contract.id) : [],
                discountArticles: discountArticles,
                ignoreStartingFee: o.ignoreStartingFee,
                campaigns,
                customSalesDiscount: o.generatedOrder.customSalesDiscount
            };
        })};
    }

    sendOrderToWebhallen(id: number): Observable<any> {
        return this.http.post(`${this.baseUrl}/send/webhallen`,{id});
    }

    createOrders(req: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/new`, req);
    }

    findOrder(
        filterUrl: string,
        queryParams: QueryParamsModel,
    ): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = `${this.baseUrl}`;

        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams,
        });
    }

    getReportInfo(
        filterUrl: string,
        queryParams: QueryParamsModel,
    ): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParamsReport(queryParams);

        const url = `${this.baseUrl}/report`;

        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams,
        });
    }

    getOneOrder(id): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/${id}`, {
            headers: this.httpOptions,
        });
    }

    blockOneOrder(
        id,
        blockOrder: SaveOrderModel | {},
    ): Observable<EditOrderModel> {
        return this.http.patch<any>(
            `${this.baseUrl}/grab/${id}`,
            blockOrder,
            { headers: this.httpOptions },
        );
    }

    createNewOrder(customer: SaveOrderModel) {
        return this.http.post<any>(`${this.baseUrl}`, customer);
    }

    updateOneOrder(id, saveOrder: SaveOrderModel) {
        return this.http.patch<any>(
            `${this.baseUrl}/${id}`,
            saveOrder,
            { headers: this.httpOptions },
        );
    }

    cancelOrder(id, canselOrder: CanselOrderModel) {
        return this.http.patch<any>(
            `${this.baseUrl}/${id}`,
            canselOrder,
            { headers: this.httpOptions },
        );
    }

    deleteOneOrder(id): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/${id}`, {
            headers: this.httpOptions,
        });
    }

    downloadReport(
        filterUrl: string,
        queryParams: QueryParamsModel,
        detailed: boolean,
    ) {
        const url = `${this.baseUrl}/report/download`;

        let params;
        if (queryParams.sortField && queryParams.sortOrder) {
            params = new HttpParams()
                .set(
                    'sort',
                    `${queryParams.sortOrder === 'asc' ? '' : '-'}${
                        queryParams.sortField
                    }`,
                )
                .set('page', queryParams.pageNumber.toString())
                .set('query', queryParams.filter || '')
                .set('salesId', queryParams.salesFilter || '')
                .set('provider', queryParams.providerFilter || '')
                .set('dateFrom', queryParams.dateStartFilter || '')
                .set('dateTo', queryParams.dateEndFilter || '')
                .set('limit', queryParams.pageSize.toString())
                .set('headquarterId', queryParams.headquarterId.toString() || null)
                .set('detailed', String(detailed));
            // .set('state', queryParams.statusFilter || '');
        } else {
            params = new HttpParams()
                // .set('sort', `${queryParams.sortOrder === 'asc' ? '' : '-'}${queryParams.sortField}`)
                .set('page', queryParams.pageNumber.toString())
                .set('query', queryParams.filter || '')
                .set('salesId', queryParams.salesFilter || '')
                .set('provider', queryParams.providerFilter || '')
                .set('dateFrom', queryParams.dateStartFilter || '')
                .set('dateTo', queryParams.dateEndFilter || '')
                .set('limit', queryParams.pageSize.toString())
                .set('headquarterId', queryParams.headquarterId.toString() || null)
                .set('detailed', String(detailed));
            // .set('state', queryParams.statusFilter || '');
        }

        return this.http.get(url, {
            params: params,
            responseType: 'blob',
        });
    }

    checkOrdersDocumentStatus(orderIds: Array<number>) {
        const body = {
            orderIds,
        };
        return this.http.post(`${this.baseUrl}/agreements`, body);
    }
}
