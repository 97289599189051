import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { RegexService } from './regex.service';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';

@Injectable()
export class FormValidationRegExService extends Validators {
  constructor(private regex: RegexService) {
    super();
  }

  name(): ValidatorFn {
    return (control) => {
      const composedValidator = Validators.compose([
        Validators.required,
        Validators.pattern(this.regex.name),
      ]);

      return composedValidator(control);
    };
  }

  email(): ValidatorFn {
    return (control) => {
      const composedValidator = Validators.compose([
        Validators.required,
        Validators.pattern(this.regex.email),
      ]);

      return composedValidator(control);
    };
  }

  password(): ValidatorFn {
    return (control) => {
      const composedValidator = Validators.compose([
        Validators.required,
        Validators.pattern(this.regex.password),
      ]);

      return composedValidator(control);
    };
  }

  // birthDate(): ValidatorFn {
  // 	return (control) => {
  // 		const composedValidator = Validators.compose([
  // 			Validators.required,
  // 			Validators.pattern(this.regex.date),
  // 		]);
  //
  // 		return composedValidator(control);
  // 	};
  // }

  phone(): ValidatorFn {
    return (control) => {
      const composedValidator = Validators.compose([
        Validators.required,
        Validators.pattern(this.regex.phoneNumber),
      ]);

      return composedValidator(control);
    };
  }

  text(): ValidatorFn {
    return (control) => {
      const composedValidator = Validators.compose([
        Validators.required,
        Validators.pattern(this.regex.text),
      ]);

      return composedValidator(control);
    };
  }

  isObject(control: AbstractControl): { [key: string]: boolean } | null {
    if (typeof control.value !== 'object') {
      return { isNotObject: true };
    }
    return null;
  }

  phoneNumberValidator(
    region: string,
    phoneNumberType: PhoneNumberType,
    required = false,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validNumber = !required;
      let isPhoneType = false;
      const regex = new RegExp(/^\+[0-9]+$/);
      const phoneNumberUtil = PhoneNumberUtil.getInstance();

      try {
        const controlValue = control.value || '';
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
          controlValue,
          region,
        );

        switch (phoneNumberType) {
          case PhoneNumberType.FIXED_LINE:
            isPhoneType =
              phoneNumberUtil.getNumberType(phoneNumber) ===
              PhoneNumberType.FIXED_LINE;
            break;
          case PhoneNumberType.MOBILE:
            isPhoneType =
              phoneNumberUtil.getNumberType(phoneNumber) ===
              PhoneNumberType.MOBILE;
            break;
          case PhoneNumberType.FIXED_LINE_OR_MOBILE:
            isPhoneType =
              phoneNumberUtil.getNumberType(phoneNumber) ===
                PhoneNumberType.FIXED_LINE ||
              phoneNumberUtil.getNumberType(phoneNumber) ===
                PhoneNumberType.MOBILE;
            break;
          default:
            break;
        }

        validNumber =
          phoneNumberUtil.isValidNumberForRegion(phoneNumber, region) &&
          regex.test(control.value) &&
          isPhoneType;
      } catch (e) {}

      return validNumber ? null : { wrongNumber: { value: control.value } };
    };
  }
}
