<div
  class="kt-portlet"
  [ngClass]="{
    'kt-portlet--body-progress': viewLoading,
    'kt-portlet--body-progress-overlay': loadingAfterSubmit
  }"
>
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ data.isNew ? 'Add' : 'Update' }} employee
      </h3>
    </div>
  </div>

  <form class="kt-form" [formGroup]="employeeEditForm" autocomplete="off">
    <div class="kt-portlet__body">
      <div class="kt-portlet__body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>

      <div class="kt-form__section kt-form__section--first">
        <div class="form-group kt-form__group"></div>
        <div class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid">
            <mat-hint align="start"
              >Search for an existing <strong>Employee</strong></mat-hint
            >
            <input
              matInput
              placeholder="Enter employee e-mail"
              formControlName="employeeId"
              [matAutocomplete]="matAuto"
            />

            <mat-autocomplete
              #matAuto="matAutocomplete"
              [displayWith]="formatter"
            >
              <mat-option *ngIf="searching" class="is-loading">
                searching...
                <div
                  style="display: inline; float: right; transform: scale(0.7)"
                >
                  <mat-spinner diameter="50"></mat-spinner>
                </div>
              </mat-option>
              <ng-container *ngIf="!searching">
                <mat-option
                  *ngFor="let user of filteredEmployee"
                  [value]="user"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>

            <mat-error
              *ngIf="
                employeeEditForm.get('employeeId').touched &&
                employeeEditForm.get('employeeId').hasError('required')
              "
            >
              Employee is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="employeeEditForm.get('employeeId').hasError('isNotObject')"
            >
              Please choose from <strong>existing</strong> employees
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Role" formControlName="role">
              <mat-option value="HEADQUARTER_MANAGER"
                >HEADQUARTER_MANAGER</mat-option
              >
              <mat-option value="ORDER_MANAGER">ORDER_MANAGER</mat-option>
              <mat-option value="VODAFONE_MANAGER">VODAFONE_MANAGER</mat-option>
              <mat-option value="ONLINE_PICKUP">ONLINE_PICKUP</mat-option>
              <mat-option value="VODAFONE_ADMIN">VODAFONE_ADMIN</mat-option>
            </mat-select>
            <mat-error *ngIf="employeeEditForm.get('role').hasError('require')">
              <strong>Role is required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions kt-form__actions--solid">
        <button
          type="button"
          mat-raised-button
          cdkFocusInitial
          (click)="onNoClick()"
          matTooltip="Cancel changes"
        >
          Cancel</button
        >&nbsp;
        <button
          type="submit"
          mat-raised-button
          color="primary"
          (click)="save()"
          [disabled]="employeeEditForm.invalid"
          matTooltip="Save changes"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</div>
