import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Directive({
  selector: '[ktSuperAdmin]',
})
export class SuperAdminDirective {
  constructor(
    private readonly permissionsService: NgxPermissionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.permissionsService.permissions$.subscribe((permission) => {
      if (permission['ROLE_SUPER_ADMIN']) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
