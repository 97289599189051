// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { HeadquarterStoresState } from '../_reducers/headquarter-store.reducers';
import { HeadquarterStoreModel } from '../_models/headquarter-store.model';

export const selectHeadquarterStoresState =
  createFeatureSelector<HeadquarterStoresState>('headquarterStores');

export const selectHeadquarterStoreById = (headquarterStoreId: number) =>
  createSelector(
    selectHeadquarterStoresState,
    (headquarterStoresState) =>
      headquarterStoresState.entities[headquarterStoreId],
  );

export const selectHeadquarterStoresPageLoading = createSelector(
  selectHeadquarterStoresState,
  (headquarterStoresState) => headquarterStoresState.loading,
);

export const selectCurrentHeadquarterIdInStoreForHeadquarterSpecs =
  createSelector(
    selectHeadquarterStoresState,
    (headquarterStoresState) => headquarterStoresState.headquarterId,
  );

export const selectLastCreatedHeadquarterStoreId = createSelector(
  selectHeadquarterStoresState,
  (headquarterStoresState) =>
    headquarterStoresState.lastCreatedHeadquarterStoreId,
);

export const selectPSShowInitWaitingMessage = createSelector(
  selectHeadquarterStoresState,
  (headquarterStoresState) => headquarterStoresState.showInitWaitingMessage,
);

export const selectHeadquarterStoresInStore = createSelector(
  selectHeadquarterStoresState,
  (headquarterStoresState) => {
    const items: HeadquarterStoreModel[] = [];
    each(headquarterStoresState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: HeadquarterStoreModel[] = httpExtension.sortArray(
      items,
      headquarterStoresState.lastQuery.sortField,
      headquarterStoresState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, headquarterStoresState.totalCount, '');
  },
);
