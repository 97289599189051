import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  LayoutUtilsService,
  MessageType,
  QueryResultsModel,
} from '../../_base/crud';
import { EmployeesRequestsService } from '../_services/';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  EmployeesRequestsActionTypes,
  EmployeesRequestsPageLoaded,
  EmployeesRequestsPageRequested,
  EmployeesRequestsPageToggleLoading,
  EmployeesRequestsUpdated,
  OneEmployeesRequestsDeleted,
} from '../_actions/employees-requests.action';

@Injectable({
  providedIn: 'root',
})
export class EmployeesRequestsEffects {
  hideLoadingDistpatcher = new EmployeesRequestsPageToggleLoading({
    isLoading: false,
  });

  loadHeadquarterEmployeesPage$  = createEffect(() => this.actions$.pipe(
    ofType<EmployeesRequestsPageRequested>(
      EmployeesRequestsActionTypes.EmployeesRequestsPageRequested,
    ),
    mergeMap(({ payload }) =>
      this.employeesRequestsService.findHeadquarterEmployees(
        payload.page,
        payload.headquarterId,
      ),
    ),
    map((result: QueryResultsModel) => {
      return new EmployeesRequestsPageLoaded({
        employeesRequests: result.items,
        totalCount: result.totalCount,
      });
    }),
  ));

  updateHeadquarterEmployee$  = createEffect(() => this.actions$.pipe(
    ofType<EmployeesRequestsUpdated>(
      EmployeesRequestsActionTypes.EmployeesRequestsUpdated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(
        new EmployeesRequestsPageToggleLoading({ isLoading: true }),
      );
      return this.employeesRequestsService
        .updateEmployeesStatus(payload.employeeRequests)
        .pipe(
          tap(() => {
            this.store.dispatch(
              new OneEmployeesRequestsDeleted(payload.employeeRequests.id),
            );
            this.layoutUtilsService.showActionNotification(
              'The user was successfully transferred to the selected status',
              MessageType.Create,
            );
          }),
        );
    }),
    map((r) => {
      return this.hideLoadingDistpatcher;
    }),
  ));

  constructor(
    private actions$: Actions,
    private layoutUtilsService: LayoutUtilsService,
    private employeesRequestsService: EmployeesRequestsService,
    private store: Store<AppState>,
  ) {}
}
