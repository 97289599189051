// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
// Actions
import { StoreActions, StoreActionTypes } from '../_actions/store.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { StoreModel } from '../_models/store.model';

export interface StoresState extends EntityState<StoreModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  lastCreatedStoreId: number;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<StoreModel> =
  createEntityAdapter<StoreModel>();

export const initialStoresState: StoresState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  lastCreatedStoreId: undefined,
  showInitWaitingMessage: true,
});

export function storesReducer(
  state = initialStoresState,
  action: StoreActions,
): StoresState {
  switch (action.type) {
    case StoreActionTypes.StoresPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedStoreId: undefined,
      };
    case StoreActionTypes.StoresActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    case StoreActionTypes.StoreOnServerCreated:
      return {
        ...state,
      };
    case StoreActionTypes.StoreCreated:
      return adapter.addOne(action.payload.store, {
        ...state,
        lastCreatedStoreId: action.payload.store.id,
      });
    case StoreActionTypes.StoreUpdated:
      return adapter.updateOne(action.payload.partialStore, state);
    case StoreActionTypes.StoresStatusUpdated: {
      const _partialStores: Update<StoreModel>[] = [];
      for (let i = 0; i < action.payload.stores.length; i++) {
        _partialStores.push({
          id: action.payload.stores[i].id,
          changes: {
            // status: action.payload.status
          },
        });
      }
      return adapter.updateMany(_partialStores, state);
    }
    case StoreActionTypes.OneStoreDeleted:
      return adapter.removeOne(action.payload.id, state);
    case StoreActionTypes.ManyStoresDeleted:
      return adapter.removeMany(action.payload.ids, state);
    case StoreActionTypes.StoresPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };
    case StoreActionTypes.StoresPageLoaded:
      return adapter.addMany(action.payload.stores, {
        ...initialStoresState,
        totalCount: action.payload.totalCount,
        listLoading: false,
        lastQuery: action.payload.page,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}

export const getStoreState = createFeatureSelector<StoreModel>('stores');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
