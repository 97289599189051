<section class="p-4">
  <h3>
    {{ 'ORDER.HUNGARY.ACTIVITY_MENU.TITLE' | translate }}
  </h3>
  <span>
    {{ 'ORDER.HUNGARY.ACTIVITY_MENU.TEXT_ONE' | translate }}
    <span class="bold">
      0{{ displayingTimer.minutes }} :
      <ng-template [ngIf]="displayingTimer.seconds.length <= 1">0</ng-template
      >{{ displayingTimer.seconds }}
    </span>
    {{ 'ORDER.HUNGARY.ACTIVITY_MENU.TEXT_TWO' | translate }}
  </span>
  <div class="kt-margin-b-10">
    {{ 'ORDER.HUNGARY.ACTIVITY_MENU.TEXT_THREE' | translate }}
  </div>
  <div class="actions-container">
    <button mat-raised-button color="primary" (click)="confirm()">
      {{ 'ORDER.HUNGARY.ACTIVITY_MENU.STAY' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="logout()">
      {{ 'ORDER.HUNGARY.ACTIVITY_MENU.LOG_OUT' | translate }}
    </button>
  </div>
</section>
