import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'filterExclude',
})
export class FilterExcludePipe implements PipeTransform {
  transform(array: any[], value: any[] = []) {
    const without = R.without();
    return without(value, array);
  }
}
