export class LocalStorageService {
  constructor() {}

  static save(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static get(key: string): any {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  static remove(key: string) {
    localStorage.removeItem(key);
  }
}
