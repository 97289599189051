// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
// Actions
import {
  StoreEmployeeActions,
  StoreEmployeeActionTypes,
} from '../_actions/store-employee.actions';
// Models
import { StoreEmployeeModel } from '../_models/store-employee.model';
import { QueryParamsModel } from '../../_base/crud';

export interface StoreEmployeesState extends EntityState<StoreEmployeeModel> {
  storeId: number;
  loading: boolean;
  totalCount: number;
  lastCreatedStoreEmployeeId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<StoreEmployeeModel> =
  createEntityAdapter<StoreEmployeeModel>();

export const initialStoreEmployeesState: StoreEmployeesState =
  adapter.getInitialState({
    loading: false,
    totalCount: 0,
    storeId: undefined,
    lastCreatedStoreEmployeeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function storeEmployeesReducer(
  state = initialStoreEmployeesState,
  action: StoreEmployeeActions,
): StoreEmployeesState {
  switch (action.type) {
    case StoreEmployeeActionTypes.StoreEmployeesPageToggleLoading:
      return {
        ...state,
        loading: action.payload.isLoading,
        lastCreatedStoreEmployeeId: undefined,
      };
    case StoreEmployeeActionTypes.StoreEmployeeOnServerCreated:
      return { ...state, loading: true };
    case StoreEmployeeActionTypes.StoreEmployeeCreated:
      return adapter.addOne(action.payload.storeEmployee, {
        ...state,
        lastCreatedStoreEmployeeId: action.payload.storeEmployee.id,
      });
    case StoreEmployeeActionTypes.StoreEmployeeUpdated:
      return adapter.updateOne(action.payload.partialStoreEmployee, state);
    case StoreEmployeeActionTypes.OneStoreEmployeeDeleted:
      return adapter.removeOne(action.payload.employeeId, state);
    case StoreEmployeeActionTypes.ManyStoreEmployeesDeleted:
      return adapter.removeMany(action.payload.ids, state);
    case StoreEmployeeActionTypes.StoreEmployeesPageCancelled:
      return {
        ...state,
        totalCount: 0,
        loading: false,
        storeId: undefined,
        lastQuery: new QueryParamsModel({}),
      };
    case StoreEmployeeActionTypes.StoreEmployeesPageRequested:
      return {
        ...state,
        totalCount: 0,
        loading: true,
        storeId: action.payload.storeId,
        lastQuery: action.payload.page,
      };
    case StoreEmployeeActionTypes.StoreEmployeesPageLoaded:
      return adapter.addMany(action.payload.storeEmployees, {
        ...initialStoreEmployeesState,
        totalCount: action.payload.totalCount,
        loading: false,
        storeId: state.storeId,
        lastQuery: state.lastQuery,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}

export const getStoreRemarlState =
  createFeatureSelector<StoreEmployeeModel>('storeEmployees');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
