export class ActionInfo {
  IMEINumber: string = '';
  simcard: string;
  subscriptionNumber: string;
  agreement: string;
  additionalProducts: AdditionaProduct[];
  selfMove: boolean = false;
  number: string = '';
  numberAction: string = 'new';
  activationDate: string = '';
  simCardNumber: string;
  deviceWarrantyTime: string;
  ICCNum: string = '';
  selectedBookedNumber: string = '';
  deliveryMethod: number = null;
  useEsim: boolean = false;
  startDate?: Date = null;
  constructor(init?: Partial<ActionInfo>) {
    Object.assign(this, init);
  }
}

export class AdditionaProduct {
  additionalMove: boolean;
  additionalName: string;
  additionalNumber: string;
  additionalNumberAction: string;
  additionalId: number;
  additionalCount: number;
}

export interface TransferNumber {
  legalIdLeaving: string;
  legalId: string;
  subscriptionId?: string;
  subscriptionIds?: string[];

  customer?: {
    firstName: string;
    lastName: string;
    address: string;
    postalCode: string;
    city: string;
  };
}
