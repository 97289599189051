// NGRX
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import {DevicesReducerState} from '../catalog';

// tslint:disable-next-line:no-empty-interface
export interface AppState {
    DevicesStore?: DevicesReducerState;
    router: any;
}

export const reducers: ActionReducerMap<AppState> = { router: routerReducer };

export const metaReducers: MetaReducer<AppState>[] = [];
