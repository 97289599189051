<div class="kt-form kt-margin-b-30">
  <div class="kt-form__filtration">
    <div class="row align-items-center">
      <div class="col-md-2 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input
            matInput
            placeholder="Search headquarter employee request"
            #searchInput
          />
          <mat-hint align="start">Search in all fields</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-8 kt-margin-bottom-10-mobile"></div>
    </div>
  </div>
</div>

<div class="mat-table__wrapper mat-table__wrapper--sub">
  <mat-table
    class="lmat-elevation-z8"
    [dataSource]="dataSource"
    matSort
    matSortActive="_employeeName"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column"
        >E-mail</mat-header-cell
      >
      <mat-cell *matCellDef="let employee" class="mat-column">{{
        employee?.user?.email
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="accept">
      <mat-header-cell *matHeaderCellDef class="mat-column-actions">
        Accept
      </mat-header-cell>
      <mat-cell *matCellDef="let employee" class="mat-column-actions">
        <button
          mat-raised-button
          color="accent"
          type="button"
          (click)="acceptEmployee(employee)"
          matTooltip="Accept employee"
        >
          Accept
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="decline">
      <mat-header-cell *matHeaderCellDef class="mat-column-actions">
        Decline
      </mat-header-cell>
      <mat-cell *matCellDef="let employee" class="mat-column-actions">
        <button
          mat-raised-button
          color="warn"
          type="button"
          (click)="declineEmployee(employee)"
          matTooltip="Decline employee"
        >
          Decline
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="mat-table__message" *ngIf="!dataSource.hasItems">
    No records found
  </div>
  <div
    class="mat-table__message"
    *ngIf="dataSource.isPreloadTextViewed$ | async"
  >
    Please wait....
  </div>
</div>
<div class="mat-table__bottom">
  <mat-spinner
    [diameter]="20"
    *ngIf="dataSource.loading$ | async"
  ></mat-spinner>
  <mat-paginator
    [pageSize]="10"
    [pageSizeOptions]="[3, 5, 10]"
    [length]="dataSource.paginatorTotal$ | async"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</div>
