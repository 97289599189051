import {Inject, Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HeadquarterProviderModel } from '../_models/headquarterProviderModel';
import { ContractModel } from '../_models/contract.model';
import { objectToStr } from '../../_base/crud/utils/http.utils';
import { Tele2ProviderService } from './providers/tele2-provider.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ICustomerLoyalty } from '../../../views/pages/catalog/catalog-details-phone/phone-providers/interfaces/customer-loyalty.interface';
import {IAvailabilityCount} from '../../../views/pages/catalog/catalog-details-phone/phone-providers/interfaces/availability-count.interface';
import { TeliaProviderService } from './providers/telia-provider.service';
import { CategoryType, ProviderDependence } from '../_models/category-type.model';
import {DOCUMENT} from "@angular/common";

const API_PROVIDER_URL = `${environment.api_url}/providers`;
const API_URL = environment.api_url;

export enum HeadquarterConfiguration {
  Hungary = 'MEDIAMARKTHU',
  Mediamarkt = 'MEDIAMARKTSE',
  Webhallen = 'WEBHALLEN',
}

export interface PersonalData {
    firstname: string;
    lastname: string;
    address: string;
    zip: string;
    city: string;
    contactPhoneNumber: string;
    email: string;
}

@Injectable({ providedIn: 'root' })
export class ProviderService {
  isMediamarkt: boolean = false;
  isWebhallen: boolean = false;
  currency = '';

  constructor(
    private http: HttpClient,
    public tele2: Tele2ProviderService,
    public telia: TeliaProviderService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.prepareConfig();
  }

  getHeaderProviders(): Observable<HeadquarterProviderModel[]> {
    return this.http.get<HeadquarterProviderModel[]>(API_PROVIDER_URL);
  }

  getAll(): Observable<HeadquarterProviderModel[]> {
    return this.http.get<HeadquarterProviderModel[]>(`${API_PROVIDER_URL}`);
  }

  getOnlyProviders(headquarter: number, typeId:number = CategoryType.MOBILE, dependentOn: string = ProviderDependence.CONTRACTS): Observable<Array<HeadquarterProviderModel>> {
    return this.http.get<Array<HeadquarterProviderModel>>(`${API_URL}/headquarter/${headquarter}/providers?typeId=${typeId}&dependentOn=${dependentOn}`);
  }

  dictionary(storeIds: number[], headquarterId: number): Observable<any> {
    return this.http.get(
      `${API_URL}/dictionary/providers?storeIds=${storeIds.toString()}&headquarterId=${headquarterId}`,
    );
  }

  getExtraSubventions(providerId: number) {
    return this.http.get(`${API_URL}/providers/${providerId}/products/3`);
  }

  getExtraContracts(providerId: number,previouslyAddedContracts: Array<number>, ssn: string, offerId): Observable<Array<ContractModel>> {
      const searchParams = {
          providerId,
          previouslyAddedContracts: previouslyAddedContracts.toString(),
          ssn,
          offerId
      };
    return this.http.get<Array<ContractModel>>(`${API_URL}/providers/extra-contracts?${objectToStr(searchParams)}`);
  }

  getStartingFee(providerId) {
    return this.http.get(`${API_URL}/products/starting-fee?providerId=${providerId}`);
  }

  getProducts(providerId: string, providerGroup: string, typeId: number) {
    return this.http.get(
      `${API_PROVIDER_URL}/${providerId}/products/${
        providerGroup === 'new' ? 1 : 2
      }?typeId=${typeId}`,
    );
  }

  getDeviceRelatedContracts(
    deviceId: number,
    searchParams,
    contractOnlyParams: any,
    visibility: string,
    pagination?: { page: number; limit: number }
  ): Observable<{ items: Array<any>, count: number }> {
      let dataIncluded = Math.round(searchParams.dataIncludedMin);
      let dataIncludedMax = searchParams.dataIncludedMax;
      let url = '';

      if (deviceId !== undefined) {
          url = `${API_URL}/device/${deviceId}/contracts?${this.buildQuery(searchParams)}&dataIncludedMin=${dataIncluded}`;
      } else if (contractOnlyParams?.providerId != null) {
          url = `${API_URL}/headquarter/${contractOnlyParams.headquarterId}/contracts?providerIds=${contractOnlyParams.providerId}&${this.buildQuery(searchParams)}&dataIncludedMin=${dataIncluded}`;
      } else {
          return of(null);
      }

      if (dataIncludedMax !== 100) {
          url += `&dataIncludedMax=${dataIncludedMax}`;
      }
      url += `&visibilityMode=${visibility}`;
      if (pagination) {
            url += `&page=${pagination.page}&limit=${pagination.limit}&paginated=true`;
      }
      if (searchParams.providerId) {
          url += `&providerId=${searchParams.providerId}`;
      }
      if (searchParams.contractSearchStr) {
          url += `&contractSearchStr=${searchParams.contractSearchStr}`;
      }
      if (searchParams.productGroup) {
          url += `&productGroup=${searchParams.productGroup}`;
      }
      if (searchParams.sort) {
          url += `&sort={"sortField":"${searchParams.sort.sortField}","direction":"${searchParams.sort.direction}"}`;
      }

      return this.http.get<{ items: Array<any>, count: number }>(url);
  }

  getUserDataByPersonnumber(personNumber: number): Observable<PersonalData> {
    return this.http.get<PersonalData>(`${API_URL}/user/personalnumber/data/${personNumber}`);
  }

  getUserDataByPersonnumberTele2(personNumber: number): Observable<PersonalData> {
    return this.http.get<PersonalData>(
      `${API_URL}${environment.tele2BankIdUrl}/${personNumber}`,
    );
  }

  getHalebopOrderDetails(articleNum: string, ssn: number): Observable<any> {
    return this.http.get<any[]>(
      `${API_URL}/providers/halebop/offering/${articleNum}/details/${ssn}`,
    );
  }


  private prepareConfig() {
    if (LocalStorageService.get('headquarter')) {
      const headquarter = LocalStorageService.get('headquarter');

      if (!headquarter || !headquarter.configuration) {
        return;
      }

      if (
        headquarter.configuration.code === HeadquarterConfiguration.Mediamarkt
      ) {
        this.isMediamarkt = true;
      } else if (
        headquarter.configuration.code === HeadquarterConfiguration.Webhallen
      ) {
        this.isWebhallen = true;
      }

      this.currency = headquarter.currency;
    }
  }

  private buildQuery(data): string {
    if (data.contractLength !== '') {
      return `contractLength=${data.contractLength}`;
    } else {
      return '';
    }
  }

  bankIdAuth(providerId: number, sameDevice = false): Observable<any> {
    const body = {
        providerId,
        sameDevice
    };
    return this.http.post<any>(`${API_URL}/user/verify`, body);
  }

  cancelBankId(transactionId: string, providerId: number): Observable<any> {
      return this.http.delete<any>(`${API_URL}/user/verify/${transactionId}?providerId=${providerId}`);
  }

  get bankIdMandatory(): boolean {
    const headquarter = LocalStorageService.get('headquarter');
    return headquarter.bankIDVerification;
  }

  checkBankIdVerification(orderRef: string,providerId: number): Observable<any> {
    return this.http.get<any>(`${API_URL}/user/verify/${orderRef}/?providerId=${providerId}`);
  }

  authorizeViaBankIdApp(autoStartToken: string): void {
      const redirect = `${encodeURI(`${this.document.location.hostname}/api/user/bank-id/${autoStartToken}`)}`
      window.open(`bankid:///?autostarttoken=${autoStartToken}&redirect=`, "_blank");
  }

  getIdentificationMethods(): Observable<Array<any>> {
    return this.http.get<any>(`${API_URL}/providers/identificationMethods`);
  }

  getDeliveryMethods(): Observable<Array<any>> {
    return this.http.get<any>(`${API_URL}/providers/deliveryMethods`);
  }

  salesRefCheck(providerId: number): Observable<{ allowed: boolean }> {
    return this.http.get<{ allowed: boolean }>(
      `${API_URL}/providers/salesRefCheck/${providerId}`,
    );
  }

  getAvailabilityCount(providerId: number, ssn: string): Observable<IAvailabilityCount> {
    return this.http.get<IAvailabilityCount>(
      `${API_URL}/providers/${providerId}/scoreCheck/${ssn}`,
    );
  }

  addSalesRef(providerId, salesRef: string) {
    const body = {
      salesRef,
      provider: providerId,
    };
    return this.http.patch<any>(`${API_URL}/user/salesref`, body);
  }

  compareSalesRef(providerId: number, salesRefToCompare: string) {
    const body = {
      salesRef: salesRefToCompare,
        provider: providerId,
    };
    return this.http.post<{ allowed: boolean }>(`${API_URL}/user/salesref/compare`, body);
  }

  sendTeliaDoc(email: string): Observable<any> {
    const body = {
      email,
    };
    return this.http.post<any>(`${API_URL}/order/telia-document`, body);
  }

  sendScriveLinkByEmail( link: string, email: string): Observable<any> {
    const body = {
        email,
        link,
    };
    return this.http.post<any>(`${API_URL}/scrive/email`, body);
  }
}
