<kt-portlet>
  <kt-portlet-header
    [title]="'headquarters list'"
    [class]="'kt-portlet__head--lg'"
  >
    <ng-container ktPortletTools>
      <button
        [routerLink]="['../add']"
        [hidden]="!canCreateHeadquarter"
        mat-raised-button
        color="primary"
        matTooltip="Create new headquarter"
      >
        New headquarter
      </button>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div class="kt-form kt-margin-b-30">
      <div class="kt-form__filtration">
        <div class="row align-items-center">
          <div class="col-md-4 kt-margin-bottom-10-mobile">
            <mat-form-field>
              <input
                matInput
                placeholder="Search headquarter"
                #searchInput
                class="mat-form-field mat-form-field-fluid"
              />
              <mat-hint align="start">
                <strong>Search in all fields</strong>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center collapse kt-form__group-actions kt-margin-top-20 kt-margin-bottom-20"
        [ngClass]="{ show: selection.selected.length > 0 }"
      >
        <div class="col-xl-12">
          <div class="kt-form__group kt-form__group--inline">
            <div class="kt-form__label kt-form__label-no-wrap">
              <label class="kt--font-bold kt-font-danger-"
                >Selected records count: {{ selection.selected.length }}</label
              >
            </div>
            <div class="kt-form__control kt-form__group--inline">
              <button
                mat-raised-button
                color="accent"
                (click)="deleteHeadquarters()"
                matTooltip="Delete selected headquarters"
                class="mat-button-mt-4"
              >
                <mat-icon>delete</mat-icon>
                Delete All</button
              >&nbsp;
              <!-- Call 'delete-entity-dialog' from _shared folder -->
              <button
                mat-raised-button
                (click)="fetchHeadquarters()"
                matTooltip="Fetch selected headquarters"
                class="mat-button-mt-4"
              >
                <mat-icon>clear_all</mat-icon>
                Fetch Selected Records</button
              >&nbsp;
              <!-- Call 'fetch-entity-dialog' from _shared folder -->
              <button
                mat-raised-button
                (click)="updateStatusForHeadquarters()"
                matTooltip="Update status for selected headquarters"
                class="mat-button-mt-4"
              >
                <mat-icon>update</mat-icon>
                Update status</button
              ><!-- Call 'update-stated-dialog' from _shared folder -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MATERIAL TABLE | Binded to datasources -->
    <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
    <div class="mat-table__wrapper">
      <!-- Checkbox Column -->
      <!-- Table with selection -->
      <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
      <mat-table
        class="lmat-elevation-z8"
        #table
        [dataSource]="dataSource"
        matSort
        #sort1="matSort"
        matSortActive="id"
        matSortDirection="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="mat-column-vinCode"
            >name</mat-header-cell
          >
          <mat-cell *matCellDef="let headquarter" class="mat-column-vinCode">{{
            headquarter.name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >country</mat-header-cell
          >
          <mat-cell *matCellDef="let headquarter">{{
            headquarter.country?.name
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let headquarter">
            <button
              type="button"
              (click)="editHeadquarter(headquarter.id)"
              mat-icon-button
              color="primary"
              matTooltip="Edit headquarter"
            >
              <mat-icon>create</mat-icon></button
            >&nbsp;
            <mat-menu #menu="matMenu">
              <button mat-menu-item type="button">
                <mat-icon>subject</mat-icon>
                <span>Employees</span>
              </button>
              <button mat-menu-item type="button">
                <mat-icon>assignment</mat-icon>
                <span>Stores</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="mat-table__message" *ngIf="!dataSource.hasItems">
        No records found
      </div>
      <div
        class="mat-table__message"
        *ngIf="dataSource.isPreloadTextViewed$ | async"
      >
        Please wait....
      </div>
    </div>

    <!-- start: BOTTOM -->
    <div class="mat-table__bottom">
      <mat-spinner
        [diameter]="20"
        *ngIf="dataSource.loading$ | async"
      ></mat-spinner>
      <mat-paginator
        [pageSize]="10"
        [pageSizeOptions]="[3, 5, 10]"
        [length]="dataSource.paginatorTotal$ | async"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
    <!-- end: BOTTOM -->
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>
