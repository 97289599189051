import { Action } from '@ngrx/store';
import { DeviceModel } from '../_models/device.model';
import { ContractModel } from '../_models/contract.model';
import { OrderModel } from '../_models/order.model';
import { DevicesReducerState } from '../_reducers/devices.reducers';
import { IStoreAdditionalDevice } from '../_models/storeAdditionalDevice.model';
import { HeadquarterProviderModel } from '../_models/headquarterProviderModel';
import { SelectedCampaign } from '../../../views/pages/catalog/catalog-checkout/tab-overview/models/campaign';

export enum ActionTypes {
  SelectDevices = '[Select Devices Component] Devices get one from server',
  SelectContract = '[Select Contract Component]',
  SelectDevicesWarranty = '[Select Devices Component] Devices warranty get one from server',
  SetOptionsContract = '[set Providers Component]',
  SetSampleOrders = '[set Order Component]',
  SetCustomer = '[set Customer Component] Customer Information',
  SetCustomerSubCount = '[set Customer Sub Component] Customer Information',
  SetActionInfo = '[set Customer Component] Customer Action Information',
  SetCheckout = '[set Checkout Component] Customer Action Checkout',
  Reset = '[Reset] Reset Information',
  ResetPartly = '[Reset partly] Reset Information partly',
  AdditionalOrder = '[Additional] Reset Information excluding customer info',
  SetContractAvalMonths = '[set Months avail]',
  SetAdditionalProducts = 'Set Additional Products',
  SetAdditionalServices = 'Set Additional Products',
  SetExtraUsers = 'Set Extra Users',
  SetVAServices = 'Set VAS Services',
  SetMoreDevices = 'Setting more devices',
  SetProviderOnly = 'Setting contract without device',
  SetDiscount = 'Setting discount for the contract',
  SwitchOrder = 'Switching order',
  RemoveStartingFee = 'Removing Fee',
  SetCurrentContractData = 'Setting current contract data',
  AdditionalDevices = '[Additional Devices] Additional Devices',
  NoFinancing = '[No Financing] No Financing',
  SelectScreenProtector = '[ScreenProtector] SelectScreenProtector',
  SetProvider = '[set Provider Component]',
  SetProviders = '[set ProvidersArray Component]',
  SetFamilyTypes = '[set FamilyTypes Component]',
  IncludeMsisdns = '[set includingMsisdn Component]',
  SetCampaigns = '[set campaigns Component]',
  SetTotal = '[set Total]',
  SetFamily = '[set Family]',
}

/**
 * Выбранный девайс
 */
export class ActionSelectDevices implements Action {
  readonly type = ActionTypes.SelectDevices;
  constructor(public payload: DeviceModel) {}
}

export class ActionSelectContract implements Action {
  readonly type = ActionTypes.SelectContract;
  constructor(public payload: ContractModel) {}
}

export class ActionAdditionalDevices implements Action {
  readonly type = ActionTypes.AdditionalDevices;
  constructor(public payload: IStoreAdditionalDevice[]) {}
}

export class ActionSelectDevicesWarranty implements Action {
  readonly type = ActionTypes.SelectDevicesWarranty;
  constructor(public payload: number[]) {}
}

export class ActionSwitchOrder implements Action {
  readonly type = ActionTypes.SwitchOrder;

  constructor(public payload: DevicesReducerState) {}
}

export class ActionSetProviderOnly implements Action {
  readonly type = ActionTypes.SetProviderOnly;

  constructor(public payload: HeadquarterProviderModel) {}
}

/**
 * Сохраним Параметры поиска контракта
 */
export class ActionSetOptionsContract implements Action {
  readonly type = ActionTypes.SetOptionsContract;
  constructor(public payload: ContractModel) {}
}

export class ActionSetDiscount implements Action {
  readonly type = ActionTypes.SetDiscount;
  constructor(public payload: number) {}
}

export class ActionRemoveStartingFee implements Action {
  readonly type = ActionTypes.RemoveStartingFee;

  constructor(public payload: boolean) {}
}

/**
 * Order template update
 */
export class ActionOrderUpdateOrder implements Action {
  readonly type = ActionTypes.SetSampleOrders;
  constructor(public payload: OrderModel) {}
}

/**
 * Customer info
 */
export class ActionSetCustomer implements Action {
  readonly type = ActionTypes.SetCustomer;
  constructor(public payload: any) {}
}

export class ActionSetCustomerSubCount implements Action {
  readonly type = ActionTypes.SetCustomerSubCount;
  constructor(public payload: number) {}
}

/**
 * Information
 */
export class ActionSetActionInfo implements Action {
  readonly type = ActionTypes.SetActionInfo;
  constructor(public payload: any) {}
}

export class ActionResetInfo implements Action {
  readonly type = ActionTypes.Reset;
  constructor() {}
}

export class ActionResetInfoPartly implements Action {
  readonly type = ActionTypes.ResetPartly;
  constructor() {}
}

export class ActionAdditionalOrder implements Action {
  readonly type = ActionTypes.AdditionalOrder;
  constructor() {}
}

/**
 * Generated order from server
 */
export class ActionSetCheckout implements Action {
  readonly type = ActionTypes.SetCheckout;
  constructor(public payload: any) {}
}

export class ActionSetMonths implements Action {
  readonly type = ActionTypes.SetContractAvalMonths;

  constructor(public payload: number[]) {}
}

export class ActionSetAdditionalProducts implements Action {
  readonly type = ActionTypes.SetAdditionalProducts;

  constructor(public payload: any) {}
}

export class ActionSetAdditionalServices implements Action {
  readonly type = ActionTypes.SetAdditionalServices;

  constructor(public payload: any) {}
}

export class ActionSetMoreDevices implements Action {
  readonly type = ActionTypes.SetMoreDevices;

  constructor(public payload: any) {}
}

export class ActionSetCurrentContractData implements Action {
  readonly type = ActionTypes.SetCurrentContractData;

  constructor(public payload: any) {}
}

export class ActionNoFinancing implements Action {
  readonly type = ActionTypes.NoFinancing;

  constructor(public payload: boolean) {}
}

export class ActionSelectScreenProtector implements Action {
  readonly type = ActionTypes.SelectScreenProtector;

  constructor(public payload: boolean) {}
}

export class ActionSetProvider implements Action {
  readonly type = ActionTypes.SetProvider;

  constructor(public payload: HeadquarterProviderModel) {}
}

export class ActionSetProviders implements Action {
  readonly type = ActionTypes.SetProviders;

  constructor(public payload: HeadquarterProviderModel[]) {}
}

export class ActionSetExtraUsers implements Action {
  readonly type = ActionTypes.SetExtraUsers;

  constructor(public payload: any) {}
}

export class ActionSetVAS implements Action {
  readonly type = ActionTypes.SetVAServices;

  constructor(public payload: any) {}
}

export class ActionSetFamilyTypes implements Action {
  readonly type = ActionTypes.SetFamilyTypes;

  constructor(public payload: any) {}
}

export class ActionIncludeMsisdnTypes implements Action {
  readonly type = ActionTypes.IncludeMsisdns;

  constructor(public payload: any) {}
}

export class ActionSetCampaigns implements Action {
  readonly type = ActionTypes.SetCampaigns;

  constructor(public payload: SelectedCampaign[]) {}
}

export class ActionSetTotal implements Action {
  readonly type = ActionTypes.SetTotal;

  constructor(public payload: any) {}
}

export class ActionSetFamily implements Action {
  readonly type = ActionTypes.SetFamily;

  constructor(public payload: string) {}
}

export type DevicesActions =
  | ActionSelectDevices
  | ActionSelectContract
  | ActionSelectDevicesWarranty
  | ActionSetOptionsContract
  | ActionSetCheckout
  | ActionSetCustomer
  | ActionSetCustomerSubCount
  | ActionSetActionInfo
  | ActionOrderUpdateOrder
  | ActionResetInfo
  | ActionResetInfoPartly
  | ActionAdditionalOrder
  | ActionSetMonths
  | ActionSetAdditionalProducts
  | ActionSetMoreDevices
  | ActionSetProviderOnly
  | ActionSetDiscount
  | ActionSwitchOrder
  | ActionRemoveStartingFee
  | ActionSetCurrentContractData
  | ActionAdditionalDevices
  | ActionSelectScreenProtector
  | ActionNoFinancing
  | ActionSetProvider
  | ActionSetProviders
  | ActionSetExtraUsers
  | ActionSetFamilyTypes
  | ActionSetVAS
  | ActionIncludeMsisdnTypes
  | ActionSetCampaigns
  | ActionSetTotal
  | ActionSetFamily
  | ActionSetAdditionalServices;
