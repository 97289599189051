import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AuthService } from '../../../../../../../../core/auth';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormValidationRegExService } from '../../../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../../../core/_base/crud';

@Component({
  selector: 'kt-headquarter-employee-edit-dialog',
  templateUrl: './employee-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadquarterEmployeeEditDialogComponent
  implements OnInit, OnDestroy
{
  employeeEditForm: UntypedFormGroup;
  private destroyStream$ = new Subject<void>();
  canEdit: boolean = false;
  viewLoading: boolean = true;
  loadingAfterSubmit: boolean = false;
  filteredEmployee: { id: number; email: string }[] = [];
  searching = false;
  searchFailed = false;
  formatter = (obj) => (obj ? obj.email : undefined);

  constructor(
    public dialogRef: MatDialogRef<HeadquarterEmployeeEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private permissionService: NgxPermissionsService,
    private validate: FormValidationRegExService,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    this.initSpecificationForm();

    this.permissionService.permissions$.subscribe((permissions) => {
      this.canEdit = !!permissions['ROLE_SUPER_ADMIN'];
    });
  }

  initSpecificationForm() {
    const employeeId: string = !this.data.employeeId
      ? ''
      : this.data.employeeId;
    const role: string = this.data.role;
    this.employeeEditForm = this.fb.group({
      employeeId: [employeeId, [Validators.required, this.validate.isObject]],
      role: [
        role,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });

    this.employeeEditForm
      .get('employeeId')
      .valueChanges.pipe(
        takeUntil(this.destroyStream$),
        debounceTime(300),
        tap(() => (this.searching = true)),
        switchMap((value) => {
          return this.auth
            .findUsersAutocomplete(value, this.data.headquarterId)
            .pipe(
              finalize(() => (this.searchFailed = false)),
              catchError(() => {
                this.searchFailed = true;
                return of([]);
              }),
            );
        }),
        tap(() => (this.searching = false)),
      )
      .subscribe((users: { id: number; email: string }[]) => {
        this.filteredEmployee = users;
      });

    this.viewLoading = false;
  }

  onNoClick(): void {
    this.dialogRef.close({ isUpdated: false });
  }

  save() {
    const controls = this.employeeEditForm.controls;

    this.loadingAfterSubmit = true;
    this.viewLoading = true;
    const employeeId = controls['employeeId'].value.id;
    const role = controls['role'].value;
    this.viewLoading = false;
    this.closeDialog(employeeId, role);
  }

  closeDialog(employeeId, role) {
    this.dialogRef.close({
      isUpdated: true,
      employeeId: employeeId,
      role: role,
      _EmployeeName: employeeId,
    });
  }

  ngOnDestroy() {
    this.destroyStream$.next();
    this.destroyStream$.complete();
  }
}
