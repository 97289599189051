<ng-container *ngIf="user$ | async as _user">
    <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-button class="m10">
            {{ 'USER.HELLO' | translate }}, {{_user.email }}
        </button>
        <button  *ngIf="_user.headquarter.storeChangingAllowed" mat-menu-item (click)="showStoreSelection()">Select Store <mat-icon>store</mat-icon></button>
        <mat-slide-toggle class="m10" *ngIf="!production && !stage" (change)="changeMode($event)" [(ngModel)]="expertMode">
            Expert mode
        </mat-slide-toggle>
        <button mat-menu-item (click)="logout()">{{ 'SIGN_OUT' | translate }}<mat-icon>logout</mat-icon></button>
    </mat-menu>
</ng-container>

