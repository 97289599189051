// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
// CRUD
import {
  HttpUtilsService,
  QueryParamsModel,
  QueryResultsModel,
} from '../../_base/crud';
// Models and Consts
import { HeadquarterEmployeeModel } from '../_models/headquarter-employee.model';
import { SPECIFICATIONS_DICTIONARY } from '../_consts/specification.dictionary';
import { environment } from '../../../../environments/environment';

const API_HEADQUARTER_URL = `${environment.api_url}/headquarter`;
// Real REST API
@Injectable()
export class HeadquarterEmployeesService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  createHeadquarterEmployee(
    userId,
    headquarterId,
    role,
  ): Observable<HeadquarterEmployeeModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<HeadquarterEmployeeModel>(
      `${API_HEADQUARTER_URL}/employee`,
      { headquarterId, userId, role },
      { headers: httpHeaders },
    );
  }

  findHeadquarterEmployees(
    queryParams: QueryParamsModel,
    headquarterId: number,
  ): Observable<QueryResultsModel> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

    const url = `${API_HEADQUARTER_URL}/${headquarterId}/employees`;
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params: httpParams,
    });
  }

  updateHeadquarterSpec(
    headquarterSpec: HeadquarterEmployeeModel,
  ): Observable<any> {
    return this.http.put(API_HEADQUARTER_URL, headquarterSpec, {
      headers: this.httpUtils.getHTTPHeaders(),
    });
  }

  deleteHeadquarterEmployee(
    headquarterId: number,
    employeeId: number,
  ): Observable<any> {
    const url = `${API_HEADQUARTER_URL}/${headquarterId}/employee/${employeeId}`;
    return this.http.delete<HeadquarterEmployeeModel>(url);
  }

  deleteHeadquarterEmployees(ids: number[] = []): Observable<any> {
    const url = API_HEADQUARTER_URL + '/deleteHeadquarterEmployees';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { headquarterEmployeeIdsForDelete: ids };
    return this.http.put<any>(url, body, { headers: httpHeaders });
  }
}
