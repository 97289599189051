// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { StoreModel } from '../_models/store.model';
import { Update } from '@ngrx/entity';

export enum StoreActionTypes {
  StoreOnServerCreated = '[Edit Store Component] Store On Server Created',
  StoreCreated = '[Edit Store Component] Store Created',
  StoreUpdated = '[Edit Store Component] Store Updated',
  StoresStatusUpdated = '[Stores List Page] Stores Status Updated',
  OneStoreDeleted = '[Stores List Page] One Store Deleted',
  ManyStoresDeleted = '[Stores List Page] Many Selected Stores Deleted',
  StoresPageRequested = '[Stores List Page] Stores Page Requested',
  StoresPageLoaded = '[Stores API] Stores Page Loaded',
  StoresPageCancelled = '[Stores API] Stores Page Cancelled',
  StoresPageToggleLoading = '[Stores] Stores Page Toggle Loading',
  StoresActionToggleLoading = '[Stores] Stores Action Toggle Loading',
}

export class StoreOnServerCreated implements Action {
  readonly type = StoreActionTypes.StoreOnServerCreated;
  constructor(public payload: { store: StoreModel }) {}
}

export class StoreCreated implements Action {
  readonly type = StoreActionTypes.StoreCreated;
  constructor(public payload: { store: StoreModel }) {}
}

export class StoreUpdated implements Action {
  readonly type = StoreActionTypes.StoreUpdated;
  constructor(
    public payload: {
      partialStore: Update<StoreModel>; // For State update
      store: StoreModel; // For Server update (through service)
    },
  ) {}
}

export class StoresStatusUpdated implements Action {
  readonly type = StoreActionTypes.StoresStatusUpdated;
  constructor(
    public payload: {
      stores: StoreModel[];
      status: number;
    },
  ) {}
}

export class OneStoreDeleted implements Action {
  readonly type = StoreActionTypes.OneStoreDeleted;
  constructor(public payload: { id: number }) {}
}

export class ManyStoresDeleted implements Action {
  readonly type = StoreActionTypes.ManyStoresDeleted;
  constructor(public payload: { ids: number[] }) {}
}

export class StoresPageRequested implements Action {
  readonly type = StoreActionTypes.StoresPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class StoresPageLoaded implements Action {
  readonly type = StoreActionTypes.StoresPageLoaded;
  constructor(
    public payload: {
      stores: StoreModel[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class StoresPageCancelled implements Action {
  readonly type = StoreActionTypes.StoresPageCancelled;
}

export class StoresPageToggleLoading implements Action {
  readonly type = StoreActionTypes.StoresPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class StoresActionToggleLoading implements Action {
  readonly type = StoreActionTypes.StoresActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type StoreActions =
  | StoreOnServerCreated
  | StoreCreated
  | StoreUpdated
  | StoresStatusUpdated
  | OneStoreDeleted
  | ManyStoresDeleted
  | StoresPageRequested
  | StoresPageLoaded
  | StoresPageCancelled
  | StoresPageToggleLoading
  | StoresActionToggleLoading;
