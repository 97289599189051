// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
// Actions
import {
  HeadquarterEmployeeActions,
  HeadquarterEmployeeActionTypes,
} from '../_actions/headquarter-employee.actions';
// Models
import { HeadquarterEmployeeModel } from '../_models/headquarter-employee.model';
import { QueryParamsModel } from '../../_base/crud';

export interface HeadquarterEmployeesState
  extends EntityState<HeadquarterEmployeeModel> {
  headquarterId: number;
  loading: boolean;
  totalCount: number;
  lastCreatedHeadquarterEmployeeId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<HeadquarterEmployeeModel> =
  createEntityAdapter<HeadquarterEmployeeModel>();

export const initialHeadquarterEmployeesState: HeadquarterEmployeesState =
  adapter.getInitialState({
    loading: false,
    totalCount: 0,
    headquarterId: undefined,
    lastCreatedHeadquarterEmployeeId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function headquarterEmployeesReducer(
  state = initialHeadquarterEmployeesState,
  action: HeadquarterEmployeeActions,
): HeadquarterEmployeesState {
  switch (action.type) {
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageToggleLoading:
      return {
        ...state,
        loading: action.payload.isLoading,
        lastCreatedHeadquarterEmployeeId: undefined,
      };
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeeOnServerCreated:
      return { ...state, loading: true };
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeeCreated:
      return adapter.addOne(action.payload.headquarterEmployee, {
        ...state,
        lastCreatedHeadquarterEmployeeId: action.payload.headquarterEmployee.id,
      });
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeeUpdated:
      return adapter.updateOne(
        action.payload.partialHeadquarterEmployee,
        state,
      );
    case HeadquarterEmployeeActionTypes.OneHeadquarterEmployeeDeleted:
      return adapter.removeOne(action.payload.employeeId, state);
    case HeadquarterEmployeeActionTypes.ManyHeadquarterEmployeesDeleted:
      return adapter.removeMany(action.payload.ids, state);
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageCancelled:
      return {
        ...state,
        totalCount: 0,
        loading: false,
        headquarterId: undefined,
        lastQuery: new QueryParamsModel({}),
      };
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageRequested:
      return {
        ...state,
        totalCount: 0,
        loading: true,
        headquarterId: action.payload.headquarterId,
        lastQuery: action.payload.page,
      };
    case HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageLoaded:
      return adapter.addMany(action.payload.headquarterEmployees, {
        ...initialHeadquarterEmployeesState,
        totalCount: action.payload.totalCount,
        loading: false,
        headquarterId: state.headquarterId,
        lastQuery: state.lastQuery,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}

export const getHeadquarterRemarlState =
  createFeatureSelector<HeadquarterEmployeeModel>('headquarterEmployees');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
