<div class="toast {{toast.type}}"
     [style.top.px]="i*100 + 100">
    <div class="container-1" [ngClass]="'container_'+toast.type">
        <ng-container [ngSwitch]="toast.type">
        <mat-icon *ngSwitchCase="EventTypes.Success">check_circle</mat-icon>
        <mat-icon *ngSwitchCase="EventTypes.Info">info</mat-icon>
        <mat-icon *ngSwitchCase="EventTypes.Warning">warning</mat-icon>
        <mat-icon *ngSwitchCase="EventTypes.Error">error</mat-icon>
        </ng-container>
    </div>

    <div class="container-2">
        <p>{{toast.title}}</p>
        <p>{{toast.message}}</p>
    </div>
    <button (click)="remove.emit(i)">&times;</button>
</div>

