import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-discout-confirm',
  templateUrl: './discout-confirm.component.html',
  styleUrls: ['./discout-confirm.component.scss']
})
export class DiscoutConfirmComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<DiscoutConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
      ) {}

  ngOnInit(): void {
  }

  confirm(){
    this.dialogRef.close(true);
  }

}
