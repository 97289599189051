// RxJS
import { delay, distinctUntilChanged } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
// Selectors
import {
  selectStoresInitWaitingMessage,
  selectStoresInStore,
  selectStoresPageLoading,
} from '../_selectors/store.selectors';

export class StoresDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectStoresPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectStoresInitWaitingMessage),
    );

    this.store
      .pipe(select(selectStoresInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
