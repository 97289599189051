// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
import {
  ContentAnimateDirective,
  HeaderDirective,
  MenuDirective,
  StickyDirective,
  FormValidationRegExService,
  RegexService,
  FilterPipe,
  SortPipe,
  InsuranceScreenProtectorPipe,
} from './_base/layout';
// Pipes
// Services
import {
  FirstLetterPipe,
  GetObjectPipe,
  JoinPipe,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  TimeElapsedPipe,
  ToggleDirective,
  ThousandSeparatorPipe,
} from './_base/layout';
import { FilterExcludePipe } from './_base/layout/pipes/filter-exclude.pipe';
import { FilterRFilterPipe } from './_base/layout/pipes/filter-r-filter.pipe';
import { TruncatePipe } from './_base/layout/pipes/truncate.pipe';
import {ExpertModeDirective} from './_base/layout/directives/expert-mode.directive';
import { SuperAdminDirective} from './_base/layout/directives/super-admin.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    ContentAnimateDirective,
    StickyDirective,
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    FilterPipe,
    InsuranceScreenProtectorPipe,
    SortPipe,
    ThousandSeparatorPipe,
    FilterExcludePipe,
    FilterRFilterPipe,
    TruncatePipe,
    ExpertModeDirective,
    SuperAdminDirective
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    ContentAnimateDirective,
    StickyDirective,
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    FilterPipe,
    InsuranceScreenProtectorPipe,
    SortPipe,
    ThousandSeparatorPipe,
    FilterExcludePipe,
    FilterRFilterPipe,
    TruncatePipe,
    ExpertModeDirective,
    SuperAdminDirective
  ],
  providers: [FormValidationRegExService, RegexService],
})
export class CoreModule {}
