<div class="login">
  <h3 class="login__title">{{ 'AUTH.FORGOT.TITLE' | translate }}</h3>

  <kt-auth-notice></kt-auth-notice>

  <form class="login__form" [formGroup]="forgotPasswordForm" autocomplete="off">
    <mat-form-field class="login__field">
      <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
      <input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off" />
      <mat-error *ngIf="isControlHasError('email', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'email')">
        <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
      </mat-error>
    </mat-form-field>

    <div class="login__actions">
      <button type="button" routerLink="/auth/login" [disabled]="loading" class="btn btn-secondary btn-elevate">
        {{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
      </button>
      <button type="submit" (click)="submit()" class="btn btn-primary btn-elevate">
        <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</span>
      </button>
    </div>
  </form>
</div>
