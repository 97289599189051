import {Directive, ElementRef} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ApplicationService} from '../../../services/application.service';

@Directive({
  selector: '[ktExpertMode]'
})
export class ExpertModeDirective {

    constructor(private el: ElementRef,private appService: ApplicationService) {
        if (!environment.production && !environment.stage) {
            this.expertModeSubscribe();
        }
    }

    expertModeSubscribe(): void {
        this.appService.productionMode$.subscribe(expert => {
            if (expert) {
              this.el.nativeElement.style.display = 'flex';
              return;
            }
            this.el.nativeElement.style.display = 'none';
        });
    }

}
