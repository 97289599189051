import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { EmployeesRequestsModel } from '../_models/employees-requests.model';

export enum EmployeesRequestsActionTypes {
  EmployeesRequestsUpdated = '[Edit Employee Requests Dialog] Headquarter Employee Updated',
  EmployeesRequestsPageRequested = '[ Employees Requests List Page] Headquarter Employees Page Requested',
  EmployeesRequestsPageLoaded = '[ Employees Requests API] Headquarter Employees Page Loaded',
  OneEmployeesRequestsDeleted = '[ Employee Delete]  One Requests Employee Deleted',
  EmployeesRequestsPageToggleLoading = '[ Employees Requests] Headquarter Employees Page Toggle Loading',
}

export class EmployeesRequestsUpdated implements Action {
  readonly type = EmployeesRequestsActionTypes.EmployeesRequestsUpdated;

  constructor(
    public payload: { employeeRequests: { id: any; status: string } },
  ) {}
}

export class OneEmployeesRequestsDeleted implements Action {
  readonly type = EmployeesRequestsActionTypes.OneEmployeesRequestsDeleted;
  constructor(public payload: number) {}
}

export class EmployeesRequestsPageRequested implements Action {
  readonly type = EmployeesRequestsActionTypes.EmployeesRequestsPageRequested;
  constructor(
    public payload: { page: QueryParamsModel; headquarterId?: number },
  ) {}
}

export class EmployeesRequestsPageLoaded implements Action {
  readonly type = EmployeesRequestsActionTypes.EmployeesRequestsPageLoaded;
  constructor(
    public payload: {
      employeesRequests: EmployeesRequestsModel[];
      totalCount: number;
    },
  ) {}
}

export class EmployeesRequestsPageToggleLoading implements Action {
  readonly type =
    EmployeesRequestsActionTypes.EmployeesRequestsPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type EmployeesRequestsActions =
  | EmployeesRequestsUpdated
  | EmployeesRequestsPageRequested
  | EmployeesRequestsPageLoaded
  | OneEmployeesRequestsDeleted
  | EmployeesRequestsPageToggleLoading;
