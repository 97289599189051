import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  HeadquarterModel,
  HeadquarterOnServerCreated,
  HeadquartersService,
  selectLastCreatedHeadquarterId,
} from '../../../../../../core/headquarter';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';

@Component({
  selector: 'kt-headquarter-new',
  templateUrl: './headquarter-new.component.html',
  styleUrls: ['./headquarter-new.component.scss'],
})
export class HeadquarterNewComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$: Observable<boolean>;
  private componentSubscriptions: Subscription;
  public headquarterForm: UntypedFormGroup;

  searching = false;
  searchFailed = false;
  formatter = (x: { name: string }) => x.name;
  searchCountry = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.headquarterService.findCountriesAutocomplete(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    );

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private headquarterService: HeadquartersService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    this.createForm();
  }

  private createForm() {
    this.headquarterForm = this.fb.group({
      name: ['', [Validators.required]],
      // country: [this.headquarter.country, [this.validate.name()]],
      googleTagManagerKey: ['', []],
      VAT: ['', [Validators.required]],
      staffBonus: ['', [Validators.required]],
      currency: ['', [Validators.required]],
      bankIDVerification: ['', [Validators.required]],
      country: ['', [Validators.required]],
      enableAnonimization: new UntypedFormControl(false),
      finishedOrdersAnonimization: [{ value: '', disabled: true }],
      unfinishedOrdersAnonimization: [{ value: '', disabled: true }],
    });

    this.headquarterForm
      .get('enableAnonimization')
      .valueChanges.subscribe((val) => {
        if (val) {
          this.headquarterForm.get('finishedOrdersAnonimization').enable();
          this.headquarterForm
            .get('finishedOrdersAnonimization')
            .setValidators(Validators.required);

          this.headquarterForm.get('unfinishedOrdersAnonimization').enable();
          this.headquarterForm
            .get('unfinishedOrdersAnonimization')
            .setValidators(Validators.required);
          this.headquarterForm.updateValueAndValidity();
        } else {
          this.headquarterForm.get('finishedOrdersAnonimization').disable();
          this.headquarterForm.get('unfinishedOrdersAnonimization').disable();
          this.headquarterForm.patchValue({
            finishedOrdersAnonimization: '',
            unfinishedOrdersAnonimization: '',
          });
          this.headquarterForm.updateValueAndValidity();
        }
      });
  }

  public createHeadquarter() {
    this.loadingSubject.next(false);
  }

  public addHeadquarter(
    _headquarter: HeadquarterModel,
    withBack: boolean = false,
  ) {
    this.loadingSubject.next(true);
    this.store.dispatch(
      new HeadquarterOnServerCreated({ headquarter: _headquarter }),
    );
    this.componentSubscriptions = this.store
      .pipe(delay(1000), select(selectLastCreatedHeadquarterId))
      .subscribe((newId) => {
        if (!newId) {
          return;
        }

        this.loadingSubject.next(false);
        if (withBack) {
          this.back();
        } else {
          const message = `New headquarter successfully has been added.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            10000,
            true,
            true,
          );
        }
      });
  }

  public back(): void {
    this.router.navigate(['/dashboard/headquarter/list']);
  }
}
