// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, catchError } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { AuthService } from '../../../core/auth/_services';
// State
import { AppState } from '../../../core/reducers';
import {
  UserActionTypes,
  UsersPageRequested,
  UsersPageLoaded,
  UserCreated,
  UserDeleted,
  UserUpdated,
  UserOnServerCreated,
  UsersActionToggleLoading,
  UsersPageToggleLoading,
  UsersPageCancelled,
  UserFailed,
} from '../_actions/user.actions';

@Injectable()
export class UserEffects {
  showPageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: true });
  hidePageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: false });

  showActionLoadingDistpatcher = new UsersActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new UsersActionToggleLoading({
    isLoading: false,
  });

  loadUsersPage$  = createEffect(() => this.actions$.pipe(
    ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showPageLoadingDistpatcher);
      const requestToServer = this.auth.findUsers(payload.page);
      const lastQuery = of(payload.page);
      return forkJoin(requestToServer, lastQuery);
    }),
    map((response: any) => {
      let res = response;
      const result: QueryResultsModel = res[0];
      const lastQuery: QueryParamsModel = res[1];
      return new UsersPageLoaded({
        users: result.items,
        totalCount: result.totalCount,
        page: lastQuery,
      });
    }),
    catchError((err) => {
      return of(null);
    }),
  ));

  deleteUser$  = createEffect(() => this.actions$.pipe(
    ofType<UserDeleted>(UserActionTypes.UserDeleted),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.auth.deleteUser(payload.id);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
    catchError((err) => {
      return of(null);
    }),
  ));

  updateUser$  = createEffect(() => this.actions$.pipe(
    ofType<UserUpdated>(UserActionTypes.UserUpdated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.auth.updateUser(payload.user);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
    catchError((err) => {
      return of(null);
    }),
  ));

  createUser$  = createEffect(() => this.actions$.pipe(
    ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
    mergeMap(({ payload }) => {
      // this.store.dispatch(this.showActionLoadingDistpatcher);
      return this.auth.createUser(payload.user).pipe(
        tap(
          (res) => {
            this.store.dispatch(new UserCreated({ user: res }));
          },
          (err) => {
            this.store.dispatch(new UserFailed());
            // this.store.dispatch(new UsersPageCancelled())
          },
        ),
        catchError((err) => {
          return of(err);
        }),
      );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
    // catchError(err => {
    //   return of(null);
    // })
  ));

  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private store: Store<AppState>,
  ) {}
}
