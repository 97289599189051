<div class="login">
  <h3 class="login__title">{{ 'AUTH.LOGIN.BUTTON' | translate }}</h3>

  <form class="login__form" [formGroup]="loginForm" autocomplete="off">
    <mat-form-field class="login__field" appearance="outline">
      <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
      <input matInput id="login-input" type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off" />
      <mat-error *ngIf="isControlHasError('email', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'email')">
        <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login__field" appearance="outline">
      <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
      <input matInput type="password" id="password-input" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" />
      <mat-error *ngIf="isControlHasError('password', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('password', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('password', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
      </mat-error>
    </mat-form-field>

    <a class="login__link" [routerLink]="['/auth/forgot-password']">
      {{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
    </a>
    <p *ngFor="let error of errors">
      {{ ('AUTH.SERVER_ERRORS' | translate) + ' ' + error  }}
    </p>
    <div class="login__actions">
      <button (click)="submit()" class="btn btn-primary btn-elevate">
        <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">{{ 'AUTH.LOGIN.BUTTON' | translate }}</span>
      </button>
    </div>
  </form>
</div>
