// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { HeadquartersState } from '../_reducers/headquarter.reducers';
import { HeadquarterModel } from '../_models/headquarter.model';

export const selectHeadquartersState =
  createFeatureSelector<HeadquartersState>('headquarters');

export const selectHeadquarterById = (headquarterId: number) =>
  createSelector(
    selectHeadquartersState,
    (headquartersState) => headquartersState.entities[headquarterId],
  );

export const selectHeadquartersPageLoading = createSelector(
  selectHeadquartersState,
  (headquartersState) => headquartersState.listLoading,
);

export const selectHeadquartersActionLoading = createSelector(
  selectHeadquartersState,
  (customersState) => customersState.actionsloading,
);

export const selectHeadquartersPageLastQuery = createSelector(
  selectHeadquartersState,
  (headquartersState) => headquartersState.lastQuery,
);

export const selectLastCreatedHeadquarterId = createSelector(
  selectHeadquartersState,
  (headquartersState) => headquartersState.lastCreatedHeadquarterId,
);

export const selectHeadquartersInitWaitingMessage = createSelector(
  selectHeadquartersState,
  (headquartersState) => headquartersState.showInitWaitingMessage,
);

export const selectHeadquartersInStore = createSelector(
  selectHeadquartersState,
  (headquartersState) => {
    const items: HeadquarterModel[] = [];
    each(headquartersState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: HeadquarterModel[] = httpExtension.sortArray(
      items,
      headquartersState.lastQuery.sortField,
      headquartersState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, headquartersState.totalCount, '');
  },
);

export const selectHasHeadquartersInStore = createSelector(
  selectHeadquartersInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  },
);
