import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {environment} from '../../../environments/environment';
import {DevicesReducerState} from "../catalog";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private production$ = new BehaviorSubject(true);
  private readonly production = environment.production;
  previousOrders$ = new BehaviorSubject([]);
  constructor() {
      if (!this.production) {
          let _production = LocalStorageService.get('production');
          if (_production == null) {
              LocalStorageService.save('production', true);
              _production = true;
          }
          this.production$.next(_production);
      }
      let prevOrders = LocalStorageService.get('orders');
      prevOrders = prevOrders ? prevOrders : [];
      this.previousOrders$.next(prevOrders);
  }

  set productionMode(mode: boolean) {
      LocalStorageService.save('production', mode);
      this.production$.next(mode);
  }

  get productionMode$(): BehaviorSubject<boolean> {
      return this.production$;
  }

  set previousOrders(orders: DevicesReducerState[]) {
      LocalStorageService.save('orders', orders);
      this.previousOrders$.next(orders);
  }
}
