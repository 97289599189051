<div [ngClass]="darkMode?'darkMode':''">
    <mat-toolbar class="mat-elevation-z8">
        <button mat-icon-button (click)="toggleSidenavLock()">
            <mat-icon>{{ isSidenavOpen ? 'close' : 'menu' }}</mat-icon>
        </button>
        <div class="actions">
            <mat-slide-toggle (change)="switchTheme()" color="primary">
                <mat-icon>{{darkMode?'dark_mode':'light_mode'}}</mat-icon>
            </mat-slide-toggle>
            <kt-language-selector2></kt-language-selector2>
            <kt-user-profile2></kt-user-profile2>
        </div>
    </mat-toolbar>
    <mat-drawer-container class="sidenav-container">
        <mat-drawer [mode]="'side'" [opened] = true class="sidenav" >
            <div [ngClass]="isSidenavOpen? 'open' : ''" class="sidebar">
                <ul class="nav-list">
                    <ng-container *ngFor="let item of menuAsideService.menuList$ | async">
                        <ng-template [ngxPermissionsOnly]="item.permission" *ngIf="titleFilter(item.title)">
                            <li class="ch-item" >
                                <ng-container *ngIf="!item.section; else section">
                                    <a (click)="clickItem(item)"
                                       class="item-container"
                                       matTooltipPosition="right"
                                       [matTooltip]="item.translate ? (item.translate | translate) : item.title">
                                        <div class="item-container__title">
                                            <i class='material-icons'>{{item.matIcon}}</i>
                                            <span *ngIf="isSidenavOpen" [ngClass]="isSidenavOpen? 'open' : ''" class="links_name">{{ item.translate ? (item.translate | translate) : item.title }}</span>
                                        </div>
                                        <div class="item-container__action" *ngIf="item.submenu && isSidenavOpen">
                                            <i class="material-icons rotator" [ngClass]="item.opened ? 'open' : ''">chevron_right</i>
                                        </div>
                                    </a>
                                    <div *ngIf="isSidenavOpen" class="submenu" [ngClass]="item.opened ?'open':''">
                                        <ul>
                                            <ng-template *ngFor="let child of item.submenu" [ngxPermissionsOnly]="child.permission">
                                                <li >
                                                    <div (click)="clickItem(child)" class="item-text_link">{{ child.translate ? (child.translate | translate) : child.title }}</div>
                                                </li>
                                            </ng-template>

                                        </ul>
                                    </div>
                                </ng-container>
                                <ng-template #section>
                                    <i *ngIf="!isSidenavOpen" class="material-icons section-icon">more_horiz</i>
                                    <div *ngIf="isSidenavOpen" class="section-title">{{ item.translate ? (item.translate | translate) : item.title }}</div>
                                </ng-template>
                            </li>
                        </ng-template>
                    </ng-container>
                </ul>
            </div>
        </mat-drawer>

        <mat-drawer-content class="drawer-content" (click)="isSidenavOpen = false;$event.stopPropagation()">
            <div class="content" >
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

