<section class="p-4">
  <h3 class="title">
    <mat-icon class="warning-icon" color="warn">warning</mat-icon>
    {{ 'ORDER.DISCOUNT_CONFIRM.TITLE' | translate }}
  </h3>
  <p>
    {{ 'ORDER.DISCOUNT_CONFIRM.TEXT' | translate }}
    <strong class="discount">{{ data.value }} {{ data.type === 'amount' ? 'kr' : '%' }}</strong>
    {{ 'ORDER.DISCOUNT_CONFIRM.DISCOUNT' | translate }}
  </p>

  <div class="row justify-content-end mr-0">
    <button class="btn btn-primary" (click)="confirm()">Confirm</button>
  </div>
</section>
