// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
// Actions
import {
  HeadquarterActions,
  HeadquarterActionTypes,
} from '../_actions/headquarter.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { HeadquarterModel } from '../_models/headquarter.model';

export interface HeadquartersState extends EntityState<HeadquarterModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  lastCreatedHeadquarterId: number;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<HeadquarterModel> =
  createEntityAdapter<HeadquarterModel>();

export const initialHeadquartersState: HeadquartersState =
  adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedHeadquarterId: undefined,
    showInitWaitingMessage: true,
  });

export function headquartersReducer(
  state = initialHeadquartersState,
  action: HeadquarterActions,
): HeadquartersState {
  switch (action.type) {
    case HeadquarterActionTypes.HeadquartersPageToggleLoading:
      return {
        ...state,
        listLoading: action.payload.isLoading,
        lastCreatedHeadquarterId: undefined,
      };
    case HeadquarterActionTypes.HeadquartersActionToggleLoading:
      return {
        ...state,
        actionsloading: action.payload.isLoading,
      };
    case HeadquarterActionTypes.HeadquarterOnServerCreated:
      return {
        ...state,
      };
    case HeadquarterActionTypes.HeadquarterCreated:
      return adapter.addOne(action.payload.headquarter, {
        ...state,
        lastCreatedHeadquarterId: action.payload.headquarter.id,
      });
    case HeadquarterActionTypes.HeadquarterUpdated:
      return adapter.updateOne(action.payload.partialHeadquarter, state);
    case HeadquarterActionTypes.HeadquartersStatusUpdated: {
      const _partialHeadquarters: Update<HeadquarterModel>[] = [];
      for (let i = 0; i < action.payload.headquarters.length; i++) {
        _partialHeadquarters.push({
          id: action.payload.headquarters[i].id,
          changes: {
            // status: action.payload.status
          },
        });
      }
      return adapter.updateMany(_partialHeadquarters, state);
    }
    case HeadquarterActionTypes.OneHeadquarterDeleted:
      return adapter.removeOne(action.payload.id, state);
    case HeadquarterActionTypes.ManyHeadquartersDeleted:
      return adapter.removeMany(action.payload.ids, state);
    case HeadquarterActionTypes.HeadquartersPageCancelled:
      return {
        ...state,
        listLoading: false,
        lastQuery: new QueryParamsModel({}),
      };
    case HeadquarterActionTypes.HeadquartersPageLoaded:
      return adapter.addMany(action.payload.headquarters, {
        ...initialHeadquartersState,
        totalCount: action.payload.totalCount,
        listLoading: false,
        lastQuery: action.payload.page,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}

export const getHeadquarterState =
  createFeatureSelector<HeadquarterModel>('headquarters');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
