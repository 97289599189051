export class QueryParamsModel {
  // fields
  filter: any;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;
  salesFilter: string;
  dateStartFilter: string;
  dateEndFilter: string;
  providerFilter: string;
  statusFilter: string;
  storeFilter: string;
  headquarterId?: number;

  // constructor overrides
  constructor(
    _filter: any,
    _sortOrder: string = 'asc',
    _sortField: string = '',
    _pageNumber: number = 0,
    _pageSize: number = 10,
    _salesFilter: string = '',
    _dateStartFilter: string = '',
    _dateEndFilter: string = '',
    _providerFilter: string = '',
    _statusFilter: string = '',
    _storeFilter: string = '',
    _headquarterId: number = null,
  ) {
    this.filter = _filter;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber + 1;
    this.pageSize = _pageSize;
    this.salesFilter = _salesFilter;
    this.dateStartFilter = _dateStartFilter;
    this.dateEndFilter = _dateEndFilter;
    this.providerFilter = _providerFilter;
    this.statusFilter = _statusFilter;
    this.storeFilter = _storeFilter;
    this.headquarterId = _headquarterId;
  }
}

export class UserQueryParamsModel {
  pageNumber: number;
  pageSize: number;
  storeId: string;
  user: string;
  // constructor overrides
  constructor(
    _filter: any,
    _pageNumber: number = 0,
    _pageSize: number = 10,
    _storeId: string = '',
    _user: string = '',
  ) {
    this.pageNumber = _pageNumber + 1;
    this.pageSize = _pageSize;
    this.storeId = _storeId;
    this.user = _user;
  }
}

export class OrderListQuery {
  filter: any;
  sortOrder: string; // asc || desc
  sortField: string;
  pageNumber: number;
  pageSize: number;
  salesFilter: string;
  dateStartFilter: string;
  dateEndFilter: string;
  providerFilter: string;
  statusFilter: string;
  storeFilter: string;
  customerFilter: string;
  simCardFilter: string;
  orderNumberFilter: string;
  headquarterId: number;
  // constructor overrides
  constructor(
    _filter: any,
    _sortOrder: string = 'asc',
    _sortField: string = '',
    _pageNumber: number = 0,
    _pageSize: number = 20,
    _salesFilter: string = '',
    _dateStartFilter: string = '',
    _dateEndFilter: string = '',
    _providerFilter: string = '',
    _statusFilter: string = '',
    _storeFilter: string = '',
    _customerFilter: string = '',
    _simCardFilter: string = '',
    _orderNumberFilter: string = '',
    _headquarterId: number = null,
  ) {
    this.filter = _filter;
    this.sortOrder = _sortOrder;
    this.sortField = _sortField;
    this.pageNumber = _pageNumber + 1;
    this.pageSize = _pageSize;
    this.salesFilter = _salesFilter;
    this.dateStartFilter = _dateStartFilter;
    this.dateEndFilter = _dateEndFilter;
    this.providerFilter = _providerFilter;
    this.statusFilter = _statusFilter;
    this.storeFilter = _storeFilter;
    this.customerFilter = _customerFilter;
    this.simCardFilter = _simCardFilter;
    this.orderNumberFilter = _orderNumberFilter;
    this.headquarterId = _headquarterId;
  }
}
