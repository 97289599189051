// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insuranceScreenProtector',
})
export class InsuranceScreenProtectorPipe implements PipeTransform {
  transform(additionalServices: any[], screenProtector: boolean): any {
    if (!additionalServices) {
      return [];
    }
    return additionalServices
      .filter(
        (aS) =>
          aS.productType !== 'Insurance' ||
          (aS.productType === 'Insurance' &&
            aS.screenProtector === screenProtector),
      )
      .sort((a, b) => {
        if (a.articleName > b.articleName) {
          return 1;
        } else {
          return -1;
        }
      });
  }
}
