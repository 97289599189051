import { ActionTypes, DevicesActions} from '../_actions/devices.actions';
import { DeviceModel } from '../_models/device.model';
import { ContractModel, Insurance } from '../_models/contract.model';
import {OrderModel, OrderType} from '../_models/order.model';
import { CustomerModel } from '../_models/customer.model';
import { ActionInfo } from '../_models/action-info.model';
import { OrderCheckoutModel } from '../_models/order-checkout.model';
import { IStoreAdditionalDevice } from '../_models/storeAdditionalDevice.model';
import { HeadquarterProviderModel } from '../_models/headquarterProviderModel';
import { SelectedCampaign } from '../../../views/pages/catalog/catalog-checkout/tab-overview/models/campaign';

export interface DevicesReducerState {
  selectedDevice: DeviceModel;
  selectedContract: ContractModel;
  selectDevicesWarranty: number[];
  optionsContract: ContractModel;
  sampleOrders: OrderModel;
  customer: CustomerModel;
  actionInfo: ActionInfo;
  generatedOrder: OrderCheckoutModel;
  availableContractMonths: number[];
  selectedAdditionalProducts: any;
  selectedCampaigns: SelectedCampaign[];
  moreDevices: any;
  device?: any;
  providerOnly: HeadquarterProviderModel;
  ignoreStartingFee: boolean;
  currentContractData: any;
  selectedAdditionalServices?: any;
  subDevicesTmp?: [
    { device: DeviceModel; contract: ContractModel; insurance?: Insurance }
  ];
  additionalDevices: IStoreAdditionalDevice[];
  noFinancing: boolean;
  showScreenProtectorOnly: boolean;
  selectedProvider: HeadquarterProviderModel;
  providers: HeadquarterProviderModel[];
  extraUsers: any;
  VAS: any;
  includedMsisdns: any;
  extra: boolean;
  total?: any;
  id?: number;
  family?: string;
  type?: OrderType;
  margin?: number;
  customSalesDiscount?: {value: string; type: string; cost?: string};
}

export const initialDevicesState: DevicesReducerState = {
  selectedDevice: new DeviceModel(),
  selectedContract: new ContractModel(),
  selectDevicesWarranty: [],
  optionsContract: new ContractModel(),
  sampleOrders: null,
  customer: null,
  actionInfo: null,
  generatedOrder: null,
  availableContractMonths: [],
  selectedAdditionalProducts: [],
  selectedCampaigns: [],
  moreDevices: [],
  providerOnly: null,
  ignoreStartingFee: false,
  currentContractData: null,
  additionalDevices: [],
  noFinancing: false,
  showScreenProtectorOnly: false,
  selectedProvider: null,
  providers: [],
  extraUsers: [],
  VAS: [],
  includedMsisdns: [],
  extra: false,
  total: {},
};

export function DevicesReducer(
  state = initialDevicesState,
  action: DevicesActions,
): DevicesReducerState {
  switch (action.type) {
    case ActionTypes.SelectDevices:
      return {
        ...state,
        selectedDevice: action.payload,
      };
    case ActionTypes.SelectContract:
      return {
        ...state,
        selectedContract: action.payload,
      };
    case ActionTypes.AdditionalDevices:
      return {
        ...state,
        additionalDevices: action.payload,
      };
    case ActionTypes.SelectDevicesWarranty:
      return {
        ...state,
        selectDevicesWarranty: action.payload,
      };
    case ActionTypes.SetOptionsContract:
      return {
        ...state,
        optionsContract: action.payload,
      };
    case ActionTypes.SetSampleOrders:
      return {
        ...state,
        sampleOrders: action.payload,
      };
    case ActionTypes.SetCustomer:
      return {
        ...state,
        customer: action.payload,
      };
    case ActionTypes.SetCustomerSubCount:
      return {
        ...state,
        customer: Object.assign({},state.customer, {subsAvailable: action.payload}),
      };
    case ActionTypes.SetActionInfo:
      return {
        ...state,
        actionInfo: action.payload,
      };
    case ActionTypes.SetCheckout:
      return {
        ...state,
        generatedOrder: action.payload,
      };
    case ActionTypes.SetContractAvalMonths:
      return {
        ...state,
        availableContractMonths: action.payload,
      };
    case ActionTypes.SetAdditionalServices:
      return {
        ...state,
        selectedAdditionalServices: action.payload,
      };
    case ActionTypes.SetAdditionalProducts:
      return {
        ...state,
        selectedAdditionalProducts: action.payload,
      };
    case ActionTypes.SetMoreDevices:
      return {
        ...state,
        moreDevices: [...state.moreDevices, action.payload],
      };
    case ActionTypes.SetProviderOnly:
      return {
        ...state,
        providerOnly: action.payload,
      };
    case ActionTypes.SetDiscount:
      return {
        ...state,
        optionsContract: Object.assign({}, state.optionsContract, {
          discount: action.payload,
        }),
      };
    case ActionTypes.RemoveStartingFee:
      return {
        ...state,
        ignoreStartingFee: action.payload,
      };
    case ActionTypes.SwitchOrder:
      return {
        ...action.payload,
      };
    case ActionTypes.NoFinancing:
      return {
        ...state,
        noFinancing: action.payload,
      };
    case ActionTypes.SelectScreenProtector:
      return {
        ...state,
        showScreenProtectorOnly: action.payload,
      };
    case ActionTypes.Reset:
      return {
        selectDevicesWarranty: [],
        selectedDevice: new DeviceModel(),
        selectedContract: new ContractModel(),
        optionsContract: new ContractModel(),
        sampleOrders: new OrderModel(),
        customer: null,
        actionInfo: null,
        generatedOrder: null,
        availableContractMonths: [],
        selectedAdditionalProducts: [],
        selectedCampaigns: [],
        moreDevices: [...state.moreDevices],
        providerOnly: null,
        ignoreStartingFee: false,
        currentContractData: null,
        additionalDevices: [],
        noFinancing: false,
        showScreenProtectorOnly: false,
        selectedProvider: null,
        providers: [],
        extraUsers: [],
        VAS: [],
        includedMsisdns: [],
        extra: false
      };
    case ActionTypes.ResetPartly:
      return {
        ...state,
        selectDevicesWarranty: [],
        selectedDevice: new DeviceModel(),
        selectedContract: new ContractModel(),
        optionsContract: new ContractModel(),
        sampleOrders: new OrderModel(),
        actionInfo: null,
        generatedOrder: null,
        availableContractMonths: [],
        selectedAdditionalProducts: [],
        selectedCampaigns: [],
        moreDevices: [...state.moreDevices],
        ignoreStartingFee: false,
        currentContractData: null,
        additionalDevices: [],
        noFinancing: false,
        showScreenProtectorOnly: false,
        providers: [],
        extraUsers: [],
        VAS: [],
        includedMsisdns: [],
        extra: false
      };
    case ActionTypes.AdditionalOrder:
      return {
        ...state,
          sampleOrders: new OrderModel(),
        VAS: [],
        selectedContract: new ContractModel(),
        extraUsers: [],
        selectedDevice: new DeviceModel(),
        generatedOrder: null,
      };
    case ActionTypes.SetCurrentContractData:
      return {
        ...state,
        currentContractData: action.payload,
      };
    case ActionTypes.SetProvider:
      return {
        ...state,
        selectedProvider: action.payload,
      };
    case ActionTypes.SetProviders:
      return {
        ...state,
        providers: action.payload,
      };
    case ActionTypes.SetExtraUsers:
      return {
        ...state,
        extraUsers: action.payload,
      };
    case ActionTypes.SetVAServices:
      return {
        ...state,
        VAS: action.payload,
      };
    case ActionTypes.SetFamilyTypes:
      return {
        ...state,
        extraUsers: action.payload,
      };
    case ActionTypes.IncludeMsisdns:
      return {
        ...state,
        includedMsisdns: action.payload,
      };
    case ActionTypes.SetCampaigns:
      return {
        ...state,
        selectedCampaigns: action.payload,
      };
    case ActionTypes.SetTotal:
      return {
        ...state,
        total: action.payload,
      };
    case ActionTypes.SetFamily:
      return {
        ...state,
        family: action.payload,
      };
    default:
      return state;
  }
}
