import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import {LocalStorageService} from "../../services/local-storage.service";

const API_USERS_URL = 'users';
const API_USER_URL = `${environment.api_url}/user`;
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
const API_URL = environment.api_url;

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}
  // Authentication/Authorization
  login(
    email: string,
    password: string,
  ): Observable<{ user: User; token: string; refreshToken: string }> {
    // return this.http.post<User>(API_USERS_URL, { email, password });
    return this.http.post<{ user: User; token: string; refreshToken: string }>(
      `${environment.api_url}/login`,
      { email, password },
      { observe: 'body' },
    );
  }

  videoLogin(email: string, birthDate: string) {
    return this.http.post(`${environment.api_url}/login/order`, {
      email,
      birthDate,
    });
  }

  getNewTokens(
    refreshToken: string,
  ): Observable<{ token: string; refreshToken: string }> {
    return this.http.get<{ token: string; refreshToken: string }>(
      `${API_USER_URL}/refresh/token?refreshToken=${refreshToken}`,
    );
  }

  getUserByToken(): Observable<User> {
    const userToken = LocalStorageService.get(environment.authTokenKey);
    return this.http.get<User>(`${environment.api_url}/user/me`);
  }

  register(user: User): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<User>(API_USERS_URL, user, { headers: httpHeaders })
      .pipe(
        map((res: User) => {
          return res;
        }),
        catchError((err) => {
          return null;
        }),
      );
  }

  /*
   * Submit forgot password request
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  public requestPassword(email: string): Observable<any> {
    return this.http
      .get(API_USERS_URL + '/forgot?=' + email)
      .pipe(catchError(this.handleError('forgot-password', [])));
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(API_USER_URL + '/forgot', { email: email });
  }

  resetPassword(password: string, token: string): Observable<any> {
    let requestData = {
      token: token,
      password: password,
    };
    return this.http.post(API_USER_URL + '/password/reset', requestData);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(API_USERS_URL);
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${environment.api_url}/user/${userId}`);
  }

  // DELETE => delete the user from the server
  deleteUser(userId: number) {
    const url = `${API_USERS_URL}/${userId}`;
    return this.http.delete(url);
  }

  // UPDATE => PUT: update the user on the server
  updateUser(_user: User): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.patch(`${environment.api_url}/user/${_user.id}`, _user, {
      headers: httpHeaders,
    });
  }
  // UPDATE => PUT: update the user on the server
  updatePassword(_user: User, id): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.patch(`${environment.api_url}/user/${id}`, _user, {
      headers: httpHeaders,
    });
  }
  // UPDATE => PUT: update the user on the server
  addRole(_user: User, role: Role): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');

    return this.http.post(
      `${environment.api_url}/user/role`,
      {
        userId: _user.id,
        roleId: role.id,
      },
      { headers: httpHeaders },
    );
  }
  // UPDATE => PUT: update the user on the server
  removeRole(_user: User, role: Role): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');

    return this.http.delete(
      `${environment.api_url}/user/${_user.id}/role/${role.id}`,
      { headers: httpHeaders },
    );
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: User): Observable<User> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<User>(API_USER_URL, user, { headers: httpHeaders });
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findUsers(queryParams): Observable<QueryResultsModel> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');

    return this.http.get<QueryResultsModel>(
      `${environment.api_url}/user/list?page=${queryParams.pageNumber}&limit=${queryParams.pageSize}&storeId=${queryParams.storeId}&query=${queryParams.user}`,
    );
    // return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
  }

  findUsersAutocomplete(
    term: string,
    headquarterId: number = null,
  ): Observable<[{ id: number; email: string }]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    let query = `query=${term}${
      headquarterId ? `&headquarterId=${headquarterId}` : ''
    }`;
    return this.http.get<[{ id: number; email: string }]>(
      `${environment.api_url}/user/autocomplete?${query}`,
    );
    // return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
  }

  // Permission
  getAllPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(API_PERMISSION_URL);
  }

  getRolePermissions(roleId: number): Observable<Permission[]> {
    return this.http.get<Permission[]>(
      API_PERMISSION_URL + '/getRolePermission?=' + roleId,
    );
  }

  // Roles
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(API_ROLES_URL);
  }

  getRoleById(roleId: number): Observable<Role> {
    return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
  }

  // CREATE =>  POST: add a new role to the server
  createRole(role: Role): Observable<Role> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders });
  }

  // UPDATE => PUT: update the role on the server
  updateRole(role: Role): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
  }

  // DELETE => delete the role from the server
  deleteRole(roleId: number): Observable<Role> {
    const url = `${API_ROLES_URL}/${roleId}`;
    return this.http.delete<Role>(url);
  }

  // Check Role Before deletion
  isRoleAssignedToUsers(roleId: number): Observable<boolean> {
    return this.http.get<boolean>(
      API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId,
    );
  }

  findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // This code imitates server calls
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<QueryResultsModel>(
      API_ROLES_URL + '/findRoles',
      queryParams,
      { headers: httpHeaders },
    );
  }

  getTokenByIp() {
    return this.http.get(API_USER_URL + '/autologin');
    // return this.http.get(API_USER_URL + '/catalog');
  }

  catalogPreviewCheck() {
    return this.http.get(API_USER_URL + '/catalog');
  }

  getConfiguration() {
    return this.http.get(`${API_URL}/configuration`);
  }

  /*
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
