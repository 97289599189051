<div class="overflow-hidden">
  <mat-slide-toggle class="mb10"
    (toggleChange)="changeMode('campaignModule')"
    [checked]="isCampaignModule">
    Campaign module
  </mat-slide-toggle>
  <br />
  <mat-slide-toggle class="mb10"
    (toggleChange)="changeMode('staffBonusModule')"
    [checked]="isStaffBonusModule">
    Staff bonus module
  </mat-slide-toggle>
  <br>
  <mat-slide-toggle class="mb10"
    (toggleChange)="changeMode('focusContractMode')"
    [checked]="isFocusContractMode">
    Focus contract mode
  </mat-slide-toggle>
  <br>
  <mat-slide-toggle class="mb10"
    (toggleChange)="changeMode('hideCustomerInfo')"
    [checked]="isHideCustomerInfo">
    Hide customer info
  </mat-slide-toggle>
  <br>
  <mat-slide-toggle class="mb10"
    (toggleChange)="changeMode('enableSalesDiscount')"
    [checked]="isEnableSalesDiscount">
    Sales discount
  </mat-slide-toggle>
</div>
