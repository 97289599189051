<div
  class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
  id="kt_footer"
>
  <div class="kt-footer__copyright">
    {{ today | date: 'yyyy' }}&nbsp;&copy;&nbsp;
  </div>
  <div class="kt-footer__menu">
  </div>
</div>
