import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'ramda';

@Pipe({
  name: 'filterRFilter',
})
export class FilterRFilterPipe implements PipeTransform {
  transform(value: any[], isEven, controlValue): any {
    // console.log(value);
    return R.filter(isEven, value);
  }
}
