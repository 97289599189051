import { Pipe, PipeTransform } from '@angular/core';

export enum SortTypeFields {
  articleName = 'articleName',
  monthlyCost = 'monthlyCost',
  dataIncluded = 'dataIncluded',
}

@Pipe({ name: 'sort' })
export class SortPipe implements PipeTransform {
  transform(array: any[], value: SortTypeFields) {
    switch (value) {
      case SortTypeFields.articleName:
        return array.sort(function (a, b) {
          let nameA = a[value].toLowerCase();
          let nameB = b[value].toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // don't sort
        });
        break;
      case SortTypeFields.monthlyCost:
        return array.sort((a, b) => a[value] - b[value]);
        break;
      case SortTypeFields.dataIncluded:
        return array.sort((a, b) => a[value] - b[value]);
        break;
      default:
        return array;
        break;
    }
  }
}
