import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { QueryResultsModel } from '../models/query-models/query-results.model';
import { QueryParamsModel } from '../models/query-models/query-params.model';
import { HttpExtenstionsModel } from '../models/http-extentsions-model';

@Injectable()
export class HttpUtilsService {
  getFindHTTPParams(queryParams: any): HttpParams {
    let params;

    if (queryParams.sortField && queryParams.sortOrder) {
      params = new HttpParams()
        .set(
          'sort',
          `${queryParams.sortOrder === 'asc' ? '' : '-'}${
            queryParams.sortField
          }`,
        )
        .set('page', queryParams.pageNumber.toString())
        .set('query', queryParams.filter || '')
        .set('salesId', queryParams.salesFilter || '')
        .set('provider', queryParams.providerFilter || '')
        .set('date', queryParams.dateStartFilter || '')
        .set('limit', queryParams.pageSize.toString())
        .set('state', queryParams.statusFilter || '')
        .set('customer', queryParams.customerFilter || '')
        .set('store', queryParams.storeFilter || '')
        .set('id', queryParams.orderNumberFilter || '')
        .set('simCardNumber', queryParams.simCardFilter)
        .set('search', queryParams.filter.manufacture)
        .set('headquarterId', queryParams.headquarterId || null);
    } else {
      params = new HttpParams()
        .set('page', queryParams.pageNumber.toString())
        .set('query', queryParams.filter || '')
        .set('salesId', queryParams.salesFilter || '')
        .set('provider', queryParams.providerFilter || '')
        .set('date', queryParams.dateStartFilter || '')
        .set('limit', queryParams.pageSize.toString())
        .set('state', queryParams.statusFilter || '')
        .set('customer', queryParams.customerFilter || '')
        .set('store', queryParams.storeFilter || '')
        .set('id', queryParams.orderNumberFilter || '')
        .set('simCardNumber', queryParams.simCardFilter)
        .set('search', queryParams.filter.manufacture)
        .set('headquarterId', queryParams.headquarterId || null);
    }
    return params;
  }

  getFindHTTPParamsReport(queryParams: any): HttpParams {
    let params;
    if (queryParams.sortField && queryParams.sortOrder) {
      params = new HttpParams()
        .set(
          'sort',
          `${queryParams.sortOrder === 'asc' ? '' : '-'}${
            queryParams.sortField
          }`,
        )
        .set('page', queryParams.pageNumber.toString())
        .set('query', queryParams.filter || '')
        .set('salesId', queryParams.salesFilter || '')
        .set('provider', queryParams.providerFilter || '')
        .set('dateFrom', queryParams.dateStartFilter || '')
        .set('dateTo', queryParams.dateEndFilter || '')
        .set('limit', queryParams.pageSize.toString())
        .set('state', queryParams.statusFilter || '')
        .set('headquarterId', queryParams.headquarterId || null);
    } else {
      params = new HttpParams()
        .set('page', queryParams.pageNumber.toString())
        .set('query', queryParams.filter || '')
        .set('salesId', queryParams.salesFilter || '')
        .set('provider', queryParams.providerFilter || '')
        .set('dateFrom', queryParams.dateStartFilter || '')
        .set('dateTo', queryParams.dateEndFilter || '')
        .set('limit', queryParams.pageSize.toString())
        .set('state', queryParams.statusFilter || '')
        .set('headquarterId', queryParams.headquarterId || null);
    }
    return params;
  }

  getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  baseFilter(
    _entities: any[],
    _queryParams: QueryParamsModel,
    _filtrationFields: string[] = [],
  ): QueryResultsModel {
    const httpExtention = new HttpExtenstionsModel();
    return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
  }

  sortArray(
    _incomingArray: any[],
    _sortField: string = '',
    _sortOrder: string = 'asc',
  ): any[] {
    const httpExtention = new HttpExtenstionsModel();
    return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
  }
}
