import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { debounceTime } from 'rxjs/operators';
import {
  selectEmployeesRequestsInStore,
  selectEmployeesRequestsPageLoading,
  selectERShowInitWaitingMessage,
} from '../_selectors/employees-requests.selector';

export class EmployeesRequestsDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.store
      .pipe(select(selectEmployeesRequestsInStore), debounceTime(600))
      .subscribe((response: QueryResultsModel) => {
        this.entitySubject.next(response.items);
        this.paginatorTotalSubject.next(response.totalCount);
      });

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectERShowInitWaitingMessage),
    );

    this.loading$ = this.store.pipe(select(selectEmployeesRequestsPageLoading));
  }
}
