import { Injectable } from '@angular/core';

@Injectable()
export class TypesUtilsService {
  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  dateFormat(date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    if (date) {
      return `${month}/${day}/${year}`;
    }

    return '';
  }

  getDateFromString(dateInStr: string = ''): Date {
    if (dateInStr && dateInStr.length > 0) {
      const dateParts = dateInStr.trim().split('/');
      const year = this.toInteger(dateParts[2]);
      const month = this.toInteger(dateParts[0]);
      const day = this.toInteger(dateParts[1]);
      // tslint:disable-next-line:prefer-const
      let result = new Date();
      result.setDate(day);
      result.setMonth(month - 1);
      result.setFullYear(year);
      return result;
    }

    return new Date();
  }

  getDateStringFromDate(_date: Date = new Date()): string {
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const date = _date.getDate();
    return `${month}/${date}/${year}`;
  }
}
