import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';
import { HeadquarterModel } from '../../headquarter/_models/headquarter.model';

export class User {
  id: number;
  password: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  roles: { name: string; id: number }[];
  pic: string;
  fullname: string;
  firstname: string;
  lastname: string;
  occupation: string;
  companyName: string;
  phone: string;
  address: Address;
  socialNetworks: SocialNetworks;
  headquarterId: number;
  externalReferenceId: string;
  autologinIp: string;
  headquarter: Partial<HeadquarterModel>;
  insuranceUserId?: string;
  bankUserId?: string;
  providerSalesRef?: any;
  teliaUserId?: string;
  tele2SalesAgentId?: string;
  storeId?: number;

  clear(): void {
    this.id = undefined;
    this.password = '';
    this.email = '';
    this.roles = [];
    this.fullname = '';
    this.firstname = '';
    this.lastname = '';
    this.accessToken = 'access-token-' + Math.random();
    this.refreshToken = 'access-token-' + Math.random();
    this.pic = './assets/media/users/default.jpg';
    this.occupation = '';
    this.companyName = '';
    this.phone = '';
    this.headquarterId = null;
    this.headquarter = null;
    this.insuranceUserId = null;
    this.bankUserId = null;
    this.address = new Address();
    this.address.clear();
    this.socialNetworks = new SocialNetworks();
    this.socialNetworks.clear();
  }
}
