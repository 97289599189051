import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {HeadquarterProviderModel, ProviderService} from '../../../../core/catalog';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { OrdersService } from '../../../../core/catalog/_services/orders.service';
import { ToastService } from '../../../../core/services/toast/toast.service';
import { EventTypes } from '../../../../core/services/toast/models/event-types';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../../../../core/services/application.service';
import {HEADQUARTERS} from "../../../../core/utils/constants/headquarters.constants";
import {PROVIDER_CODES} from "../../../../core/catalog/_models/headquarterProviderModel";

export interface DialogInfo {
    orders: Array<OrdersInfo>;
    provider: HeadquarterProviderModel;
    purchase?: ITeliaPurchase;
}

interface ITeliaPurchase {
    purchaseRequestToken: string;
    qr?: boolean;
    signed?: boolean;
}

interface AgreementInfo {
    link: string;
    documentId: any;
    signed?: boolean;
    status?: string;
    providerOrderId?: string;
    qr?: boolean;
    errorMessage?: string;
}

interface OrdersInfo {
    orderIds: number[];
    agreement?: AgreementInfo;
    voucher?: string;
}

@Component({
    selector: 'kt-checkout-success',
    templateUrl: './checkout-success.component.html',
    styleUrls: ['./checkout-success.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CheckoutSuccessComponent implements OnInit, OnDestroy {
    agreementsChecking = false;
    paymentChecking = false;
    private agreementRequestTimer;
    private paymentRequestTimer;
    private destroyStream$ = new Subject<void>();
    private email: string;
    ordersError = false;

    constructor(
        private orders: OrdersService,
        private providers: ProviderService,
        private toasts: ToastService,
        private dialogRef: MatDialogRef<CheckoutSuccessComponent>,
        private translate: TranslateService,
        private app: ApplicationService,
        @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    ) {
    }

    ngOnInit() {
        this.processInformation();
        this.checkOrdersAgreementStatus();
        this.checkPaymentStatus();
        this.getPreviousOrders();
    }

    private processInformation() {
        this.data.purchase = this.data.purchase ? {...this.data.purchase, qr: false, signed: false} : undefined;
        this.data.orders = this.data.orders.map((o) => {
            const agreement = o.agreement ? {...o.agreement, qr: false, status: 'pending'} : undefined;
            return {
                ...o, agreement
            };
        });
        if (this.data.orders.find(o => !!o.agreement)) {
            this.agreementsChecking = true;
        }
    }

    private allDocsAreSigned(): boolean {
        return !!this.data.orders.filter(o => !!o.agreement).filter(
            (o) => o.agreement.documentId && o.agreement.status === 'pending',
        ).length && this.data.purchase ? this.data.purchase.signed : true;
    }

    onCatalogClick(): void {
        if (!this.allDocsAreSigned()) {
            const exit = confirm(
                'Do you really want to leave before the document has been signed?',
            );
            if (!exit) {
                return;
            }
        }
        this.dialogRef.close({action: 'Catalog'});
    }

    onStayClick(): void {
        if (!this.allDocsAreSigned()) {
            const exit = confirm(
                'Do you really want to leave before the document has been signed?',
            );
            if (!exit) {
                return;
            }
        }
        this.dialogRef.close({action: 'back'});
    }

    showLinkQr(item): void {
        item.qr = !item.qr;
    }

    showTeliaPayment(): void {
        this.showTeliaPurchase(this.data.purchase.purchaseRequestToken);
    }

    private showTeliaPurchase(purchaseRequestToken: string) {
        window.open(this.providers.telia.getPaymentLink(purchaseRequestToken), '_blank');
    }

    get purchaseLink():string {
        return this.providers.telia.getPaymentLink(this.data?.purchase?.purchaseRequestToken)
    }

    get hasTeliaPayment(): boolean {
        return this.data.purchase && [PROVIDER_CODES.TELIA, PROVIDER_CODES.HALEBOP].includes(this.data.provider.provider.code);
    }

    private checkOrdersAgreementStatus() {
        this.agreementRequestTimer = timer(0, 2000)
            .pipe(
                takeUntil(this.destroyStream$))
            .subscribe(() => {
                const orders = this.data.orders
                    .filter((o) => o.agreement)
                    .filter((o) => o.agreement.documentId && o.agreement.status === 'pending')
                    .map((o) => o.orderIds[0]);
                if (!orders.length) {
                    this.agreementsChecking = false;
                    this.agreementRequestTimer.unsubscribe();
                    this.sendWebhallenRequests();
                    return;
                }
                this.orders
                    .checkOrdersDocumentStatus(orders)
                    .subscribe(
                        (orderStatuses: Array<{ orderId: number; status: string; providerOrderId?: string; errorMessage?: string }>) => {
                            orderStatuses.forEach((os) => {
                                if (os.status === 'error') {
                                    this.toasts.showToast('Error', os.errorMessage, EventTypes.Error);
                                    this.ordersError = true;
                                }
                                const order = this.data.orders.find((o) => o.orderIds[0] === os.orderId);
                                    order.agreement.status = os.status;
                                    order.agreement.providerOrderId = os.providerOrderId;
                                    order.agreement.errorMessage = os.errorMessage;
                            });
                        },
                    );
                return;
            });
    }

    private checkPaymentStatus() {
        if (!this.data.purchase) {
            return;
        }
        this.paymentChecking = true;
        this.paymentRequestTimer = timer(0, 2000)
            .pipe(
                takeUntil(this.destroyStream$))
            .subscribe(() => {
                this.providers.telia.checkPayment(this.data.orders[0].orderIds[0])
                    .subscribe(
                        (res: { signed: boolean }) => {
                            if (res.signed) {
                                this.data.purchase.signed = true;
                                this.paymentChecking = false;
                                this.paymentRequestTimer.unsubscribe();
                            }
                        },
                    );
                return;
            });
    }

    private sendWebhallenRequests() {
        if (LocalStorageService.get('headquarter').id === HEADQUARTERS.WEBHALLEN) {
            const orderIds = this.data.orders.map(o => o.orderIds).reduce((acc, val) => acc.concat(val), []);
            for (const order of orderIds) {
                this.orders.sendOrderToWebhallen(order).subscribe(res => {
                    this.toasts.showToast('Webhallen order', `Webhallen order: ${res.order_id} created`, EventTypes.Success, 36e+6);
                });
            }
        }
    }

    getPreviousOrders(): void {
        this.app.previousOrders$.subscribe(orders => {
            this.email = orders[0].customer.email;
        });
    }

    sendEmail(link: string) {
        const email = this.email;
        this.providers
            .sendScriveLinkByEmail(link, email)
            .subscribe(() => {
                this.toasts.showToast(this.translate.instant('CATALOG.CHECKOUT.FINISH.EMAIL_SENT'), '', EventTypes.Success);
            });
    }

    copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    ngOnDestroy() {
        this.destroyStream$.next();
        this.destroyStream$.complete();
    }
}
