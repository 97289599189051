import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {LocalStorageService} from "../../../services/local-storage.service";
import { environment } from "../../../../../environments/environment";

export interface Locale {
  lang: string;
  // tslint:disable-next-line:ban-types
  data: Object;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private langIds: any = [];

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en', 'se', 'hu']);

    if (this.translate.getDefaultLang()) {
      this.translate.setDefaultLang(this.translate.getDefaultLang());
    } else {
      this.translate.setDefaultLang(environment.locale);
    }
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  setLanguage(lang) {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      LocalStorageService.save('language', lang);
    }
  }

  getSelectedLanguage(): any {
    return LocalStorageService.get('language') || this.translate.getDefaultLang();
  }
}
