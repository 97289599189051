import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, withLatestFrom } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action, select } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { HeadquartersService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
  HeadquarterActionTypes,
  HeadquartersPageRequested,
  HeadquartersPageLoaded,
  ManyHeadquartersDeleted,
  OneHeadquarterDeleted,
  HeadquartersPageToggleLoading,
  HeadquartersStatusUpdated,
  HeadquarterUpdated,
  HeadquarterCreated,
  HeadquarterOnServerCreated,
} from '../_actions/headquarter.actions';
import { defer, Observable, of } from 'rxjs';
import { currentUser } from '../../auth';

@Injectable()
export class HeadquarterEffects {
  showPageLoadingDistpatcher = new HeadquartersPageToggleLoading({
    isLoading: true,
  });
  showLoadingDistpatcher = new HeadquartersPageToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new HeadquartersPageToggleLoading({
    isLoading: false,
  });

  loadHeadquartersPage$  = createEffect(() => this.actions$.pipe(
    ofType<HeadquartersPageRequested>(
      HeadquarterActionTypes.HeadquartersPageRequested,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showPageLoadingDistpatcher);
      const requestToServer = this.headquartersService.findHeadquarters(
        payload.page,
      );
      const lastQuery = of(payload.page);
      return forkJoin([requestToServer, lastQuery]);
    }),
    withLatestFrom(this.store.pipe(select(currentUser))),
    map(([response, user]) => {
      const result: QueryResultsModel = response[0];
      const lastQuery: QueryParamsModel = response[1];
      const filteredItems = user.roles.some(
        (i) => i.name === 'ROLE_VODAFONE_ADMIN',
      )
        ? result.items.filter((i) => i.id === user.headquarterId)
        : result.items;

      return new HeadquartersPageLoaded({
        headquarters: filteredItems,
        totalCount: result.totalCount,
        page: lastQuery,
      });
    }),
  ));

  deleteHeadquarter$  = createEffect(() => this.actions$.pipe(
    ofType<OneHeadquarterDeleted>(HeadquarterActionTypes.OneHeadquarterDeleted),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.headquartersService.deleteHeadquarter(payload.id);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  deleteHeadquarters$  = createEffect(() => this.actions$.pipe(
    ofType<ManyHeadquartersDeleted>(
      HeadquarterActionTypes.ManyHeadquartersDeleted,
    ),
    mergeMap(({ payload }) => {
      // debugger;
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.headquartersService.deleteHeadquarters(payload.ids);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  updateHeadquartersStatus$  = createEffect(() => this.actions$.pipe(
    ofType<HeadquartersStatusUpdated>(
      HeadquarterActionTypes.HeadquartersStatusUpdated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.headquartersService.updateStatusForHeadquarter(
        payload.headquarters,
        payload.status,
      );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  updateHeadquarter$  = createEffect(() => this.actions$.pipe(
    ofType<HeadquarterUpdated>(HeadquarterActionTypes.HeadquarterUpdated),
    mergeMap(({ payload }) => {
        this.store.dispatch(this.showLoadingDistpatcher);
      return this.headquartersService.updateHeadquarter(payload.headquarter);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  createHeadquarter$  = createEffect(() => this.actions$.pipe(
    ofType<HeadquarterOnServerCreated>(
      HeadquarterActionTypes.HeadquarterOnServerCreated,
    ),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.headquartersService
        .createHeadquarter(payload.headquarter)
        .pipe(
          tap((res) => {
            this.store.dispatch(new HeadquarterCreated({ headquarter: res }));
          }),
        );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  // @Effect()
  // init$: Observable<Action> = defer(() => {
  //     const queryParams = new QueryParamsModel({});
  //     return of(new HeadquartersPageRequested({ page: queryParams }));
  // });

  constructor(
    private actions$: Actions,
    private headquartersService: HeadquartersService,
    private store: Store<AppState>,
  ) {}
}
