import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService, AuthService } from '../../../../core/auth';

@Component({
  selector: 'kt-forgot-password',
  templateUrl: './forgot-password.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: UntypedFormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private authService: AuthService,
    public authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.initRegistrationForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next({});
    this.unsubscribe.complete();
    this.loading = false;
  }

  initRegistrationForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
    });
  }

  submit() {
    const controls = this.forgotPasswordForm.controls;
    if (this.forgotPasswordForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }

    this.loading = true;

    const email = controls['email'].value;
    this.authService
      .forgotPassword(email)
      .pipe(
        tap((response) => {
          if (response === null) {
            console.log(response);
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.FORGOT.SENT'),
              'success',
            );
          } else {
            this.authNoticeService.setNotice(
              this.translate.instant('AUTH.VALIDATION.NOT_FOUND', {
                name: this.translate.instant('AUTH.INPUT.EMAIL'),
              }),
              'danger',
            );
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }
}
