import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../../../../core/catalog/_services/users.service';
import {HeadquarterStoresService} from '../../../../core/headquarter';

@Component({
    selector: 'kt-store-changing-dialog',
    templateUrl: './store-changing-dialog.component.html',
    styleUrls: ['./store-changing-dialog.component.scss']
})
export class StoreChangingDialogComponent implements OnInit {
    _stores = [];
    constructor(
        public dialogRef: MatDialogRef<StoreChangingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            headquarterId: number,
            selectedStoreId: number
        },
        private readonly users: UsersService,
        private readonly headquarter: HeadquarterStoresService,
    ) {}

    ngOnInit() {
        this.getStores();
    }

    private getStores(): void {
        this.headquarter.storesDictionary(this.data.headquarterId).subscribe((stores) => {
            this._stores = stores;
        });
    }

    selectStore(): void {
        this.users.setStore(this.data.selectedStoreId).subscribe(() => {
            this.dialogRef.close();
        });
    }

}
