<div class="auth">
  <div class="auth__aside">
    <h3 class="auth__title">Verticula</h3>
  </div>

  <div class="auth__main">
    <div class="auth__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
