// Angular
import { Component } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginPopupComponent } from './../../../../../views/partials/layout/login-popup/login-popup.component';
import { Logout, Login } from '../../../../../core/auth/_actions/auth.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {LocalStorageService} from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private storeDevices: Store<AppState>,
  ) {}

  public login() {
    const dialogRef = this.dialog.open(LoginPopupComponent, {
      width: '400px',
      disableClose: false,
      data: {
        successLogin: false,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.successLogin) {
        this.storeDevices.dispatch(
          new Login({
            authToken: res.token,
            refreshToken: res.refresh,
          }),
        );
        this.router.navigateByUrl('/dashboard/main');
      }
    });
  }
}
