<div class="kt-login__body">
    <div class="kt-login__form">
        <div class="kt-login__title">
            <h3>Log In</h3>
        </div>
        <form [formGroup]="loginForm" autocomplete="off" class="kt-form">
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
                    <input
                        autocomplete="off"
                        formControlName="email"
                        matInput
                        placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
                        type="email"
                    />
                    <mat-error *ngIf="isControlHasError('email', 'required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email', 'email')">
                        <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email', 'minLength')">
                        <strong
                        >{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong
                        >
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email', 'maxLength')">
                        <strong
                        >{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong
                        >
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                    <input
                        autocomplete="off"
                        formControlName="password"
                        matInput
                        placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
                        type="password"
                    />
                    <mat-error *ngIf="isControlHasError('password', 'required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password', 'minLength')">
                        <strong
                        >{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong
                        >
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password', 'maxLength')">
                        <strong
                        >{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong
                        >
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="kt-login__server-error">
                <p *ngFor="let error of errors">
                    {{ 'AUTH.SERVER_ERRORS.' + error | translate }}
                </p>
            </div>
            <div class="kt-login__actions">
                <button (click)="submit()"
                        color="primary"
                        id="kt_login_signin_submit"
                        mat-raised-button>
                    {{ 'AUTH.LOGIN.BUTTON' | translate }}
                </button>
                <button (click)="logout()"
                        color="primary"
                        id="kt_login_logout"
                        mat-raised-button>
                    {{ 'AUTH.LOGOUT.BUTTON' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>
