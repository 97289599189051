import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HeadquarterProviderModel, ProviderService } from '../catalog';
import {map, take} from 'rxjs/operators';
import {PROVIDER_CODES} from "../catalog/_models/headquarterProviderModel";

@Injectable({
    providedIn: 'root',
})
export class FlowService {
    private manualFlowSelected: boolean = true;
    private providerConnected$ = new BehaviorSubject(false);
    private providers: Array<HeadquarterProviderModel> = [];
    private _selectedProvider: HeadquarterProviderModel;

    constructor(private readonly providersService: ProviderService) {
        this.init().pipe(take(1)).subscribe();
    }

    private _hasProviderConnection: boolean = false;

    get hasProviderConnection(): boolean {
        return this._hasProviderConnection;
    }

    set manualFlow(mode: boolean) {
        this.manualFlowSelected = mode;
        this.providerConnected$.next(this._hasProviderConnection && !this.manualFlowSelected);
    }

    get selectedProvider(): HeadquarterProviderModel {
        return this._selectedProvider;
    }

    get providerConnectedOnly(): boolean {
        return this._selectedProvider.providerConnectedOnly;
    }

    set selectProvider(provider: HeadquarterProviderModel) {
        if (!this.providers.length || !this.providers.find((p: HeadquarterProviderModel) => p.id === +provider.id)) {
        this.init().subscribe(() => {
            this._selectedProvider = this.providers.find((p: HeadquarterProviderModel) => p.id === +provider.id);
            this._hasProviderConnection = this._selectedProvider?.providerConnected;
            this.manualFlowSelected = !this._selectedProvider?.providerConnected;
            this.providerConnected$.next(this._hasProviderConnection && !this.manualFlowSelected);
        });
        } else {
            this._selectedProvider = this.providers.find((p: HeadquarterProviderModel) => p.id === +provider.id);
            this._hasProviderConnection = this._selectedProvider.providerConnected;
            this.manualFlowSelected = !this._selectedProvider.providerConnected;
            this.providerConnected$.next(this._hasProviderConnection && !this.manualFlowSelected);
        }
    }

    get providerConnectedMode(): boolean {
        return this.providerConnected$.value;
    }

    get deliveryRequired(): boolean {
        return [PROVIDER_CODES.TELIA, PROVIDER_CODES.HALEBOP, PROVIDER_CODES.TELE2].includes(this._selectedProvider?.provider.code);
    }

    get haveEAN(): boolean {
        return [PROVIDER_CODES.HALEBOP, PROVIDER_CODES.TELIA].includes(
            this._selectedProvider?.provider.code,
        );
    }

    init(): Observable<any> {
        this.manualFlowSelected = true;
        this._hasProviderConnection = false;
        return this.providersService.getOnlyProviders(LocalStorageService.get('headquarter').id, LocalStorageService.get('typeId')).pipe(map((providers: Array<HeadquarterProviderModel>) => {
                this.providers = providers;
                return providers;
            },
        ));
    }
}
