import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HeadquartersService } from '../../../../../../../core/headquarter';
import { HeadquarterProviderModel } from '../../../../../../../core/catalog';
import { MatTableDataSource } from '@angular/material/table';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../../core/_base/crud';

@Component({
  selector: 'kt-provider-calc',
  templateUrl: './provider-calc.component.html',
  styleUrls: ['./provider-calc.component.scss'],
})
export class ProviderCalcComponent implements OnInit {
  @Input()
  public headquarterId$: number;

  public providersList: HeadquarterProviderModel[] = [];
  public displayedColumns = [
    'provider',
    'calculationModel',
    'ask',
    'modify',
    'removeManualPrice',
  ];
  public dataSource: MatTableDataSource<HeadquarterProviderModel>;

  public currentChangeId: number = null;

  public currentAsk: boolean;

  public selectedProviderCalcModel: number;

  public calculationModels = [
    {
      name: '1st calculation model',
      number: 1,
    },
    {
      name: '2nd calculation model',
      number: 2,
    },
    {
      name: '3rd calculation model',
      number: 3,
    },
    {
      name: '4th calculation model',
      number: 4,
    },
    {
      name: '5th calculation model',
      number: 5,
    },
  ];

  constructor(
    private headquarterService: HeadquartersService,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    this.getProviders();
  }

  getProviders() {
    this.headquarterService
      .getProvidersList(this.headquarterId$)
      .subscribe((res: any) => {
        this.providersList = res;
        this.dataSource = new MatTableDataSource(res.items);
        this.cdr.detectChanges();
      });
  }

  changeProvider(provider: any) {
    this.currentChangeId = provider.id;
    this.selectedProviderCalcModel = provider.calculationModel;
    this.currentAsk = provider.askIsEmployedAtAuthorityPosition || false;
  }

  updateProvider(provider: any) {
    this.headquarterService
      .modifyProviderCalcModel(
        this.headquarterId$,
        provider.id,
        this.selectedProviderCalcModel,
        this.currentAsk,
      )
      .subscribe((res) => {
        this.currentChangeId = null;
        this.getProviders();
      });
  }

  cancelProvider() {
    this.currentChangeId = null;
  }

  removeManualPrice(providerId: number) {
    this.headquarterService
      .removeManualPriceByProvider(providerId)
      .subscribe((res) => {
        this.layoutUtilsService.showActionNotification(
          'Manual prices were successfully deleted',
          MessageType.Create,
          10000,
          true,
        );
      });
  }
}
