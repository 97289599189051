import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel,} from '../../_base/crud';
import {HeadquarterStoreModel} from '../_models/headquarter-store.model';
import {environment} from '../../../../environments/environment';
import {StoreModel} from '../../store';

const API_HEADQUARTER_URL = `${environment.api_url}/headquarter`;
const API_STORE_URL = `${environment.api_url}/store`;

@Injectable()
export class HeadquarterStoresService {
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    createHeadquarterStore(store: StoreModel): Observable<HeadquarterStoreModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<HeadquarterStoreModel>(`${API_STORE_URL}`, store, {
            headers: httpHeaders,
        });
    }

    findHeadquarterStores(
        queryParams: QueryParamsModel,
        headquarterId: number,
    ): Observable<QueryResultsModel> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        const url = `${API_HEADQUARTER_URL}/${headquarterId}/stores`;
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams,
        });
    }

    // UPDATE => PUT: update the headquarter store on the server
    updateHeadquarterSpec(
        headquarterSpec: HeadquarterStoreModel,
    ): Observable<any> {
        return this.http.put(API_HEADQUARTER_URL, headquarterSpec, {
            headers: this.httpUtils.getHTTPHeaders(),
        });
    }

    // DELETE => delete the headquarter store from the server
    deleteHeadquarterStore(
        headquarterId: number,
        storeId: number,
    ): Observable<any> {
        const url = `${API_HEADQUARTER_URL}/${headquarterId}/store/${storeId}`;
        return this.http.delete<HeadquarterStoreModel>(url);
    }

    deleteHeadquarterStores(ids: number[] = []): Observable<any> {
        const url = API_HEADQUARTER_URL + '/deleteHeadquarterStores';
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {headquarterStoreIdsForDelete: ids};
        return this.http.put<any>(url, body, {headers: httpHeaders});
    }

    storesDictionary(
        headquarterId: number,
    ): Observable<Array<Partial<StoreModel>>> {
        return this.http.get<Array<Partial<StoreModel>>>(
            `${environment.api_url}/dictionary/stores?headquarterId=${headquarterId}`,
        );
    }
}
