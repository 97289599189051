import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeadquarterModel } from '../../../../../../../core/headquarter';

@Component({
  selector: 'kt-product-settings',
  templateUrl: './product-settings.component.html',
})
export class ProductSettingsComponent implements OnInit {
  @Input() headquarter: HeadquarterModel;
  @Output() getModule = new EventEmitter<boolean>();

  isCampaignModule: boolean;
  isStaffBonusModule: boolean;
  isFocusContractMode: boolean;
  isHideCustomerInfo: boolean;
  isEnableSalesDiscount: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isCampaignModule = this.headquarter.campaignModule;
    this.isStaffBonusModule = this.headquarter.staffBonusModule;
    this.isFocusContractMode = this.headquarter.focusContractMode;
    this.isHideCustomerInfo = this.headquarter.hideCustomerInfo;
    this.isEnableSalesDiscount = this.headquarter.enableSalesDiscount;
  }

  public changeMode(name: string) {
    let module;
    if (name === 'campaignModule') {
      this.isCampaignModule = !this.isCampaignModule;
      module = { name, value: this.isCampaignModule };
    }
    if (name === 'staffBonusModule') {
      this.isStaffBonusModule = !this.isStaffBonusModule;
      module = { name, value: this.isStaffBonusModule };
    }
    if (name === 'focusContractMode') {
      this.isFocusContractMode = !this.isFocusContractMode;
      module = { name, value: this.isFocusContractMode };
    }
    if (name === 'hideCustomerInfo') {
      this.isHideCustomerInfo = !this.isHideCustomerInfo;
      module = { name, value: this.isHideCustomerInfo };
    }
    if (name === 'enableSalesDiscount') {
      this.isEnableSalesDiscount = !this.isEnableSalesDiscount;
      module = { name, value: this.isEnableSalesDiscount };
    }
    this.getModule.emit(module);
  }
}
