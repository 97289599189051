import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { SubheaderService } from '../../../../../../core/_base/layout';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../../core/_base/crud';
import { HeadquarterModel, HeadquartersDataSource, HeadquartersPageRequested, HeadquartersStatusUpdated, ManyHeadquartersDeleted, selectHeadquartersPageLastQuery } from '../../../../../../core/headquarter';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'kt-headquarters-list',
    templateUrl: './headquarters-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadquartersListComponent implements OnInit, OnDestroy {
    dataSource: HeadquartersDataSource;
    displayedColumns = [/*'select',*/ 'name', 'country', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus: string = '';
    filterCondition: string = '';
    lastQuery: QueryParamsModel;
    canCreateHeadquarter: boolean = false;
    selection = new SelectionModel<HeadquarterModel>(true, []);
    headquartersResult: HeadquarterModel[] = [];
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        private store: Store<AppState>,
        private permissionsService: NgxPermissionsService,
    ) {
    }

    ngOnInit() {
        const roleSubscription = this.permissionsService.permissions$.subscribe(
            (permissions) => {
                this.canCreateHeadquarter = permissions['ROLE_SUPER_ADMIN']
                    ? true
                    : false;
            },
        );
        this.subscriptions.push(roleSubscription);

        const sortSubscription = this.sort.sortChange.subscribe(
            () => (this.paginator.pageIndex = 0),
        );
        this.subscriptions.push(sortSubscription);

        const paginatorSubscriptions = merge(
            this.sort.sortChange,
            this.paginator.page,
        )
            .pipe(tap(() => this.loadHeadquartersList()))
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        const searchSubscription = fromEvent(
            this.searchInput.nativeElement,
            'keyup',
        )
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.loadHeadquartersList();
                }),
            )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        this.subheaderService.setTitle('Headquarters');

        this.dataSource = new HeadquartersDataSource(this.store);

        const entitiesSubscription = this.dataSource.entitySubject
            .pipe(skip(1), distinctUntilChanged())
            .subscribe((res) => {
                this.headquartersResult = res;
            });
        this.subscriptions.push(entitiesSubscription);

        const lastQuerySubscription = this.store
            .pipe(select(selectHeadquartersPageLastQuery))
            .subscribe((res) => (this.lastQuery = res));
        this.subscriptions.push(lastQuerySubscription);

        const routeSubscription = this.activatedRoute.queryParams.subscribe(
            (params) => {
                if (params.id) {
                    this.restoreState(this.lastQuery, +params.id);
                }

                this.loadHeadquartersList();
            },
        );
        this.subscriptions.push(routeSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((el) => el.unsubscribe());
    }

    loadHeadquartersList() {
        this.selection.clear();
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );
        this.store.dispatch(new HeadquartersPageRequested({ page: queryParams }));
        this.selection.clear();
    }

    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        if (this.filterStatus && this.filterStatus.length > 0) {
            filter.status = +this.filterStatus;
        }

        if (this.filterCondition && this.filterCondition.length > 0) {
            filter.condition = +this.filterCondition;
        }

        filter.model = searchText;

        filter.manufacture = searchText;
        filter.color = searchText;
        filter.VINCode = searchText;
        return filter;
    }

    restoreState(queryParams: QueryParamsModel, id: number) {
        if (!queryParams.filter) {
            return;
        }

        if ('condition' in queryParams.filter) {
            this.filterCondition = queryParams.filter.condition.toString();
        }

        if ('status' in queryParams.filter) {
            this.filterStatus = queryParams.filter.status.toString();
        }

        if (queryParams.filter.model) {
            this.searchInput.nativeElement.value = queryParams.filter.model;
        }
    }

    deleteHeadquarters() {
        const _title: string = 'Headquarters Delete';
        const _description: string =
            'Are you sure to permanently delete selected headquarters?';
        const _waitDesciption: string = 'Headquarters are deleting...';
        const _deleteMessage = 'Selected headquarters have been deleted';

        const dialogRef = this.layoutUtilsService.deleteElement(
            _title,
            _description,
            _waitDesciption,
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }

            const idsForDeletion: number[] = [];
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.selection.selected.length; i++) {
                idsForDeletion.push(this.selection.selected[i].id);
            }
            this.store.dispatch(new ManyHeadquartersDeleted({ ids: idsForDeletion }));
            this.layoutUtilsService.showActionNotification(
                _deleteMessage,
                MessageType.Delete,
            );
            this.selection.clear();
        });
    }

    fetchHeadquarters() {
        // tslint:disable-next-line:prefer-const
        let messages = [];
        this.selection.selected.forEach(() => {
            messages.push({});
        });
        this.layoutUtilsService.fetchElements(messages);
    }

    updateStatusForHeadquarters() {
        const _title = 'Update status for selected headquarters';
        const _updateMessage = 'Status has been updated for selected headquarters';
        const _statuses = [
            { value: 0, text: 'Selling' },
            { value: 1, text: 'Sold' },
        ];
        const _messages = [];

        this.selection.selected.forEach(() => {
            _messages.push({});
        });

        const dialogRef = this.layoutUtilsService.updateStatusForEntities(
            _title,
            _statuses,
            _messages,
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                this.selection.clear();
                return;
            }

            this.store.dispatch(
                new HeadquartersStatusUpdated({
                    status: +res,
                    headquarters: this.selection.selected,
                }),
            );

            this.layoutUtilsService.showActionNotification(
                _updateMessage,
                MessageType.Update,
            );
            this.selection.clear();
        });
    }

    editHeadquarter(id) {
        this.router.navigate(['../edit', id], { relativeTo: this.activatedRoute });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.headquartersResult.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.headquartersResult.forEach((row) => this.selection.select(row));
        }
    }
}
