import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  HttpUtilsService,
  QueryParamsModel,
  QueryResultsModel,
} from '../../_base/crud';
import { Observable } from 'rxjs';

const API_HEADQUARTER_URL = `${environment.api_url}/headquarter`;

@Injectable({
  providedIn: 'root',
})
export class EmployeesRequestsService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  findHeadquarterEmployees(
    queryParams: QueryParamsModel,
    headquarterId?: number,
  ): Observable<QueryResultsModel> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    if (headquarterId) {
      const url = `${API_HEADQUARTER_URL}/${headquarterId}/employee-requests`;
      return this.http.get<QueryResultsModel>(url, {
        headers: httpHeaders,
        params: httpParams,
      });
    } else {
      const url = `${API_HEADQUARTER_URL}/employee-requests`;
      return this.http.get<QueryResultsModel>(url, {
        headers: httpHeaders,
        params: httpParams,
      });
    }
  }

  findStoreEmployees(
    queryParams: QueryParamsModel,
    storeId?: number,
  ): Observable<QueryResultsModel> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    if (storeId) {
      const url = `${environment.api_url}/store/${storeId}/employee-requests`;
      return this.http.get<QueryResultsModel>(url, {
        headers: httpHeaders,
        params: httpParams,
      });
    } else {
      const url = `${environment.api_url}/store/employee-requests`;
      return this.http.get<QueryResultsModel>(url, {
        headers: httpHeaders,
        params: httpParams,
      });
    }
  }

  updateEmployeesStatus(employeesRequests: {
    id: number;
    status: string;
  }): Observable<any> {
    const url = `${API_HEADQUARTER_URL}/employee-request/${employeesRequests.id}`;
    return this.http.patch(
      url,
      { status: employeesRequests.status },
      { headers: this.httpUtils.getHTTPHeaders() },
    );
  }

  updateEmployeesStoreStatus(employeesRequests: {
    id: number;
    status: string;
  }): Observable<any> {
    const url = `${environment.api_url}/store/employee-request/${employeesRequests.id}`;
    return this.http.patch(
      url,
      { status: employeesRequests.status },
      { headers: this.httpUtils.getHTTPHeaders() },
    );
  }
}
