import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { TeliaPurchaseToken } from '../models/telia-purchase-token.model';
import { PersonalData } from '../provider.service';
import { TransferNumber } from "../../_models/action-info.model";
import { ICustomerLoyalty } from '../../../../views/pages/catalog/catalog-details-phone/phone-providers/interfaces/customer-loyalty.interface';

@Injectable({
    providedIn: 'root',
})
export class TeliaProviderService {
    private readonly baseUrl = `${environment.api_url}/providers/telia`;

    constructor(private http: HttpClient) {
    }

    bookNumbers(
        productOfferingCode: string,
        serviceCode: string,
        SSN,
        offeringCategory?,
    ): Observable<any> {
        const body = {
            amount: 10,
            productOfferingCode,
            serviceCode,
            offeringCategory,
        };
        return this.http.post(`${this.baseUrl}/book-number`, body);
    }

    getVAS(): Observable<any[]> {
        return this.http.get<any[]>(`${this.baseUrl}/offerings/vas`);
    }

    getOrderDetails(articleNum: string, ssn: number): Observable<any> {
        return this.http.get<any[]>(
            `${this.baseUrl}/offering/${articleNum}/details/${ssn}`,
        );
    }

    getUserData(personNumber: number): Observable<PersonalData> {
        return this.http.get<PersonalData>(`${this.baseUrl}/instance/${personNumber}`);
    }

    initiateFinancePayment(orderIds: number[]): Observable<TeliaPurchaseToken> {
        return this.http.get<TeliaPurchaseToken>(`${this.baseUrl}/merchant/payment/initiate?orderIds=${orderIds}`);
    }

    checkPayment(orderId: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/merchant/payment/${orderId}/check/`);
    }

    getPaymentLink(token: string): string {
        return `${this.baseUrl}/merchant/payment/${token}`;
    }

    sendTransferNumber(transfer: TransferNumber, isSubscriptionIds: boolean) {
        return this.http.post(`${this.baseUrl}/transfer-number${isSubscriptionIds ? '/v2' : ''}`, transfer)
    }

    getTransferNumber(id: number) {
        return this.http.get(`${this.baseUrl}/transfer-number/status/${id}`)
    }
    getLoyalty(ssn: string): Observable<ICustomerLoyalty> {
        return this.http.get<ICustomerLoyalty>(
            `${this.baseUrl}/loyalty/${ssn}`,
        );
    }
}
