import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { StoresService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
  StoreActionTypes,
  StoresPageRequested,
  StoresPageLoaded,
  ManyStoresDeleted,
  OneStoreDeleted,
  StoresPageToggleLoading,
  StoresStatusUpdated,
  StoreUpdated,
  StoreCreated,
  StoreOnServerCreated,
} from '../_actions/store.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class StoreEffects {
  showPageLoadingDistpatcher = new StoresPageToggleLoading({ isLoading: true });
  showLoadingDistpatcher = new StoresPageToggleLoading({ isLoading: true });
  hideActionLoadingDistpatcher = new StoresPageToggleLoading({
    isLoading: false,
  });

  loadStoresPage$  = createEffect(() => this.actions$.pipe(
    ofType<StoresPageRequested>(StoreActionTypes.StoresPageRequested),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showPageLoadingDistpatcher);
      const requestToServer = this.storesService.findStores(payload.page);
      const lastQuery = of(payload.page);
      return forkJoin(requestToServer, lastQuery);
    }),
    map((response) => {
      const result: QueryResultsModel = response[0];
      const lastQuery: QueryParamsModel = response[1];
      return new StoresPageLoaded({
        stores: result.items,
        totalCount: result.totalCount,
        page: lastQuery,
      });
    }),
  )
  );

  deleteStore$  = createEffect(() => this.actions$.pipe(
    ofType<OneStoreDeleted>(StoreActionTypes.OneStoreDeleted),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.storesService.deleteStore(payload.id);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  deleteStores$  = createEffect(() => this.actions$.pipe(
    ofType<ManyStoresDeleted>(StoreActionTypes.ManyStoresDeleted),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.storesService.deleteStores(payload.ids);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  updateStoresStatus$  = createEffect(() => this.actions$.pipe(
    ofType<StoresStatusUpdated>(StoreActionTypes.StoresStatusUpdated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.storesService.updateStatusForStore(
        payload.stores,
        payload.status,
      );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  updateStore$  = createEffect(() => this.actions$.pipe(
    ofType<StoreUpdated>(StoreActionTypes.StoreUpdated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.storesService.updateStore(payload.store);
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  createStore$  = createEffect(() => this.actions$.pipe(
    ofType<StoreOnServerCreated>(StoreActionTypes.StoreOnServerCreated),
    mergeMap(({ payload }) => {
      this.store.dispatch(this.showLoadingDistpatcher);
      return this.storesService.createStore(payload.store).pipe(
        tap((res) => {
          this.store.dispatch(new StoreCreated({ store: res }));
        }),
      );
    }),
    map(() => {
      return this.hideActionLoadingDistpatcher;
    }),
  ));

  // @Effect()
  // init$: Observable<Action> = defer(() => {
  //     const queryParams = new QueryParamsModel({});
  //     return of(new StoresPageRequested({ page: queryParams }));
  // });

  constructor(
    private actions$: Actions,
    private storesService: StoresService,
    private store: Store<AppState>,
  ) {}
}
