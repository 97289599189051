// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { StoresState } from '../_reducers/store.reducers';
import { StoreModel } from '../_models/store.model';

export const selectStoresState = createFeatureSelector<StoresState>('stores');

export const selectStoreById = (storeId: number) =>
  createSelector(
    selectStoresState,
    (storesState) => storesState.entities[storeId],
  );

export const selectStoresPageLoading = createSelector(
  selectStoresState,
  (storesState) => storesState.listLoading,
);

export const selectStoresActionLoading = createSelector(
  selectStoresState,
  (customersState) => customersState.actionsloading,
);

export const selectStoresPageLastQuery = createSelector(
  selectStoresState,
  (storesState) => storesState.lastQuery,
);

export const selectLastCreatedStoreId = createSelector(
  selectStoresState,
  (storesState) => storesState.lastCreatedStoreId,
);

export const selectStoresInitWaitingMessage = createSelector(
  selectStoresState,
  (storesState) => storesState.showInitWaitingMessage,
);

export const selectStoresInStore = createSelector(
  selectStoresState,
  (storesState) => {
    const items: StoreModel[] = [];
    each(storesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: StoreModel[] = httpExtension.sortArray(
      items,
      storesState.lastQuery.sortField,
      storesState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, storesState.totalCount, '');
  },
);

export const selectHasStoresInStore = createSelector(
  selectStoresInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  },
);
