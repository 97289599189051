import { Action } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import {
  EmployeesRequestsModel,
  EmployeesStoreRequestsModel,
} from '../_models/employees-requests.model';

export enum EmployeesStoreRequestsActionTypes {
  EmployeesStoreRequestsUpdated = '[Edit Store Employee Requests Dialog] Store Employee Updated',
  EmployeesStoreRequestsPageRequested = '[ Employees Store Requests List Page] Store Employees Page Requested',
  EmployeesStoreRequestsPageLoaded = '[ Employees Store Requests API] Store Employees Page Loaded',
  OneEmployeesStoreRequestsDeleted = '[ Employee Store Delete]  One Requests Employee Store Deleted',
  EmployeesStoreRequestsPageToggleLoading = '[ Employees Store Requests] Store Employees Page Toggle Loading',
}

export class EmployeesStoreRequestsUpdated implements Action {
  readonly type =
    EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsUpdated;

  constructor(
    public payload: { employeeRequests: { id: any; status: string } },
  ) {}
}

export class OneEmployeesStoreRequestsDeleted implements Action {
  readonly type =
    EmployeesStoreRequestsActionTypes.OneEmployeesStoreRequestsDeleted;
  constructor(public payload: number) {}
}

export class EmployeesStoreRequestsPageRequested implements Action {
  readonly type =
    EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageRequested;
  constructor(public payload: { page: QueryParamsModel; storeId?: number }) {}
}

export class EmployeesStoreRequestsPageLoaded implements Action {
  readonly type =
    EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageLoaded;
  constructor(
    public payload: {
      employeesRequests: EmployeesStoreRequestsModel[];
      totalCount: number;
    },
  ) {}
}

export class EmployeesStoreRequestsPageToggleLoading implements Action {
  readonly type =
    EmployeesStoreRequestsActionTypes.EmployeesStoreRequestsPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type EmployeesStoreRequestsActions =
  | EmployeesStoreRequestsUpdated
  | EmployeesStoreRequestsPageRequested
  | EmployeesStoreRequestsPageLoaded
  | OneEmployeesStoreRequestsDeleted
  | EmployeesStoreRequestsPageToggleLoading;
