// RxJS
import { debounceTime } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
  selectHeadquarterStoresInStore,
  selectHeadquarterStoresPageLoading,
  selectPSShowInitWaitingMessage,
} from '../_selectors/headquarter-store.selectors';
export class HeadquarterStoresDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.store
      .pipe(select(selectHeadquarterStoresInStore), debounceTime(600))
      .subscribe((response: QueryResultsModel) => {
        this.entitySubject.next(response.items);
        this.paginatorTotalSubject.next(response.totalCount);
      });

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectPSShowInitWaitingMessage),
    );

    this.loading$ = this.store.pipe(select(selectHeadquarterStoresPageLoading));
  }
}
