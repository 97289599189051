<kt-portlet>
  <kt-portlet-header
    [title]="'New headquarter'"
    [class]="'kt-portlet__head--lg'"
  >
    <ng-container ktPortletTools>
      <button
        (click)="back()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Back to the headquarters list"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">Back</span>
      </button>
      <button
        *ngxPermissionsOnly="['ROLE_SUPER_ADMIN']"
        class="btn btn-primary kt-margin-r-10"
        mat-raised-button
        matTooltip="Save & Continue"
        [disabled]="headquarterForm.invalid"
        color="primary"
        (click)="addHeadquarter(headquarterForm.value)"
      >
        <span class="kt-hidden-mobile">Save</span>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <div>
      <!--begin::Form-->
      <form
        [formGroup]="headquarterForm"
        class="kt-form kt-form--group-seperator-dashed"
      >
        <div class="kt-form__section kt-form__section--first">
          <div class="kt-form__group">
            <div class="row">
              <div class="col-md-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Enter Name"
                    formControlName="name"
                  />
                  <mat-error
                    *ngIf="headquarterForm.get('name').hasError('required')"
                  >
                    Name is <strong>required</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="headquarterForm.get('name').hasError('pattern')"
                  >
                    Name contains <strong>invalid</strong> characters
                  </mat-error>
                  <mat-hint>Please enter <strong>Name</strong> </mat-hint>
                </mat-form-field>
              </div>
              <div class="col-lg-4 kt-margin-bottom-20-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <ng-template #rt let-r="result" let-t="term">
                    {{ r.name }}
                  </ng-template>
                  <span *ngIf="searching">searching...</span>
                  <input
                    matInput
                    formControlName="country"
                    placeholder="Enter country name"
                    type="text"
                    [ngbTypeahead]="searchCountry"
                    [resultTemplate]="rt"
                    [inputFormatter]="formatter"
                  />
                  <!-- <mat-error *ngIf="isControlHasError('country','required')">
                        <strong>Country is required</strong>
                      </mat-error> -->
                </mat-form-field>
              </div>
              <div class="col-md-4 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Enter GTM key"
                    formControlName="googleTagManagerKey"
                  />
                  <mat-hint>Please enter <strong> GTM key</strong> </mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-3 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Enter VAT"
                    formControlName="VAT"
                  />
                  <mat-hint>Please enter <strong> VAT</strong> </mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-3 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Enter staff bonus"
                    formControlName="staffBonus"
                  />
                  <mat-hint
                    >Please enter <strong> staffBonus</strong>
                  </mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-3 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Enter currency"
                    formControlName="currency"
                  />
                  <mat-hint>Please enter <strong> currency</strong> </mat-hint>
                </mat-form-field>
              </div>
              <div
                class="col-md-3 kt-margin-bottom-10-mobile d-flex align-items-center"
              >
                <mat-checkbox
                  formControlName="bankIDVerification"
                  color="primary"
                  >Allow only BankID verification</mat-checkbox
                >
              </div>
              <div
                class="col-md-3 kt-margin-bottom-10-mobile d-flex align-items-center"
              >
                <mat-checkbox
                  formControlName="enableAnonimization"
                  color="primary"
                  >Enable anonimization</mat-checkbox
                >
              </div>
              <div class="col-md-3 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Finished order anonimization"
                    formControlName="finishedOrdersAnonimization"
                  />
                  <mat-hint>Please enter <strong> days</strong> </mat-hint>
                </mat-form-field>
              </div>
              <div class="col-md-3 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                  <input
                    matInput
                    placeholder="Unfinished order anonimization"
                    formControlName="unfinishedOrdersAnonimization"
                  />
                  <mat-hint>Please enter <strong> days</strong> </mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </kt-portlet-body>
</kt-portlet>
