'use strict';

var toInteger = require('./_to-integer');
var defined = require('./_defined');
module.exports = function repeat(count) {
  var str = String(defined(this));
  var res = '';
  var n = toInteger(count);
  if (n < 0 || n == Infinity) throw RangeError("Count can't be negative");
  for (; n > 0; (n >>>= 1) && (str += str)) if (n & 1) res += str;
  return res;
};