import {Component, Inject, OnDestroy, OnInit,} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
import { AuthService, currentUser, User } from '../../../../core/auth';
import { AuthNoticeService } from '../../../../core/auth';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import {LocalStorageService} from '../../../../core/services/local-storage.service';

interface DialogData {
    successLogin: boolean;
    token: string;
    refresh: string;
    user: any;
}

@Component({
    selector: 'kt-login-popup',
    templateUrl: './login-popup.component.html',
    styleUrls: ['./login-popup.component.scss'],
})
export class LoginPopupComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    loading = false;
    errors: any = [];
    user: User;

    private unsubscribe: Subject<any>;

    constructor(
        private router: Router,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<LoginPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        this.unsubscribe = new Subject();
    }

    ngOnInit(): void {
        this.getCurrentUser();

    }

    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.unsubscribe.next({});
        this.unsubscribe.complete();
        this.loading = false;
    }

    initLoginForm() {
        this.loginForm = this.fb.group({
            email: [
                { value: this.user?.email, disabled: true },
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(320),
                ]),
            ],
            password: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100),
                ]),
            ],
        });
    }

    private getCurrentUser(): void {
        this.store.pipe(select(currentUser),take(1)).subscribe(user => {
            this.user = user;
            this.initLoginForm();
        });
    }

    submit() {
        this.errors = [];
        const controls = this.loginForm.controls;
        if (this.loginForm.invalid) {
            Object.keys(controls).forEach((controlName) =>
                controls[controlName].markAsTouched(),
            );
            return;
        }

        this.loading = true;

        const authData = {
            email: controls['email'].value,
            password: controls['password'].value,
        };
        this.auth
            .login(authData.email, authData.password)
            .pipe(
                tap((response) => {
                    if (response) {
                        LocalStorageService.save(
                            'headquarter',{id: response.user.headquarterId}
                        );
                        setTimeout((_) => {
                            LocalStorageService.save(environment.authTokenKey, response.token);
                            LocalStorageService.save('refresh', response.refreshToken);
                        });
                        this.data.successLogin = true;
                        this.data.refresh = response.refreshToken;
                        this.data.token = response.token;
                        this.data.user = response.user;

                        this.dialogRef.close(this.data);
                    }
                }),
                takeUntil(this.unsubscribe),
            )
            .subscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result =
            control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
    logout() {
        this.dialogRef.close();
    }
}
