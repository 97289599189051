<div style="padding: 20px">
    <h1 mat-dialog-title>{{ 'ORDER.ORDER_DIALOG.CHOOSE_STORE' | translate }}</h1>
    <div mat-dialog-content>
        <mat-form-field class="col-md-12 col-lg-12 col-sm-12">
            <mat-select [(value)]="data.selectedStoreId"
                [placeholder]="'Select store'"
            >
                <mat-option
                    *ngFor="let store of _stores"
                    [value]="store.id"
                >
                    {{ store.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="selectStore()">{{ 'ORDER.ORDER_DIALOG.CONFIRM_STORE' | translate }}</button>
        <button mat-button mat-dialog-close>{{ 'ORDER.ORDER_DIALOG.BTN_CLOSE' | translate }}</button>
    </div>
</div>

