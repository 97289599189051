// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { StoreEmployeeModel } from '../_models/store-employee.model';

export enum StoreEmployeeActionTypes {
  StoreEmployeeOnServerCreated = '[Edit StoreEmployee Dialog] Store Employee On Server Created',
  StoreEmployeeCreated = '[Edit StoreEmployee Dialog] Store Employee Created',
  StoreEmployeeUpdated = '[Edit EmployeeEmployee Dialog] Store Employee Updated',
  OneStoreEmployeeDeleted = '[Store Employee List Page]  One Store Employee Deleted',
  ManyStoreEmployeesDeleted = '[Store Employees List Page] Many Store Employees Deleted',
  StoreEmployeesPageRequested = '[Store Employees List Page] Store Employees Page Requested',
  StoreEmployeesPageLoaded = '[Store Employees API] Store Employees Page Loaded',
  StoreEmployeesPageCancelled = '[Store Employees API] Store Employees Page Cancelled',
  StoreEmployeesPageToggleLoading = '[Store Employees] Store Employees Page Toggle Loading',
}

export class StoreEmployeeOnServerCreated implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeeOnServerCreated;
  constructor(
    public payload: { employeeId: number; storeId: number; role: string },
  ) {}
}

export class StoreEmployeeCreated implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeeCreated;
  constructor(public payload: { storeEmployee: StoreEmployeeModel }) {}
}

export class StoreEmployeeUpdated implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeeUpdated;
  constructor(
    public payload: {
      partialStoreEmployee: Update<StoreEmployeeModel>; // For State update
      storeEmployee: StoreEmployeeModel; // For Server update (through service)
    },
  ) {}
}

export class OneStoreEmployeeDeleted implements Action {
  readonly type = StoreEmployeeActionTypes.OneStoreEmployeeDeleted;
  constructor(public payload: { employeeId: number; storeId: number }) {}
}

export class ManyStoreEmployeesDeleted implements Action {
  readonly type = StoreEmployeeActionTypes.ManyStoreEmployeesDeleted;
  constructor(public payload: { ids: number[] }) {}
}

export class StoreEmployeesPageRequested implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeesPageRequested;
  constructor(public payload: { page: QueryParamsModel; storeId: number }) {}
}

export class StoreEmployeesPageLoaded implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeesPageLoaded;
  constructor(
    public payload: {
      storeEmployees: StoreEmployeeModel[];
      totalCount: number;
    },
  ) {}
}

export class StoreEmployeesPageCancelled implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeesPageCancelled;
}

export class StoreEmployeesPageToggleLoading implements Action {
  readonly type = StoreEmployeeActionTypes.StoreEmployeesPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type StoreEmployeeActions =
  | StoreEmployeeOnServerCreated
  | StoreEmployeeCreated
  | StoreEmployeeUpdated
  | OneStoreEmployeeDeleted
  | ManyStoreEmployeesDeleted
  | StoreEmployeesPageRequested
  | StoreEmployeesPageLoaded
  | StoreEmployeesPageCancelled
  | StoreEmployeesPageToggleLoading;
