// Angular
import { AfterViewInit, Component, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../../services/html-class.service';
import {LayoutConfigService, ToggleOptions} from '../../../../core/_base/layout';

@Component({
  selector: 'kt-brand',
  styles: [
    `
      .kt-aside__brand {
        justify-content: flex-end;
      }
    `,
  ],
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
  headerLogo: string;
  headerStickyLogo: string;

  toggleOptions: ToggleOptions = {
    target: 'body',
    targetState: 'kt-aside--minimize',
    togglerState: 'kt-aside__brand-aside-toggler--active',
  };

  constructor(
    private layoutConfigService: LayoutConfigService,
    public htmlClassService: HtmlClassService,
  ) {}
  ngOnInit(): void {
    this.headerLogo = this.layoutConfigService.getLogo();
    this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
  }
  ngAfterViewInit(): void {}
}
