<div class="kt-form kt-margin-b-30"></div>

<div class="mat-table__wrapper mat-table__wrapper--sub">
  <mat-table class="lmat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="provider">
      <mat-header-cell *matHeaderCellDef class="mat-column"
        >Provider name</mat-header-cell
      >
      <mat-cell *matCellDef="let provider" class="mat-column">{{
        provider.name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="calculationModel">
      <mat-header-cell *matHeaderCellDef class="mat-column"
        >Calculation model</mat-header-cell
      >
      <mat-cell *matCellDef="let provider" class="mat-column">
        <ng-template [ngIf]="currentChangeId !== provider?.id">
          <ng-container [ngSwitch]="provider.calculationModel">
            <ng-container *ngSwitchCase="1">1st</ng-container>
            <ng-container *ngSwitchCase="2">2nd</ng-container>
            <ng-container *ngSwitchCase="3">3rd</ng-container>
            <ng-container *ngSwitchCase="4">4th</ng-container>
            <ng-container *ngSwitchCase="5">5th</ng-container>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="currentChangeId === provider?.id">
          <mat-form-field>
            <mat-select [(value)]="selectedProviderCalcModel">
              <mat-option
                *ngFor="let item of calculationModels"
                [value]="item.number"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ask">
      <mat-header-cell *matHeaderCellDef class="mat-column"
        >Ask is employed</mat-header-cell
      >
      <mat-cell *matCellDef="let provider" class="mat-column">
        <ng-template [ngIf]="currentChangeId !== provider?.id">
          <section class="example-section">
            <mat-checkbox
              class="example-margin"
              [checked]="provider?.askIsEmployedAtAuthorityPosition || false"
              [disabled]="true"
            ></mat-checkbox>
          </section>
        </ng-template>
        <ng-template [ngIf]="currentChangeId === provider?.id">
          <section class="example-section">
            <mat-checkbox
              class="example-margin"
              [checked]="provider.askIsEmployedAtAuthorityPosition || false"
              [(ngModel)]="currentAsk"
            ></mat-checkbox>
          </section>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modify">
      <mat-header-cell
        *matHeaderCellDef
        class="mat-column"
        style="width: 150px; min-width: 150px; max-width: 150px"
      >
        Modify
      </mat-header-cell>
      <mat-cell
        *matCellDef="let provider"
        class="mat-column"
        style="width: 150px; min-width: 150px; max-width: 150px"
      >
        <ng-template [ngIf]="currentChangeId !== provider?.id">
          <button
            mat-raised-button
            color="primary"
            (click)="changeProvider(provider)"
            class="mat-button-mt-4"
          >
            Modify
          </button>
        </ng-template>
        <ng-template [ngIf]="currentChangeId === provider?.id">
          <button
            mat-raised-button
            color="primary"
            (click)="updateProvider(provider)"
            class="mat-button-mt-4"
          >
            Save
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="cancelProvider()"
            class="mat-button-mt-4"
          >
            Cancel
          </button>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="removeManualPrice">
      <mat-header-cell
        *matHeaderCellDef
        class="mat-column"
        style="width: 200px; min-width: 200px; max-width: 200px"
        >Remove manual prices</mat-header-cell
      >
      <mat-cell
        *matCellDef="let provider"
        class="mat-column"
        style="width: 200px; min-width: 200px; max-width: 200px"
      >
        <button
          color="warn"
          class="kt-margin-r-10"
          mat-raised-button
          matTooltip="Save & Continue"
          (click)="removeManualPrice(provider.id)"
        >
          <span class="kt-hidden-mobile">Remove manual prices</span>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <!-- <div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
    <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
   -->
</div>
<!-- start: BOTTOM -->
<div class="mat-table__bottom">
  <!-- <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator> -->
</div>
<!-- end: BOTTOM -->
