import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  resetCheckoutTabs: BehaviorSubject<boolean> = new BehaviorSubject(null);
  constructor() {}
}
