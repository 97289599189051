<div
  class="kt-portlet"
  [ngClass]="{
    'kt-portlet--body-progress': viewLoading,
    'kt-portlet--body-progress-overlay': loadingAfterSubmit
  }"
>
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ data.isNew ? 'Add' : 'Update' }} store
      </h3>
    </div>
  </div>
  <!--begin::Form-->
  <form class="kt-form" [formGroup]="storeEditForm" autocomplete="off">
    <div class="kt-portlet__body">
      <div class="kt-portlet__body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>

      <div class="kt-form__section kt-form__section--first">
        <div class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid">
            <mat-hint align="start"
              >Please enter <strong>Name</strong>
            </mat-hint>
            <input matInput placeholder="Enter Name" formControlName="name" />

            <mat-error
              *ngIf="
                storeEditForm.get('name').touched &&
                storeEditForm.get('name').hasError('required')
              "
            >
              Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="storeEditForm.get('name').hasError('pattern')">
              Name is <strong>invalid</strong>. Field contains forbidden
              characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              placeholder="Enter Address"
              formControlName="address"
            />
            <mat-hint align="start"
              >Please enter <strong>Address</strong>
            </mat-hint>

            <mat-error
              *ngIf="
                storeEditForm.get('address').touched &&
                storeEditForm.get('address').hasError('required')
              "
            >
              Address is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="storeEditForm.get('address').hasError('pattern')">
              Address is <strong>invalid</strong>. Field contains forbidden
              characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Enter Phone" formControlName="phone" />
            <mat-hint align="start"
              >Please enter <strong>Phone</strong>
            </mat-hint>

            <mat-error
              *ngIf="
                storeEditForm.get('phone').touched &&
                storeEditForm.get('phone').hasError('required')
              "
            >
              Phone is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="storeEditForm.get('phone').hasError('pattern')">
              Phone is <strong>invalid</strong> Please enter
              <strong>Phone</strong> in format: +xxxxxxxxx
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group kt-form__group">
          <mat-form-field class="mat-form-field-fluid">
            <input
              matInput
              placeholder="Enter external reference id"
              formControlName="externalReferenceId"
            />
            <mat-hint align="start"
              >Please enter <strong>external reference ID</strong>
            </mat-hint>

            <mat-error
              *ngIf="
                storeEditForm.get('externalReferenceId').touched &&
                storeEditForm.get('externalReferenceId').hasError('required')
              "
            >
              External Reference ID is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="
                storeEditForm.get('externalReferenceId').hasError('pattern')
              "
            >
              ExternalReferenceId is <strong>invalid</strong>. Field contains
              forbidden characters
            </mat-error>
          </mat-form-field>
        </div>
        <ng-container
          *ngIf="data.headquarterConfigurationCode === 'MEDIAMARKTHU'"
        >
          <div class="form-group kt-form__group">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                placeholder="Operator store id"
                formControlName="operatorStoreId"
              />
              <mat-hint align="start"
                >Please enter <strong>Operator store ID</strong>
              </mat-hint>

              <!-- <mat-error *ngIf="storeEditForm.get('operatorStoreId').touched && storeEditForm.get('operatorStoreId').hasError('required')" >
                Operator store ID is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="storeEditForm.get('operatorStoreId').hasError('pattern')" >
                operatorStoreId is <strong>invalid</strong>. Field contains forbidden characters
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="form-group kt-form__group">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                placeholder="Bank Store id"
                formControlName="bankStoreId"
              />
              <mat-hint align="start"
                >Please enter <strong>Bank Store ID</strong>
              </mat-hint>

              <!-- <mat-error *ngIf="storeEditForm.get('bankStoreId').touched && storeEditForm.get('bankStoreId').hasError('required')" >
                Bank Store ID is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="storeEditForm.get('bankStoreId').hasError('pattern')" >
                bankStoreId is <strong>invalid</strong>. Field contains forbidden characters
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="form-group kt-form__group">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                placeholder="Insurance store id"
                formControlName="insuranceStoreId"
              />
              <mat-hint align="start"
                >Please enter <strong>Insurance store ID</strong>
              </mat-hint>

              <!-- <mat-error *ngIf="storeEditForm.get('insuranceStoreId').touched && storeEditForm.get('insuranceStoreId').hasError('required')" >
                Insurance store ID is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="storeEditForm.get('insuranceStoreId').hasError('pattern')" >
                insuranceStoreId is <strong>invalid</strong>. Field contains forbidden characters
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="form-group kt-form__group">
            <mat-form-field class="mat-form-field-fluid">
              <input
                matInput
                placeholder="ticketing store id"
                formControlName="ticketingStoreId"
              />
              <mat-hint align="start"
                >Please enter <strong>ticketing store ID</strong>
              </mat-hint>

              <!-- <mat-error *ngIf="storeEditForm.get('ticketingStoreId').touched && storeEditForm.get('ticketingStoreId').hasError('required')" >
                ticketing store ID is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="storeEditForm.get('ticketingStoreId').hasError('pattern')" >
                ticketingStoreId is <strong>invalid</strong>. Field contains forbidden characters
              </mat-error> -->
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions kt-form__actions--solid">
        <button
          type="button"
          mat-raised-button
          cdkFocusInitial
          (click)="onNoClick()"
          matTooltip="Cancel changes"
        >
          Cancel</button
        >&nbsp;
        <button
          type="submit"
          mat-raised-button
          color="primary"
          (click)="save()"
          [disabled]="storeEditForm.invalid"
          matTooltip="Save changes"
        >
          Save
        </button>
      </div>
    </div>
  </form>
  <!--end::Form-->
</div>
