// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService, QueryParamsModel } from '../../_base/crud';
import { CommentModel, SaveOrderCommentModel } from '../_models/comment.model';
import { environment } from '../../../../environments/environment';

const API_COMMENT_URL = `${environment.api_url}/comments`;

@Injectable({ providedIn: 'root' })
export class OrderCommentService {
  private httpOptions = this.httpUtils.getHTTPHeaders();

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  findCommentsForOrder(
    id: string,
    queryParams: QueryParamsModel,
  ): Observable<CommentModel[]> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

    const url = `${environment.api_url}/order/${id}/comments`;

    return this.http.get<CommentModel[]>(url, {
      headers: httpHeaders,
      params: httpParams,
    });
  }

  getAllComments(id: number): Observable<CommentModel[]> {
    return this.http.get<CommentModel[]>(
      `${environment.api_url}/order/${id}/comments`,
      { headers: this.httpOptions },
    );
  }

  addComment(id: number, comment: SaveOrderCommentModel) {
    return this.http.post<SaveOrderCommentModel>(
      `${environment.api_url}/order/${id}/comment`,
      comment,
      { headers: this.httpOptions },
    );
  }

  // onDelete(comment: CommentModel) {
  // 	return this.http.delete<any>(`${environment.api_url}/order/${comment.id}/comment`);
  // }
}
