// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { HeadquarterStoreModel } from '../_models/headquarter-store.model';
import { StoreModel } from '../../store';

export enum HeadquarterStoreActionTypes {
  HeadquarterStoreOnServerCreated = '[Edit HeadquarterStore Dialog] Headquarter Store On Server Created',
  HeadquarterStoreCreated = '[Edit HeadquarterStore Dialog] Headquarter Store Created',
  HeadquarterStoreUpdated = '[Edit StoreStore Dialog] Headquarter Store Updated',
  OneHeadquarterStoreDeleted = '[Headquarter Store List Page]  One Headquarter Store Deleted',
  ManyHeadquarterStoresDeleted = '[Headquarter Stores List Page] Many Headquarter Stores Deleted',
  HeadquarterStoresPageRequested = '[Headquarter Stores List Page] Headquarter Stores Page Requested',
  HeadquarterStoresPageLoaded = '[Headquarter Stores API] Headquarter Stores Page Loaded',
  HeadquarterStoresPageCancelled = '[Headquarter Stores API] Headquarter Stores Page Cancelled',
  HeadquarterStoresPageToggleLoading = '[Headquarter Stores] Headquarter Stores Page Toggle Loading',
}

export class HeadquarterStoreOnServerCreated implements Action {
  readonly type = HeadquarterStoreActionTypes.HeadquarterStoreOnServerCreated;
  constructor(public payload: { store: StoreModel }) {}
}

export class HeadquarterStoreCreated implements Action {
  readonly type = HeadquarterStoreActionTypes.HeadquarterStoreCreated;
  constructor(public payload: { headquarterStore: HeadquarterStoreModel }) {}
}

export class HeadquarterStoreUpdated implements Action {
  readonly type = HeadquarterStoreActionTypes.HeadquarterStoreUpdated;
  constructor(
    public payload: {
      partialHeadquarterStore: Update<HeadquarterStoreModel>; // For State update
      headquarterStore: HeadquarterStoreModel; // For Server update (through service)
    },
  ) {}
}

export class OneHeadquarterStoreDeleted implements Action {
  readonly type = HeadquarterStoreActionTypes.OneHeadquarterStoreDeleted;
  constructor(public payload: { storeId: number; headquarterId: number }) {}
}

export class ManyHeadquarterStoresDeleted implements Action {
  readonly type = HeadquarterStoreActionTypes.ManyHeadquarterStoresDeleted;
  constructor(public payload: { ids: number[] }) {}
}

export class HeadquarterStoresPageRequested implements Action {
  readonly type = HeadquarterStoreActionTypes.HeadquarterStoresPageRequested;
  constructor(
    public payload: { page: QueryParamsModel; headquarterId: number },
  ) {}
}

export class HeadquarterStoresPageLoaded implements Action {
  readonly type = HeadquarterStoreActionTypes.HeadquarterStoresPageLoaded;
  constructor(
    public payload: {
      headquarterStores: HeadquarterStoreModel[];
      totalCount: number;
    },
  ) {}
}

export class HeadquarterStoresPageCancelled implements Action {
  readonly type = HeadquarterStoreActionTypes.HeadquarterStoresPageCancelled;
}

export class HeadquarterStoresPageToggleLoading implements Action {
  readonly type =
    HeadquarterStoreActionTypes.HeadquarterStoresPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type HeadquarterStoreActions =
  | HeadquarterStoreOnServerCreated
  | HeadquarterStoreCreated
  | HeadquarterStoreUpdated
  | OneHeadquarterStoreDeleted
  | ManyHeadquarterStoresDeleted
  | HeadquarterStoresPageRequested
  | HeadquarterStoresPageLoaded
  | HeadquarterStoresPageCancelled
  | HeadquarterStoresPageToggleLoading;
