/**
 * Выбранный телефон
 */
export class DeviceModel {
  id: number;
  configuration: null;
  stockSalesPrice: number = 0;
  purchasePrice: number = 0;
  articleName: string = ''; // Название девайса
  articleNumber: string = '';
  description: string = ''; // Описание девайса
  image: string = ''; // Картинка
  discount: number = 0; // Скидка
  colors: { name: string; value: string }[];
  bonus: number = 0;
  manufacturerId: number = 0;
  manufacturer: { id: number; name: string }; // производитель
  manufacturerName?: string;
  store: {
    address: string;
    email: string;
    externalReferenceId: string;
    fax: string;
    headquarter: {
      VAT: number;
      configuration: {
        id: number;
        name: string;
        code: string;
      };
      configurationId: number;
      country: DeviceCountryConfig;
      countryId: number;
      googleTagManagerKey: string;
      id: number;
      name: string;
    };
    headquarterId: number;
    id: number;
    location: string;
    name: string;
    phone: string;
    status: string;
  };
  monthlyCost?: number;
  selectDevices?: number;
  salesPriceInclDiscount?: number;
  monthlyCostInclDiscount?: number;

  constructor(init?: Partial<DeviceModel>) {
    Object.assign(this, init);
  }
}

export class DeviceCountryConfig {
  code: string;
  currency: string;
  config: {
    currency: string;
    special_fields: object;
  };
  id: number;
  name: string;
}
