import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './headquarter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadquarterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
