<div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">

  <div></div>
  <div class="kt-header-mobile__toolbar">
    <button
      [hidden]="!asideDisplay"
      class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
      id="kt_aside_mobile_toggler"
    >
      <span></span>
    </button>
    <button class="kt-header-mobile__toggler" id="kt_header_mobile_toggler">
      <span></span>
    </button>
    <button
      ktToggle
      [options]="toggleOptions"
      class="kt-header-mobile__topbar-toggler"
      id="kt_header_mobile_topbar_toggler"
    >
      <i class="flaticon-more"></i>
    </button>
  </div>
</div>
