// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
// Actions
import {
  HeadquarterStoreActions,
  HeadquarterStoreActionTypes,
} from '../_actions/headquarter-store.actions';
// Models
import { HeadquarterStoreModel } from '../_models/headquarter-store.model';
import { QueryParamsModel } from '../../_base/crud';

export interface HeadquarterStoresState
  extends EntityState<HeadquarterStoreModel> {
  headquarterId: number;
  loading: boolean;
  totalCount: number;
  lastCreatedHeadquarterStoreId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<HeadquarterStoreModel> =
  createEntityAdapter<HeadquarterStoreModel>();

export const initialHeadquarterStoresState: HeadquarterStoresState =
  adapter.getInitialState({
    loading: false,
    totalCount: 0,
    headquarterId: undefined,
    lastCreatedHeadquarterStoreId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
  });

export function headquarterStoresReducer(
  state = initialHeadquarterStoresState,
  action: HeadquarterStoreActions,
): HeadquarterStoresState {
  switch (action.type) {
    case HeadquarterStoreActionTypes.HeadquarterStoresPageToggleLoading:
      return {
        ...state,
        loading: action.payload.isLoading,
        lastCreatedHeadquarterStoreId: undefined,
      };
    case HeadquarterStoreActionTypes.HeadquarterStoreOnServerCreated:
      return { ...state, loading: true };
    case HeadquarterStoreActionTypes.HeadquarterStoreCreated:
      return adapter.addOne(action.payload.headquarterStore, {
        ...state,
        lastCreatedHeadquarterStoreId: action.payload.headquarterStore.id,
      });
    case HeadquarterStoreActionTypes.HeadquarterStoreUpdated:
      return adapter.updateOne(action.payload.partialHeadquarterStore, state);
    case HeadquarterStoreActionTypes.OneHeadquarterStoreDeleted:
      return adapter.removeOne(action.payload.storeId, state);
    case HeadquarterStoreActionTypes.ManyHeadquarterStoresDeleted:
      return adapter.removeMany(action.payload.ids, state);
    case HeadquarterStoreActionTypes.HeadquarterStoresPageCancelled:
      return {
        ...state,
        totalCount: 0,
        loading: false,
        headquarterId: undefined,
        lastQuery: new QueryParamsModel({}),
      };
    case HeadquarterStoreActionTypes.HeadquarterStoresPageRequested:
      return {
        ...state,
        totalCount: 0,
        loading: true,
        headquarterId: action.payload.headquarterId,
        lastQuery: action.payload.page,
      };
    case HeadquarterStoreActionTypes.HeadquarterStoresPageLoaded:
      return adapter.addMany(action.payload.headquarterStores, {
        ...initialHeadquarterStoresState,
        totalCount: action.payload.totalCount,
        loading: false,
        headquarterId: state.headquarterId,
        lastQuery: state.lastQuery,
        showInitWaitingMessage: false,
      });
    default:
      return state;
  }
}

export const getHeadquarterRemarlState =
  createFeatureSelector<HeadquarterStoreModel>('headquarterStores');

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
