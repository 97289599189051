// Angular
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OverlayModule} from '@angular/cdk/overlay';
// Perfect Scroll bar
// Env
import {environment} from '../environments/environment';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// State
import {metaReducers, reducers} from './core/reducers';
// Copmponents
import {AppComponent} from './app.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
// Partials
import {PartialsModule} from './views/partials/partials.module';
// Services
// Layout Services
import {
    DataTableService,
    LayoutConfigService,
    LayoutRefService,
    MenuAsideService,
    MenuConfigService,
    MenuHorizontalService,
    PageConfigService,
    SplashScreenService,
    SubheaderService
} from './core/_base/layout';
// Auth
import {AuthModule} from './views/pages/auth/auth.module';
import {AuthService, ModuleGuard,} from './core/auth';
// CRUD
import {HttpUtilsService, LayoutUtilsService, TypesUtilsService,} from './core/_base/crud';
// Config
import * as LogRocket from 'logrocket';
import { StoreChangingDialogComponent } from './views/partials/components/store-changing-dialog/store-changing-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ActionNotificationComponent} from "./views/partials/content/crud";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutConfig } from './core/_config/dashboard/layout.config';

if (environment["logrocketAppId"]) {
    LogRocket.init(environment["logrocketAppId"]);
}

// tslint:disable-next-line:class-name

export function initializeLayoutConfig(
    appConfig: LayoutConfigService,
    http: HttpClient,
) {
    return () => {
        return http
            .get(`${environment.api_url}/configuration`)
            .toPromise()
            .then((resp) => {
                if (resp) {
                    // LocalStorageService.save('headquarter', resp);
                }
                if (appConfig.getConfig() === null) {
                    appConfig.loadConfigs(new LayoutConfig().configs);
                }
            });
    };
}

@NgModule({ declarations: [AppComponent, StoreChangingDialogComponent],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        NgxPermissionsModule.forRoot(),
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        StoreDevtoolsModule.instrument(),
        AuthModule.forRoot(),
        NgbModule,
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        MatDialogModule], providers: [
        // { provide: APP_BASE_HREF, useValue: '/admin' },
        AuthService,
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        DataTableService,
        SplashScreenService,
        ModuleGuard,
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService, HttpClient],
            multi: true,
        },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
