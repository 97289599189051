<div class="login">
  <h3 class="login__title">{{ 'AUTH.REGISTER.TITLE' | translate }}</h3>

  <kt-auth-notice></kt-auth-notice>

  <form class="login__form" [formGroup]="registerForm" autocomplete="off">
    <mat-form-field class="login__field">
      <mat-label>Fullname</mat-label>
      <input matInput type="text" placeholder="Fullname" formControlName="fullname" />
      <mat-error *ngIf="isControlHasError('fullname', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('fullname', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('fullname', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="login__field">
      <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
      <input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off" />
      <mat-error *ngIf="isControlHasError('email', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'email')">
        <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="login__field">
      <mat-label>Username</mat-label>
      <input matInput type="text" placeholder="Username" formControlName="username" />
      <mat-error *ngIf="isControlHasError('username', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('username', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('username', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="login__field">
      <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
      <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" />
      <mat-error *ngIf="isControlHasError('password', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('password', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('password', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="login__field">
      <mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
      <input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword" autocomplete="off" />
      <mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('confirmPassword', 'minLength')">
        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('confirmPassword', 'maxLength')">
        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
      </mat-error>
      <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
        <strong>Passsword and ConfirmPassword didn't match.</strong>
      </mat-error>
    </mat-form-field>
    <mat-checkbox name="agree" formControlName="agree"> I agree the <a href="javascript:;">terms & conditions</a> </mat-checkbox>

    <a href="javascript:;" routerLink="/auth/forgot-password" class="login__link">
      {{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
    </a>

    <div class="login__actions">
      <button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate">
        {{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
      </button>
      <button (click)="submit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate">
        <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</span>
      </button>
    </div>
  </form>
</div>
