export const SPECIFICATIONS_DICTIONARY: string[] = [
  'Seats',
  'Fuel Type',
  'Stock',
  'Door count',
  'Engine',
  'Transmission',
  'Drivetrain',
  'Combined MPG',
  'Warranty',
  'Wheels',
];
