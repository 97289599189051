<kt-portlet>
  <kt-portlet-header
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg'">
    <ng-container ktPortletTools>
      <button
        (click)="back()"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Back to the headquarters list"
      >
        <i class="la la-arrow-left"></i>
        <span class="kt-hidden-mobile">{{'HEADQUARTERS.EDIT.ACTIONS.BACK' | translate}}</span>
      </button>
      <button
        *ngxPermissionsOnly="['ROLE_SUPER_ADMIN']"
        class="btn btn-secondary kt-margin-r-10"
        mat-raised-button
        matTooltip="Reset changes"
        (click)="reset()"
        [disabled]="selectedTab !== 0"
      >
        <i class="la la-cog"></i>
        <span class="kt-hidden-mobile">{{'HEADQUARTERS.EDIT.ACTIONS.RESET' | translate}}</span>
      </button>
      <button
        *ngxPermissionsOnly="['ROLE_SUPER_ADMIN']"
        class="btn btn-primary kt-margin-r-10"
        mat-raised-button
        matTooltip="Save & Continue"
        color="primary"
        [disabled]="headquarterForm && headquarterForm.invalid"
        (click)="onSumbit(false)"
      >
        <span class="kt-hidden-mobile">{{'HEADQUARTERS.EDIT.ACTIONS.SAVE' | translate}}</span>
      </button>
    </ng-container>
  </kt-portlet-header>
  <kt-portlet-body>
    <mat-tab-group [(selectedIndex)]="selectedTab" *ngIf="headquarter">
      <mat-tab [disabled]="isVodafoneAdmin" [label]="'HEADQUARTERS.EDIT.BASIC_INFO.LABEL' | translate">
        <ng-template matTabContent>
          <div *ngIf="headquarter">
            <!--begin::Form-->
            <form
              [formGroup]="headquarterForm">
              <kt-alert
                *ngIf="hasFormErrors"
                type="warn"
                [showCloseButton]="true"
                [duration]="10000"
                (close)="onAlertClose($event)"
              >
                Oh snap! Change a few things up and try submitting again.
              </kt-alert>
                <div class="row" style="max-width: 100%">
                    <div class="col-md-9">
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <input
                                    matInput
                                    placeholder="{{ canEdit ? ('HEADQUARTERS.EDIT.BASIC_INFO.NAME.EDIT' | translate) : ('HEADQUARTERS.EDIT.BASIC_INFO.NAME.TITLE' | translate) }}"
                                    formControlName="name"
                                    [readonly]="!canEdit"
                                />
                                <mat-error *ngIf=" headquarterForm.get('name').hasError('required')">
                                    {{'HEADQUARTERS.EDIT.BASIC_INFO.NAME.ERROR.REQUIRED' | translate}}
                                </mat-error>
                                <mat-error *ngIf="headquarterForm.get('name').hasError('pattern')">
                                    {{'HEADQUARTERS.EDIT.BASIC_INFO.NAME.ERROR.PATTERN' | translate}}
                                </mat-error>
                                <mat-hint *ngIf="canEdit" align="start">{{'HEADQUARTERS.EDIT.BASIC_INFO.NAME.HINT' | translate}}
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <ng-template #rt let-r="result" let-t="term">
                                    {{ r.name }}
                                </ng-template>
                                <span *ngIf="searching">searching...</span>
                                <input
                                    matInput
                                    formControlName="country"
                                    placeholder="Enter country name"
                                    type="text"
                                    [ngbTypeahead]="searchCountry"
                                    [resultTemplate]="rt"
                                    [inputFormatter]="formatter"
                                />
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <input
                                    matInput
                                    placeholder="{{
                            canEdit ? 'Enter GTM key' : 'GTM key'
                          }}"
                                    formControlName="googleTagManagerKey"
                                    [readonly]="!canEdit"
                                />
                                <mat-hint *ngIf="canEdit" align="start"
                                >Please enter <strong> GTM key</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col-md-4">
                                <input matInput
                                       placeholder="{{ canEdit ? 'Enter VAT' : 'VAT' }}"
                                       formControlName="VAT"
                                       [readonly]="!canEdit"
                                />
                                <mat-hint *ngIf="canEdit" align="start"
                                >Please enter <strong> VAT</strong>
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <input matInput
                                       placeholder="{{
                            canEdit ? 'Enter staff bonus' : 'staff bonus'
                          }}"
                                       formControlName="staffBonus"
                                       [readonly]="!canEdit"
                                />
                                <mat-hint *ngIf="canEdit" align="start"
                                >Please enter <strong> staffBonus</strong>
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                <input
                                    matInput
                                    placeholder="{{
                            canEdit ? 'Enter currency' : 'currency'
                          }}"
                                    formControlName="currency"
                                    [readonly]="!canEdit"
                                />
                                <mat-hint *ngIf="canEdit" align="start"
                                >Please enter <strong> currency</strong>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <div class="col-md-4 d-flex align-items-center">
                                <mat-checkbox
                                    formControlName="enableAnonimization"
                                    color="primary"
                                    [disabled]="!canEdit">Enable anonimization</mat-checkbox>
                            </div>
                            <mat-form-field class="col-md-4">
                                <input
                                    matInput
                                    placeholder="Finished order anonimization"
                                    [readonly]="!canEdit"
                                    formControlName="finishedOrdersAnonimization"
                                />
                                <mat-hint
                                >Please enter <strong> days</strong>
                                </mat-hint>
                            </mat-form-field>
                            <mat-form-field class="col-md-4">
                                    <input
                                        matInput
                                        placeholder="Unfinished order anonimization"
                                        [readonly]="!canEdit"
                                        formControlName="unfinishedOrdersAnonimization"
                                    />
                                    <mat-hint
                                    >Please enter <strong> days</strong>
                                    </mat-hint>
                                </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-3" style="display: flex; flex-direction: column">
                            <mat-checkbox formControlName="bankIDVerification" color="primary">
                                Allow only BankID verification (no manual fallback)
                            </mat-checkbox>
                            <mat-checkbox formControlName="storeChangingAllowed" color="primary">
                                Allow users to change store
                            </mat-checkbox>
                            <mat-checkbox formControlName="onlineBankIDVerification" color="primary">
                                Enable BankID for online
                            </mat-checkbox>
                            <mat-checkbox formControlName="notAuthorCampaignApproval" color="primary">
                                Campaigns must be approve by other person
                            </mat-checkbox>
                    </div>
                </div>
            </form>
          </div>

        </ng-template>
      </mat-tab>
      <mat-tab
        label="Headquarter employees"
        [disabled]="!headquarter || !headquarter.id || isVodafoneAdmin"
      >
        <ng-template matTabContent>
          <kt-headquarter-employees-list
            [headquarterId$]="headquarterId$"
          ></kt-headquarter-employees-list>
        </ng-template>
      </mat-tab>
      <mat-tab label="Employees requests">
        <ng-template matTabContent>
          <kt-employees-requests
            [headquarterId$]="headquarterId$"
          ></kt-employees-requests>
        </ng-template>
      </mat-tab>
      <mat-tab
        label="Headquarter stores"
        [disabled]="!headquarter || !headquarter.id || isVodafoneAdmin"
      >
        <ng-template matTabContent>
          <!-- specifications -->
          <kt-stores-list
            [headquarterId$]="headquarterId$"
            [hidden]="isVodafoneAdmin"
            [headquarterConfigurationCode]="
              headquarterConfigurationCode$ | async
            "
          ></kt-stores-list>
        </ng-template>
      </mat-tab>
      <mat-tab
        label="Provider settings"
        [disabled]="!headquarter || !headquarter.id || isVodafoneAdmin"
      >
        <ng-template matTabContent>
          <kt-provider-calc
            [headquarterId$]="headquarterId$ | async"
          ></kt-provider-calc>
        </ng-template>
      </mat-tab>
      <mat-tab
        *ngIf="canEdit"
        label="Product settings"
        [disabled]="!headquarter || !headquarter.id || isVodafoneAdmin"
      >
        <ng-template matTabContent>
          <kt-product-settings
            [headquarter]="headquarter"
            (getModule)="getModule($event)"
          ></kt-product-settings>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </kt-portlet-body>
</kt-portlet>
