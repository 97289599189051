<!-- start::FILTERS & GROUP ACTIONS -->
<div class="kt-form kt-margin-b-30">
  <!-- start::FILTERS -->
  <div class="kt-form__filtration">
    <div class="row align-items-center">
      <div class="col-md-2 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Search headquarter store" #searchInput />
          <mat-hint align="start">Search in all fields</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-8 kt-margin-bottom-10-mobile"></div>
      <div class="col-md-2 text-right kt-margin-bottom-10-mobile">
        <button
          type="button"
          (click)="addStore()"
          mat-raised-button
          color="primary"
          matTooltip="Add store"
        >
          Add store
        </button>
      </div>
    </div>
  </div>
  <!-- end::FILTERS -->

  <!-- start::GROUP ACTIONS -->
  <!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
  <!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
  <div
    class="row align-items-center collapse kt-form__group-actions kt-margin-bottom-20"
    [ngClass]="{ show: selection.selected.length > 0 }"
  >
    <div class="col-xl-12">
      <div class="kt-form__group kt-form__group--inline">
        <div class="kt-form__label kt-form__label-no-wrap">
          <label class="kt--font-bold kt-font-danger-"
            >Selected records count: {{ selection.selected.length }}:</label
          >
        </div>
        <div class="kt-form__control kt-form__group--inline">
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="deleteStores()"
              matTooltip="Delete selected stores"
            >
              Delete All
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              mat-raised-button
              color="warn"
              (click)="fetchStores()"
              matTooltip="Fetch selected stores"
            >
              Fetch Selected Records
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::GROUP ACTIONS -->
</div>
<!-- end::FILTERS & GROUP ACTIONS -->

<!-- MATERIAL TABLE | Binded to datasources -->
<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
<div class="mat-table__wrapper mat-table__wrapper--sub">
  <mat-table
    class="lmat-elevation-z8"
    [dataSource]="dataSource"
    matSort
    matSortActive="_storeName"
    matSortDirection="asc"
    matSortDisableClear
  >
    <!-- Checkbox Column -->
    <!-- Table with selection -->
    <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
    <!-- Checkbox Column -->
    <!-- <ng-container matColumnDef="select">
			<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
				<mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [color]="'primary'">
				</mat-checkbox>
			</mat-header-cell>
			<mat-cell *matCellDef="let row" class="mat-column-checkbox">
				<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [color]="'primary'">
				</mat-checkbox>
			</mat-cell>
		</ng-container> -->

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column"
        >Name</mat-header-cell
      >
      <mat-cell *matCellDef="let store" class="mat-column">{{
        store.name
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column"
        >Address</mat-header-cell
      >
      <mat-cell *matCellDef="let store" class="mat-column">{{
        store.address
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column"
        >Phone</mat-header-cell
      >
      <mat-cell *matCellDef="let store" class="mat-column">{{
        store.phone
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column"
        >E-mail</mat-header-cell
      >
      <mat-cell *matCellDef="let store" class="mat-column">{{
        store.email
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="mat-column-actions">
        <!-- Actions -->
      </mat-header-cell>
      <mat-cell *matCellDef="let store" class="mat-column-actions">
        <!-- <button type="button" mat-icon-button color="primary" (click)="editStore(store)" matTooltip="Edit store">
					<mat-icon>create</mat-icon>
				</button>
				&nbsp; -->
        <!-- <button mat-icon-button color="warn" type="button" (click)="deleteStore(store)" matTooltip="Delete store">
					<mat-icon>delete</mat-icon>
				</button> -->
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="mat-table__message" *ngIf="!dataSource.hasItems">
    No records found
  </div>
  <div
    class="mat-table__message"
    *ngIf="dataSource.isPreloadTextViewed$ | async"
  >
    Please wait....
  </div>
</div>
<!-- start: BOTTOM -->
<div class="mat-table__bottom">
  <mat-spinner
    [diameter]="20"
    *ngIf="dataSource.loading$ | async"
  ></mat-spinner>
  <mat-paginator
    [pageSize]="10"
    [pageSizeOptions]="[3, 5, 10]"
    [length]="dataSource.paginatorTotal$ | async"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</div>
<!-- end: BOTTOM -->
