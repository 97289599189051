<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>language</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <ng-container *ngFor="let language of languages">
        <button (click)="setLanguage(language.lang)" mat-menu-item>
            <img alt="" src="{{ language.flag }}" height="40"/>
            <small>{{ language.name }}</small>
        </button>
    </ng-container>
</mat-menu>
