import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'kt-info-video-check',
  templateUrl: './info-video-check.component.html',
  styleUrls: ['./info-video-check.component.scss'],
})
export class InfoVideoCheckComponent implements OnInit {
  public checked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<InfoVideoCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
  ) {}

  ngOnInit() {}

  public close() {
    this.dialogRef.close(false);
  }

  public confirm() {
    this.dialogRef.close(true);
  }
}
