<div
  class="kt-subheader kt-grid__item"
  [hidden]="subheaderService.disabled$ | async"
  id="kt_subheader"
>
  <div class="kt-subheader__main">
    <h3 *ngIf="title" class="kt-subheader__title">{{ title }}</h3>
    <span class="kt-subheader__separator kt-subheader__separator--v"></span>
  </div>
  <div class="kt-subheader__toolbar">
    <div class="kt-subheader__wrapper">
    </div>
  </div>
</div>
