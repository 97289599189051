import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {currentUser, Login, Logout, User} from '../../../../../core/auth';
import {environment} from '../../../../../../environments/environment';
import {ApplicationService} from '../../../../../core/services/application.service';
import {MatDialog} from '@angular/material/dialog';
import {StoreChangingDialogComponent} from '../../../components/store-changing-dialog/store-changing-dialog.component';
import {LocalStorageService} from "../../../../../core/services/local-storage.service";

@Component({
    selector: 'kt-user-profile2',
    templateUrl: './user-profile2.component.html',
    styleUrls: ['./user-profile2.component.scss'],
})
export class UserProfile2Component implements OnInit {
    user$: Observable<User>;
    expertMode: boolean = false;
    readonly production = environment.production;
    readonly stage = environment.stage;

    @Input() showAvatar: boolean = false;
    @Input() showHi: boolean = true;
    @Input() showBadge: boolean = false;
    private user;

    constructor(private store: Store<AppState>,
                private appService: ApplicationService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(currentUser));
        this.getMode();
        this.userChangesSub();
    }

    getMode(): void {
        this.expertMode = this.appService.productionMode$.value;
    }

    private userChangesSub(): void {
        this.user$.subscribe(user => {
            this.user = user;
        });
    }

    changeMode(production): void {
        this.appService.productionMode = production.checked;
    }

    logout() {
        this.store.dispatch(new Logout());
    }

    showStoreSelection() {
        const headquarterId = this.user.headquarterId;
        const storeId = this.user.storeId;
        const dialogRef = this.dialog.open(StoreChangingDialogComponent, {
            maxWidth: '440px',
            data: {
                headquarterId,
                selectedStoreId: storeId
            },
        });
        dialogRef.afterClosed().subscribe(res => {
            const userToken = LocalStorageService.get(environment.authTokenKey);
            const refreshToken = LocalStorageService.get('refresh');
            this.store.dispatch(new Login({
                authToken: userToken,
                refreshToken: refreshToken,
            }));
        });
    }
}
