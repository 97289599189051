// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import {
  HttpUtilsService,
  QueryParamsModel,
  QueryResultsModel,
} from '../../_base/crud';
// Models
import { StoreModel } from '../_models/store.model';
import { environment } from '../../../../environments/environment';

const API_STORE_URL = `${environment.api_url}/store`;
// Real REST API
@Injectable()
export class StoresService {
  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(
    new QueryParamsModel({}, 'asc', '', 0, 10),
  );

  constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

  // CREATE =>  POST: add a new store to the server
  createStore(store): Observable<StoreModel> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.post<StoreModel>(API_STORE_URL, store, {
      headers: httpHeaders,
    });
  }

  // READ
  getAllStores(): Observable<StoreModel[]> {
    return this.http.get<StoreModel[]>(API_STORE_URL);
  }

  getStoreById(storeId: number): Observable<StoreModel> {
    return this.http.get<StoreModel>(API_STORE_URL + `/${storeId}`);
  }

  // Server should return filtered/sorted result
  findStores(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const httpParams = this.httpUtils.getFindHTTPParams(queryParams);
    const url = API_STORE_URL;
    return this.http.get<QueryResultsModel>(url, {
      headers: httpHeaders,
      params: httpParams,
    });
  }

  // UPDATE => PUT: update the store on the server
  updateStore(store: StoreModel): Observable<any> {
    // Note: Add headers if needed (tokens/bearer)
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.patch(`${API_STORE_URL}/${store.id}`, store, {
      headers: httpHeaders,
    });
  }

  getLogsByStore(pageIndex: number, pageSize: number, storeId: number) {
    return this.http.get(
      `${environment.api_url}/logs/auth/store/${storeId}?sort=-createdAt&page=${pageIndex}&limit=${pageSize}`,
    );
  }

  findStoreAutocomplete(
    query: string,
  ): Observable<[{ id: number; email: string }]> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<[{ id: number; email: string }]>(
      `${environment.api_url}/autocomplete?query=${query}`,
    );
    // return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
  }

  createIpRange(cidr: string, storeId: number) {
    return this.http.post(`${API_STORE_URL}/ip-range`, {
      cidr: cidr,
      storeId: storeId,
    });
  }

  updateIpRange(ipRangeId: string, ipRange: string) {
    return this.http.patch(`${API_STORE_URL}/ip-range/${ipRangeId}`, {
      cidr: ipRange,
    });
  }

  deleteIpRange(ipRangeId: string) {
    return this.http.delete(`${API_STORE_URL}/ip-range/${ipRangeId}`);
  }

  // UPDATE Status
  // Comment this when you start work with real server
  // This code imitates server calls
  updateStatusForStore(stores: StoreModel[], status: number): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = {
      storesForUpdate: stores,
      newStatus: status,
    };
    const url = API_STORE_URL + '/updateStatus';
    return this.http.put(url, body, { headers: httpHeaders });
  }

  // DELETE => delete the store from the server
  deleteStore(storeId: number): Observable<StoreModel> {
    const url = `${API_STORE_URL}/${storeId}`;
    return this.http.delete<StoreModel>(url);
  }

  deleteStores(ids: number[] = []): Observable<any> {
    const url = API_STORE_URL + '/delete';
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    const body = { prdocutIdsForDelete: ids };
    return this.http.put<QueryResultsModel>(url, body, {
      headers: httpHeaders,
    });
  }
}
