import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeadquarterEmployeesState } from '../_reducers/headquarter-employee.reducers';
import { HeadquarterEmployeeModel } from '../_models/headquarter-employee.model';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { EmployeesRequestsState } from '../_reducers/employees-reuests.reducers';
import { selectHeadquarterEmployeesState } from './headquarter-employee.selectors';
import { EmployeesRequestsModel } from '../_models/employees-requests.model';

export const selectEmployeesRequestsState =
  createFeatureSelector<EmployeesRequestsState>('employeesRequests');

export const selectEmployeesRequestsPageLoading = createSelector(
  selectEmployeesRequestsState,
  (employeesRequestsState) => employeesRequestsState.loading,
);

export const selectERShowInitWaitingMessage = createSelector(
  selectEmployeesRequestsState,
  (employeesRequestsState) => employeesRequestsState.showInitWaitingMessage,
);

export const selectEmployeesRequestsInStore = createSelector(
  selectEmployeesRequestsState,
  (employeesRequestsState) => {
    const items: EmployeesRequestsModel[] = [];
    each(employeesRequestsState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: EmployeesRequestsModel[] = httpExtension.sortArray(
      items,
      employeesRequestsState.lastQuery.sortField,
      employeesRequestsState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, employeesRequestsState.totalCount, '');
  },
);
