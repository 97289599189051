// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { StoreEmployeesState } from '../_reducers/store-employee.reducers';
import { StoreEmployeeModel } from '../_models/store-employee.model';

export const selectStoreEmployeesState =
  createFeatureSelector<StoreEmployeesState>('storeEmployees');

export const selectStoreEmployeeById = (storeEmployeeId: number) =>
  createSelector(
    selectStoreEmployeesState,
    (storeEmployeesState) => storeEmployeesState.entities[storeEmployeeId],
  );

export const selectStoreEmployeesPageLoading = createSelector(
  selectStoreEmployeesState,
  (storeEmployeesState) => storeEmployeesState.loading,
);

export const selectCurrentStoreIdInStoreForStoreSpecs = createSelector(
  selectStoreEmployeesState,
  (storeEmployeesState) => storeEmployeesState.storeId,
);

export const selectLastCreatedStoreEmployeeId = createSelector(
  selectStoreEmployeesState,
  (storeEmployeesState) => storeEmployeesState.lastCreatedStoreEmployeeId,
);

export const selectPSShowInitWaitingMessage = createSelector(
  selectStoreEmployeesState,
  (storeEmployeesState) => storeEmployeesState.showInitWaitingMessage,
);

export const selectStoreEmployeesInStore = createSelector(
  selectStoreEmployeesState,
  (storeEmployeesState) => {
    const items: StoreEmployeeModel[] = [];
    each(storeEmployeesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: StoreEmployeeModel[] = httpExtension.sortArray(
      items,
      storeEmployeesState.lastQuery.sortField,
      storeEmployeesState.lastQuery.sortOrder,
    );
    return new QueryResultsModel(result, storeEmployeesState.totalCount, '');
  },
);
