<div class="kt-form kt-margin-b-30">
  <div class="kt-form__filtration">
    <div class="row align-items-center">
      <div class="col-md-2 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input
            matInput
            placeholder="Search headquarter employee"
            #searchInput
          />
          <mat-hint align="start">Search in all fields</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-8 kt-margin-bottom-10-mobile"></div>
      <div class="col-md-2 text-right kt-margin-bottom-10-mobile">
        <button
          type="button"
          (click)="addEmployee()"
          mat-raised-button
          color="primary"
          matTooltip="Create new employee"
        >
          New employee
        </button>
      </div>
    </div>
  </div>

  <div
    class="row align-items-center collapse kt-form__group-actions kt-margin-bottom-20"
    [ngClass]="{ show: selection.selected.length > 0 }"
  >
    <div class="col-xl-12">
      <div class="kt-form__group kt-form__group--inline">
        <div class="kt-form__label kt-form__label-no-wrap">
          <label class="kt--font-bold kt-font-danger-"
            >Selected records count: {{ selection.selected.length }} :</label
          >
        </div>
        <div class="kt-form__control kt-form__group--inline">
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="deleteEmployees()"
              matTooltip="Delete selected employees"
            >
              Delete All
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              mat-raised-button
              color="warn"
              (click)="fetchEmployees()"
              matTooltip="Fetch selected employees"
            >
              Fetch Selected Records
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mat-table__wrapper mat-table__wrapper--sub">
  <mat-table
    class="lmat-elevation-z8"
    [dataSource]="dataSource"
    matSort
    matSortActive="_employeeName"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column"
        >E-mail</mat-header-cell
      >
      <mat-cell *matCellDef="let employee" class="mat-column">{{
        employee.email
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="mat-column-actions">
        Actions
      </mat-header-cell>
      <mat-cell *matCellDef="let employee" class="mat-column-actions">
        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="deleteEmployee(employee)"
          matTooltip="Delete employee"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="mat-table__message" *ngIf="!dataSource.hasItems">
    No records found
  </div>
  <div
    class="mat-table__message"
    *ngIf="dataSource.isPreloadTextViewed$ | async"
  >
    Please wait....
  </div>
</div>
<div class="mat-table__bottom">
  <mat-spinner
    [diameter]="20"
    *ngIf="dataSource.loading$ | async"
  ></mat-spinner>
  <mat-paginator
    [pageSize]="10"
    [pageSizeOptions]="[3, 5, 10]"
    [length]="dataSource.paginatorTotal$ | async"
    [showFirstLastButtons]="true"
  ></mat-paginator>
</div>
