<section>
  <h3>Elfogadva</h3>
  <div class="row justify-content-end">
    <button
      (click)="confirm()"
      class="btn btn-primary kt-margin-r-20 button-light"
    >
      Elfogadva
    </button>
    <button (click)="close()" class="btn btn-primary button-light">
      Vissza
    </button>
  </div>
</section>
