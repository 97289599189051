import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectQueryResult,
  selectRolesPageLoading,
  selectRolesShowInitWaitingMessage,
} from '../_selectors/role.selectors';
import {BaseDataSource, QueryResultsModel} from '../../_base/crud';

export class RolesDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectRolesPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectRolesShowInitWaitingMessage),
    );

    this.store
      .pipe(select(selectQueryResult))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
