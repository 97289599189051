// Angular
import { Injectable } from '@angular/core';
// Object-Path
import * as objectPath from 'object-path';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Layout
import { LayoutConfigModel } from '../../../core/_base/layout';

export interface ClassType {
  header: string[];
  header_mobile: string[];
  header_menu: string[];
  aside_menu: string[];
}

@Injectable({
    providedIn: 'root',
})
export class HtmlClassService {
  config: LayoutConfigModel;
  classes: ClassType;
  onClassesUpdated$: BehaviorSubject<ClassType>;

  constructor() {
    this.onClassesUpdated$ = new BehaviorSubject(this.classes);
  }

  setConfig(layoutConfig: LayoutConfigModel) {
    this.config = layoutConfig;

    // scope list of classes
    this.classes = {
      header: [],
      header_mobile: [],
      header_menu: [],
      aside_menu: [],
    };
    this.initLayout();
    this.initLoader();
    this.initHeader();
    this.initSubheader();
    this.initAside();
    this.initFooter();
    this.onClassesUpdated$.next(this.classes);
  }

  getClasses(path?: string, toString?: boolean): ClassType | string[] | string {
    if (path) {
      const classes = objectPath.get(this.classes, path) || '';
      if (toString && Array.isArray(classes)) {
        return classes.join(' ');
      }
      return classes.toString();
    }
    return this.classes;
  }

  private initLayout() {
    if (objectPath.has(this.config, 'self.body.class')) {
      const _selfBodyClass = objectPath
        .get(this.config, 'self.body.class')
        .toString();
      if (_selfBodyClass) {
        const bodyClasses: string[] = _selfBodyClass.split(' ');
        bodyClasses.forEach((cssClass) =>
          document.body.classList.add(cssClass),
        );
      }
    }
    if (
      objectPath.get(this.config, 'self.layout') === 'boxed' &&
      objectPath.has(this.config, 'self.body.background-image')
    ) {
      document.body.style.backgroundImage =
        'url("' +
        objectPath.get(this.config, 'self.body.background-image') +
        '")';
    }

    document.body.classList.add('kt-quick-panel--right');
    document.body.classList.add('kt-demo-panel--right');
    document.body.classList.add('kt-offcanvas-panel--right');
  }


  private initLoader() {}


  private initHeader() {
    // Fixed header
    if (objectPath.get(this.config, 'header.self.fixed.desktop')) {
      document.body.classList.add('kt-header--fixed');
      objectPath.push(this.classes, 'header', 'kt-header--fixed');
    } else {
      document.body.classList.add('kt-header--static');
    }

    if (objectPath.get(this.config, 'header.self.fixed.mobile')) {
      document.body.classList.add('kt-header-mobile--fixed');
      objectPath.push(this.classes, 'header_mobile', 'kt-header-mobile--fixed');
    }

    if (objectPath.get(this.config, 'header.menu.self.layout')) {
      objectPath.push(
        this.classes,
        'header_menu',
        'kt-header-menu--layout-' +
          objectPath.get(this.config, 'header.menu.self.layout'),
      );
    }
  }

  private initSubheader() {
    // Fixed content head
    if (objectPath.get(this.config, 'subheader.fixed')) {
      document.body.classList.add('kt-subheader--fixed');
    }

    if (objectPath.get(this.config, 'subheader.display')) {
      document.body.classList.add('kt-subheader--enabled');
    }

    if (objectPath.has(this.config, 'subheader.style')) {
      document.body.classList.add(
        'kt-subheader--' + objectPath.get(this.config, 'subheader.style'),
      );
    }
  }

  private initAside() {
    if (objectPath.get(this.config, 'aside.self.display') !== true) {
      return;
    }

    document.body.classList.add('kt-aside--enabled');

    // Fixed Aside
    if (objectPath.get(this.config, 'aside.self.fixed')) {
      document.body.classList.add('kt-aside--fixed');
      objectPath.push(this.classes, 'aside', 'kt-aside--fixed');
    } else {
      document.body.classList.add('kt-aside--static');
    }

    if (objectPath.get(this.config, 'aside.self.minimize.toggle')) {
      document.body.classList.remove('kt-aside--minimize-hover');
      document.body.classList.add('kt-aside--minimize');
    }


    if (
      objectPath.get(this.config, 'aside.self.fixed') !== true &&
      objectPath.get(this.config, 'aside.menu.dropdown')
    ) {
      objectPath.push(this.classes, 'aside_menu', 'kt-aside-menu--dropdown');
    }
  }

  private initFooter() {
    if (objectPath.get(this.config, 'footer.self.fixed')) {
      document.body.classList.add('kt-footer--fixed');
    }
  }
}
