import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from '../../../core/_base/layout';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit, OnDestroy {
  today: number = Date.now();
  headerLogo: string;
  public currentWidth = screen.availWidth;
  private destroyStream$ = new Subject();

  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.translationService.setLanguage(
      this.translationService.getSelectedLanguage(),
    );
    this.headerLogo = this.layoutConfigService.getLogo();
    this.splashScreenService.hide();
    this.loadCSS('./assets/css/demo1/style.bundle.css');

    this.router.events
      .pipe(takeUntil(this.destroyStream$))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
        }
      });
  }

  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }

  ngOnDestroy() {
    this.destroyStream$.next({});
  }
}
