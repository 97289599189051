<section class="catalog-list-phone">
  <kt-portlet [class]="'kt-portlet--border-bottom-brand'">
    <kt-portlet-header
      [title]="'CATALOG.SEARCH_PHONE' | translate"
    ></kt-portlet-header>
    <kt-portlet-body>
      <form [formGroup]="searchForm">
        <mat-form-field class="search-phone">
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <input
            matInput
            type="text"
            placeholder="{{ 'INFO.SEARCH' | translate }}"
            formControlName="name"
            tabindex="1"
            autofocus
          />
          <button
            *ngIf="this.searchForm.get('name').valid"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearchForm()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </kt-portlet-body>
  </kt-portlet>

  <kt-portlet
    [class]="'kt-portlet--border-bottom-brand'"
    *ngIf="this.searchForm.get('name').valid"
  >
    <kt-portlet-body perfectScrollbar class="list-phone">
      <div class="kt-widget4">
        <div class="kt-widget4__item" *ngIf="searching">
          <div class="kt-widget4__title">
            {{ 'INFO.SEARCHING' | translate }}...
          </div>
          <div style="display: inline; float: right; transform: scale(0.7)">
            <mat-spinner diameter="50"></mat-spinner>
          </div>
        </div>

        <a
          (click)="close(item)"
          href="javascript:void(0)"
          class="kt-widget4__item"
          *ngFor="let item of DEVICELIST; let i = index"
        >
          <div class="kt-widget4__pic kt-widget4__pic--pic" *ngIf="item.image">
            <img [src]="item.image" alt="image" />
          </div>

          <span class="kt-widget4__title">{{ item.articleName }}</span>

          <button mat-button tabindex="{{ i + 1 }}">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </a>
      </div>
    </kt-portlet-body>
  </kt-portlet>
</section>
