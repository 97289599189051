// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { HeadquarterEmployeeModel } from '../_models/headquarter-employee.model';

export enum HeadquarterEmployeeActionTypes {
  HeadquarterEmployeeOnServerCreated = '[Edit HeadquarterEmployee Dialog] Headquarter Employee On Server Created',
  HeadquarterEmployeeCreated = '[Edit HeadquarterEmployee Dialog] Headquarter Employee Created',
  HeadquarterEmployeeUpdated = '[Edit EmployeeEmployee Dialog] Headquarter Employee Updated',
  OneHeadquarterEmployeeDeleted = '[Headquarter Employee List Page]  One Headquarter Employee Deleted',
  ManyHeadquarterEmployeesDeleted = '[Headquarter Employees List Page] Many Headquarter Employees Deleted',
  HeadquarterEmployeesPageRequested = '[Headquarter Employees List Page] Headquarter Employees Page Requested',
  HeadquarterEmployeesPageLoaded = '[Headquarter Employees API] Headquarter Employees Page Loaded',
  HeadquarterEmployeesPageCancelled = '[Headquarter Employees API] Headquarter Employees Page Cancelled',
  HeadquarterEmployeesPageToggleLoading = '[Headquarter Employees] Headquarter Employees Page Toggle Loading',
}

export class HeadquarterEmployeeOnServerCreated implements Action {
  readonly type =
    HeadquarterEmployeeActionTypes.HeadquarterEmployeeOnServerCreated;
  constructor(
    public payload: { employeeId: number; headquarterId: number; role: string },
  ) {}
}

export class HeadquarterEmployeeCreated implements Action {
  readonly type = HeadquarterEmployeeActionTypes.HeadquarterEmployeeCreated;
  constructor(
    public payload: { headquarterEmployee: HeadquarterEmployeeModel },
  ) {}
}

export class HeadquarterEmployeeUpdated implements Action {
  readonly type = HeadquarterEmployeeActionTypes.HeadquarterEmployeeUpdated;
  constructor(
    public payload: {
      partialHeadquarterEmployee: Update<HeadquarterEmployeeModel>; // For State update
      headquarterEmployee: HeadquarterEmployeeModel; // For Server update (through service)
    },
  ) {}
}

export class OneHeadquarterEmployeeDeleted implements Action {
  readonly type = HeadquarterEmployeeActionTypes.OneHeadquarterEmployeeDeleted;
  constructor(public payload: { employeeId: number; headquarterId: number }) {}
}

export class ManyHeadquarterEmployeesDeleted implements Action {
  readonly type =
    HeadquarterEmployeeActionTypes.ManyHeadquarterEmployeesDeleted;
  constructor(public payload: { ids: number[] }) {}
}

export class HeadquarterEmployeesPageRequested implements Action {
  readonly type =
    HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageRequested;
  constructor(
    public payload: { page: QueryParamsModel; headquarterId: number },
  ) {}
}

export class HeadquarterEmployeesPageLoaded implements Action {
  readonly type = HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageLoaded;
  constructor(
    public payload: {
      headquarterEmployees: HeadquarterEmployeeModel[];
      totalCount: number;
    },
  ) {}
}

export class HeadquarterEmployeesPageCancelled implements Action {
  readonly type =
    HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageCancelled;
}

export class HeadquarterEmployeesPageToggleLoading implements Action {
  readonly type =
    HeadquarterEmployeeActionTypes.HeadquarterEmployeesPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type HeadquarterEmployeeActions =
  | HeadquarterEmployeeOnServerCreated
  | HeadquarterEmployeeCreated
  | HeadquarterEmployeeUpdated
  | OneHeadquarterEmployeeDeleted
  | ManyHeadquarterEmployeesDeleted
  | HeadquarterEmployeesPageRequested
  | HeadquarterEmployeesPageLoaded
  | HeadquarterEmployeesPageCancelled
  | HeadquarterEmployeesPageToggleLoading;
