import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ICustomerLoyalty } from '../../../../views/pages/catalog/catalog-details-phone/phone-providers/interfaces/customer-loyalty.interface';


@Injectable({
  providedIn: 'root',
})
export class Tele2ProviderService {
    private readonly baseUrl = `${environment.api_url}/providers/tele2`;
  constructor(private http: HttpClient) {}

  getExistingSubscriptions(SSN: number, selectedContractId): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/subscriptions/${SSN}?contractId=${selectedContractId}`,
    );
  }

  getFamilyType(articleNumbers: string[],offerId): Observable<Array<any>> {
    return this.http.post<Array<any>>(`${this.baseUrl}/subscriptions/type`, {
        articleNumbers,
        offerId
    });
  }

  getNumberList(SSN: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/numbers?SSN=${SSN}`);
  }

  getLoyalty(ssn: string): Observable<ICustomerLoyalty> {
      return this.http.get<ICustomerLoyalty>(
          `${this.baseUrl}/customer/${ssn}/loyalty`,
      );
  }
}
