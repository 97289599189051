
export class HeadquarterStoreModel {
  id: number;
  headquarterId: number;
  name: string;
  address: string;
  phone: string;
  email: string;

  clear(headquarterId: number) {
    this.id = undefined;
    this.headquarterId = headquarterId;
    this.email = '';
    this.address = '';
    this.phone = '';
    this.name = 'undefined';
  }
}
