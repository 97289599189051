import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ToastEvent} from './models/toast.event';
import {EventTypes} from './models/event-types';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
    toastEvents: Observable<ToastEvent>;
    private _toastEvents = new Subject<ToastEvent>();

    constructor() {
        this.toastEvents = this._toastEvents.asObservable();
    }

    showToast(title: string, message: string, type: EventTypes = EventTypes.Info, delay: number = 5000) {
        this._toastEvents.next({
            message,
            title,
            type,
            delay
        });
    }
}
