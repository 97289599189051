import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-portlet-body',
  template: `
      <mat-card-content [ngClass]="classList">
          <ng-content></ng-content>
      </mat-card-content>`,
})
export class PortletBodyComponent implements OnInit {
  @HostBinding('class') classList = 'kt-portlet__body';
  @Input() class: string;
  ngOnInit() {
    if (this.class) {
      this.classList += ' ' + this.class;
    }
  }
}
