// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { HeadquarterModel } from '../_models/headquarter.model';
import { Update } from '@ngrx/entity';

export enum HeadquarterActionTypes {
  HeadquarterOnServerCreated = '[Edit Headquarter Component] Headquarter On Server Created',
  HeadquarterCreated = '[Edit Headquarter Component] Headquarter Created',
  HeadquarterUpdated = '[Edit Headquarter Component] Headquarter Updated',
  HeadquartersStatusUpdated = '[Headquarters List Page] Headquarters Status Updated',
  OneHeadquarterDeleted = '[Headquarters List Page] One Headquarter Deleted',
  ManyHeadquartersDeleted = '[Headquarters List Page] Many Selected Headquarters Deleted',
  HeadquartersPageRequested = '[Headquarters List Page] Headquarters Page Requested',
  HeadquartersPageLoaded = '[Headquarters API] Headquarters Page Loaded',
  HeadquartersPageCancelled = '[Headquarters API] Headquarters Page Cancelled',
  HeadquartersPageToggleLoading = '[Headquarters] Headquarters Page Toggle Loading',
  HeadquartersActionToggleLoading = '[Headquarters] Headquarters Action Toggle Loading',
}

export class HeadquarterOnServerCreated implements Action {
  readonly type = HeadquarterActionTypes.HeadquarterOnServerCreated;
  constructor(public payload: { headquarter: HeadquarterModel }) {}
}

export class HeadquarterCreated implements Action {
  readonly type = HeadquarterActionTypes.HeadquarterCreated;
  constructor(public payload: { headquarter: HeadquarterModel }) {}
}

export class HeadquarterUpdated implements Action {
  readonly type = HeadquarterActionTypes.HeadquarterUpdated;
  constructor(
    public payload: {
      partialHeadquarter: Update<HeadquarterModel>; // For State update
      headquarter: HeadquarterModel; // For Server update (through service)
    },
  ) {}
}

export class HeadquartersStatusUpdated implements Action {
  readonly type = HeadquarterActionTypes.HeadquartersStatusUpdated;
  constructor(
    public payload: {
      headquarters: HeadquarterModel[];
      status: number;
    },
  ) {}
}

export class OneHeadquarterDeleted implements Action {
  readonly type = HeadquarterActionTypes.OneHeadquarterDeleted;
  constructor(public payload: { id: number }) {}
}

export class ManyHeadquartersDeleted implements Action {
  readonly type = HeadquarterActionTypes.ManyHeadquartersDeleted;
  constructor(public payload: { ids: number[] }) {}
}

export class HeadquartersPageRequested implements Action {
  readonly type = HeadquarterActionTypes.HeadquartersPageRequested;
  constructor(public payload: { page: QueryParamsModel }) {}
}

export class HeadquartersPageLoaded implements Action {
  readonly type = HeadquarterActionTypes.HeadquartersPageLoaded;
  constructor(
    public payload: {
      headquarters: HeadquarterModel[];
      totalCount: number;
      page: QueryParamsModel;
    },
  ) {}
}

export class HeadquartersPageCancelled implements Action {
  readonly type = HeadquarterActionTypes.HeadquartersPageCancelled;
}

export class HeadquartersPageToggleLoading implements Action {
  readonly type = HeadquarterActionTypes.HeadquartersPageToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export class HeadquartersActionToggleLoading implements Action {
  readonly type = HeadquarterActionTypes.HeadquartersActionToggleLoading;
  constructor(public payload: { isLoading: boolean }) {}
}

export type HeadquarterActions =
  | HeadquarterOnServerCreated
  | HeadquarterCreated
  | HeadquarterUpdated
  | HeadquartersStatusUpdated
  | OneHeadquarterDeleted
  | ManyHeadquartersDeleted
  | HeadquartersPageRequested
  | HeadquartersPageLoaded
  | HeadquartersPageCancelled
  | HeadquartersPageToggleLoading
  | HeadquartersActionToggleLoading;
