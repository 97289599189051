<div class="kt-quick-search__result">
  <ng-container *ngFor="let item of data">
    <ng-container
      *ngIf="item.type === 0"
      [ngTemplateOutlet]="separatorTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
    <ng-container
      *ngIf="item.type === 1"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
  </ng-container>
</div>

<ng-template #itemTemplate let-item="item">
  <a href="javascript:;" class="kt-quick-search__item">
    <span class="kt-quick-search__item-icon" [innerHTML]="item.icon"></span>
    <span class="kt-quick-search__item-text">{{ item.text }}</span>
  </a>
</ng-template>

<ng-template #separatorTemplate let-item="item">
  <div class="kt-quick-search__category">{{ item.text }}</div>
</ng-template>

<ng-template #emptyResultTemplate>
  <div class="kt-quick-search__message kt-hidden">
    {{ noRecordText || 'No record found' }}
  </div>
</ng-template>
