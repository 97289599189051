<div class="login">
  <h3 class="login__title">Enter your new password</h3>

  <kt-auth-notice></kt-auth-notice>

  <form class="login__form" [formGroup]="resetPasswordForm" autocomplete="off">
    <ng-container *ngIf="showPasswordForm">
      <mat-form-field class="login__field">
        <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
        <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" />
        <mat-error *ngIf="isControlHasError('password', 'required')">
          <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
        </mat-error>
        <!-- <mat-error *ngIf="isControlHasError('password','password')">
                  <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                </mat-error> -->
        <mat-error *ngIf="isControlHasError('password', 'minLength')">
          <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
        </mat-error>
        <mat-error *ngIf="isControlHasError('password', 'maxLength')">
          <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="login__field">
        <mat-label>Repeat password</mat-label>
        <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="confirmPassword" autocomplete="off" />
        <mat-error *ngIf="isControlHasError('password', 'required')">
          <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="resetPasswordForm.get('confirmPassword').errors && resetPasswordForm.get('confirmPassword').errors.ConfirmPassword">
          <strong>Passsword and ConfirmPassword didn't match.</strong>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div class="login__actions">
      <button type="button" routerLink="/auth/login" [disabled]="loading" class="btn btn-secondary btn-elevate">
        {{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
      </button>
      <button type="submit" *ngIf="!showLoginButton" (click)="submit()" class="btn btn-primary btn-elevate">
        <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</span>
      </button>
      <button type="submit" *ngIf="showLoginButton" (click)="goToLogin()" class="btn btn-primary btn-elevate">To login</button>
    </div>
    <div class="login__actions">
      <div></div>
      <div *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    </div>
  </form>
</div>
