export enum CategoryType {
  MOBILE = 0,
  BROADBAND = 1,
  TV = 2,
}

export enum ProviderDependence {
    CONTRACTS = 'contracts',
    PRODUCTS = 'products',
}
