export class HeadquarterProviderModel {
  id: number = 0;
  name: string = '';
  headquarterId: number = 0;
  active: boolean = false;
  image: string = '';
  providerConnected?: boolean;
  providerConnectedOnly?: boolean;
  providerId: number;
  provider: IProvider;

  constructor(init?: Partial<HeadquarterProviderModel>) {
    Object.assign(this, init);
  }
}

export type ProviderCode = 'THREE'
    | 'HALEBOP'
    | 'TELENOR'
    | 'TELE2'
    | 'TELIA'

export const PROVIDER_CODES = {
    THREE: 'THREE',
    HALEBOP: 'HALEBOP',
    TELENOR: 'TELENOR',
    TELE2: 'TELE2',
    TELIA: 'TELIA',
}

interface IProvider {
    code: ProviderCode;
    image: string;
}
