import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator',
})
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value != null) {
      let result = value.toLocaleString();
      return result.replace(',', '.');
    } else {
      return '';
    }
  }
}
