export const environment = {
    production: false,
    stage: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    api_url: '/api',
    tele2BankIdUrl: '/user/personalnumber/data',
    locale: 'en',
    // logrocketAppId: 'ayndpw/verticula-dev',
};
